import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog12Hero from "../../../image/blog_12_hero_elismerni-a-masik-erzeseit.png"

export default function Blog_12_elismerni_az_erzeseket() {
  return (
    <div className="mf-4408" id="mf-page" data-id="4408">
    <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog12Hero} alt="Elismerni_a_masik_erzeseit"/>
                </div>

                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">Elismerni a másik érzéseit</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2024. február 08.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>

                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">
                            <p>
                                  Nem egyszer találkozom párokkal végzett munkám során azzal, hogy a pár egyik tagja erős ellenállást mutat abban, hogy elismerje a másik érzéseit.  Ilyenkor mindig megkérdezem az ellenállást mutató féltől, hogy mi is ez az ellenállás. A válasz általában arról szól, hogy nem ért egyet azzal, amit a másik mond, és így nem igazán tudja, hogy mit válaszoljon. Erre azt szoktam felelni: <i>„Nem kell, hogy egyetértsen azzal, amit a másik mond, csak annyi kell, hogy elismerje a másik érzéseit. Azok az ő érzései, és valóságosak az ő számára, még akkor is, ha Ön éppen máshogy látja a dolgokat.”</i> Erre általában az a válasz: <i>„Hogyan tudnám ez megtenni, egyetértés nélkül – hiszen az elismerés az egyetértést jelent, nem igaz?”</i>
                            </p>

                            <p>
                                  Sokszor találkozom párterápiában azzal a tévhittel, hogy elismerni a másik érzéseit azt jelenti, hogy egyetértünk a másikkal. Az egyetértés ebben a helyzetben azt jelentené, hogy az egyik partnernek hibáztatnia kellene magát a másik partner érzéseiért.
                            </p>

                            <p>
                                  Tehát ahelyett, hogy az ellenálló fél elismerné a másik érzéseit, védekezővé, elutasítóvá válik, minimalizálja a másik érzéseit vagy egy gyors megoldási javaslattal eltereli a figyelmet a problémáról anélkül, hogy megértené, mi okozta a másikban megjelenő érzést. Az érzelmei elismerése nélküli partner  ettől viszont meg nem hallgatottnak, félreértettnek fogja érzi magát, ami előbb vagy utóbb bezárkózáshoz vagy még erősebb érzelmi reakcióhoz, dühös, kritikus vagy lenéző viselkedéshez vezethet. A meg nem értettségből fakadó negatív érzések csendben növekednek, idővel elmélyülnek, végül visszahúzódóbb, érzelmileg eltávolodott partnerhez vezetnek.
                            </p>

                            <h4>
                                  Mi az érvényesítés?
                            </h4>

                            <p>
                                  Az érvényesítés, a partner érzelmeinek, nézőpontjának oly módon történő elismerése, hogy olyan érzés alakuljon ki benne, hogy meghallják, megértik és/vagy törődnek vele. Amikor a párkapcsolatban az egyik fél megosztja, hogyan érez valamivel kapcsolatban, az az ő érzése a saját valósága. Fontos tudni, hogy az emberek valósága alapvetően szubjektív, vagyis mindenkié különbözik a másikétól.
                            </p>

                            <p>
                                  Az egészséges és sikeres kommunikációhoz azonban elengedhetetlen, hogy a másik fél valósága elismerésre kerüljön, amely rá nézve igaz, és csak azért, mert igaz rá, viszont nem jelenti azt, hogy igaznak kell lennie a másik fél számára is. Azzal, hogy elismerésre kerül az egyik fél nézőpontja, attól még továbbra is megmarad a másik fél saját perspektívája.
                            </p>

                            <h6>
                                  Íme, néhány mondat, ami segíthet érvényesíteni a partner érzéseit egyetértés kifejezése nélkül:
                            </h6>

                            <p>
                                  <i>„Hallom és látom, hogy ez fontosa számodra, figyelek rád."</i>
                            </p>

                            <p>
                                  <i>„Folytasd kérlek, fontos számomra, hogy jobban megértselek.”</i>
                            </p>

                            <p>
                                  <i>„Mit tehetek most, hogy jobban érezd magad?”</i>
                            </p>

                            <p>
                                  <i>„Szomorúan hallom, ahogy érzel. Fogalmam sem volt, hogy te ezt így élted meg.”</i>
                            </p>

                            <p>
                                  <i>„Ez borzasztóan hangzik, úgy tűnik, hogy ez nem a te napod volt!”</i>
                            </p>

                            <p>
                                  <i>„El tudnád magyarázni, hogy mit értesz XYZ alatt? Nehezen tudom megérteni.”</i>
                            </p>

                            <p>
                                  <i>„Sajnálom, hogy a szavaim/tetteim ilyen érzéseket keltettek benned. Ha a te helyedben lennék, talán én is így éreztem volna. Hogyan tehetném ezt jobbá?”</i>
                            </p>

                            <p>
                                  Összefoglalva, az érvényesítés nem az egyetértésről szól, és nem is a hibáztatásról. Azt jelenti, hogy elismered a partnered valóságát, hogy az igaz a számára, és fontos neked is. Az érvényesítés erőteljes hatással lehet két ember közelebb hozására és kapcsolatuk elmélyítésére. 
                            </p>
                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                      </div>

                      <div className="mf-blog-global-tags">
                            <p>Címkék:</p>
                            <a href="#">Kapcsolatok</a>      
                      </div>
                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
</div>
    )
}
