import React from "react";
import { Link } from "react-router-dom";

import "../../CSS/mediacio.css"

import Mediacio01 from "../../image/mf_mediacio_1.png"
import Expeditive from "../../image/hatekony.svg"
import Head from "../../image/head.svg"
import Secret from "../../image/secret.svg"
import Volunteer from "../../image/onkentes.svg"
import Like from "../../image/like_1.svg"
import DivorceMedCard from "../../image/mf_valas_med_card.png"
import FamilyMedCard from "../../image/mf_csalad_med_card.png"
import SchoolMedCard from "../../image/mf_iskola_med_card.png"
import Contact from "../../image/kapcsolat-3.png"
import FreeKonz from "../../image/ingyenes-konzultacio-1.png"
import Mediacio03 from "../../image/mediacio-3.png"
import Agrement01 from "../../image/megallapodas-1.png"

export default function Mediacio() {
  return (
    <div className="mf-3415"  id="mf-page" data-id="3415">

      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-07m42f38" data-id="07m42f38">

            
        <div className="container mf-hero-container p-0">
              
              <div className="mf-column-wrap mf-col-100">
                    <div className="mf-widget-container">
                          <div className="mf-about-widget">
                                <div className=" mediation-title-20m27f47 w-1" data-id="50b2c440">
                                      <h1 className="mf-heading-title">Mediáció</h1> 
                                </div>
                          </div>
                    </div>
              </div>
        </div> 

        <div className="container mf-container-1 p-0">
              
              <div className="mf-mediation-quote-container">
                    <div className="mediation-quote-box">
                          <blockquote>
                                <h6>"A konflikus elkerülhetetlen, de a harc nem kötelező"
                                </h6>
                          </blockquote>
                    <p>
                          /Max Lucade/
                    </p>
                    </div>
              </div>
        </div>
        
      </div>
      {/*hero section ends*/}

      {/*Mediation first section start*/}
      <div className="mf-page section mf-mediation-section" data-id="">
            <div className="container mf-mediation-container p-0">
                  <div className="mf-mediation-row">

                        <div className="mf-mediation-info padd-20">
                              <div className="mf-widget-heading-title mf-mediation-title-20m43f55 w-1" data-id="20m43f55">
                                    <h3 className="mf-heading-title">A mediáció...</h3>
                              </div>
                              
                              <div className="mf-widget-text-description mf-mediation-text-29m67f82" data-id="29m67f82">
                                    <p className="mf-text-description">
                                          egy önkéntesen igénybevehető szolgáltatás, ahol egy semleges harmadik fél (egy mediátor/közvetítő, akit mindkét fél megbízott) segíti elő, a konfliktusban részt vevő felek közötti tárgyalásokat. A mediáció célja, olyan megoldás megtalálása, amely mindkét fél számára előnyös.
                                    </p>
                              </div>

                              <div className="mf-widget-text-description mf-mediation-text-29m67f82" data-id="29m67f82">
                                    <p className="mf-text-description">
                                          A mediáció legjobb része az, hogy Ön felelős az eredményért és a feltételekért, amelyekbe beleegyezik. Ön a végső döntéshozó, nem pedig a bíróságok.
                                    </p>
                              </div>

                              <div className="mf-widget-text-description mf-mediation-text-36m58f41" data-id="36m52f70">
                                    <p className="mf-text-description">
                                          A mediáció lehetőséget ad arra, hogy a konfliktusait saját feltételei szerint oldja meg.
                                    </p>
                              </div>
                        </div>

                        <div className="mf-mediation-img padd-20">
                              <img src={Mediacio01} alt=""/>
                        </div>
                  </div>
            </div>
      </div>
      {/*Mediation first section ends*/}

      {/*Mediation second section starts*/}
      <div className="mf-page section mf-mediation1-section2" data-id="">
            <div className="container mf-mediation1-container p-0">
                  <div className="mf-mediation1-heading-box">
                        
                        <div className="mf-widget-heading-title mf-mediation-title-16m03f27 w-1" data-id="16m03f27">
                              <h1 className="mf-heading-title">Miért érdemes a mediációt választani?</h1>
                        </div>
                  </div>
            </div>
      </div>
      {/*Mediation second section ends*/}

      {/*Mediation 3 section starts*/}
      <div className="mf-page section mf-mediatio2-section3" data-id="">

            <div className="container mf-mediatio2-container p-0">
                  <div className="mf-mediatio1-row">

                        <div className="mf-mediatio-info1 padd-20">
                              <div className="icon-box">
                                    <img src={Expeditive} alt="" className="profile-img"/>
                                    <span className="icon-title">Hatékony</span>
                              </div>
                              <p className="mf-mediatio-description">A mediáció hatékony és költséghatékony módja annak, hogy az emberek a kapcsolataik tönkretétele és a bíróság beavatkozása nélkül érjenek el egymás között megállapodást. A tapasztalatok és a  tanulmányok következetesen azt mutatják, hogy a közvetítéssel létrejött megállapodások tovább tartanak, mivel a felek aktív résztvevői a konfliktus megoldásához szükséges döntéseknek.
                              </p>

                              <div className="icon-box">
                                    <img src={Head} alt="" className="profile-img"/>
                                    <span className="icon-title">Pártartlan</span>
                              </div>
                              <p className="mf-mediatio-description">A mediátor egy pártatlan harmadik személy,  aki nem áll egyik fél oldalára sem. Nem hoz döntést, és nem is javasol megoldásokat, de mindent megtesz annak érdekében, hogy a mediációban részt vevő felek tisztességesen, egyenlő feltételek mellett, produktív módon beszélhessenek egymással a megvitatni kívánt kérdésekről.  Azért dolgozik, hogy segítse a feleket a döntéshozatalban. A mediátor az egyéni coaching, az aktív hallás, valamint a kommunikációs alapszabályok betartatása révén segíti a feleket.  Nyomon követi a megvitatásra váró kérdéseket, és rögzíti az elért megállapodásokat. A közvetítő a jövőre vonatkozó megoldások kontextusában tekint a múltra.
                              </p>


                        </div>

                        <div className="mf-mediatio-info2 padd-20">
                              <div className="icon-box">
                                    <img src={Secret} alt="" className="profile-img"/>
                                    <span className="icon-title">Bizalmas</span>
                              </div>
                              <p className="mf-mediatio-description">A mediáció során elhangzott információk, állásfoglalások bizalmasan vannak kezelve. Semmilyen információ nem hozható nyilvánosságra.
                              </p>

                              <div className="icon-box">
                                    <img src={Volunteer} alt="" className="profile-img"/>
                                    <span className="icon-title">Önkéntes</span>
                              </div>
                              <p className="mf-mediatio-description">A mediáción a felek részvétele önkéntes, bármikor megszakítható a folyamat. Nem kell elfogadnia olyan feltételeket, amelyeket nem akar.
                              </p>

                              <div className="icon-box">
                                    <img src={Like} alt="" className="profile-img"/>
                                    <span className="icon-title">Gyors</span>
                              </div>
                              <p className="mf-mediatio-description">Mivel a felek vállalják a felelősséget a mediáció sikerességéért és a megállapodás létrejöttéért, így sokkal gyorsabban tudnak megegyezni az egyeztetés alatt, mint ha  bírósági meghallgatásokba vagy ügyvédek jogi procedúráiba keverednének. A bíróságok elfogadják a közvetítői eljárás során létre jött megállapodásokat, így a folyamatra fordított idő is jelentősen lerövidül.
                              </p>
                        </div>
                  </div>
            </div>
      </div>
      {/*Mediatio 3 section ends*/}

      {/*mediation 4 section starts*/}
      <div className="mf-page section mf-mediation3-section4" data-id="">
            <div className="container mf-mediation3-container p-0">
                  <div className="mf-mediation2-heading-box">
                        
                        <div className="mf-widget-heading-title mf-mediation-title-19m01f31 w-1" data-id="19m01f31">
                              <h2 className="mf-heading-title">Területeim</h2>
                        </div>
                        
                  </div>
            </div>
      </div>
      {/*mediation 4 section ends*/}

      {/*mediation 5 section starts*/}
      <div className="mf-page section mf-mediation4-section5" data-id="">
            <div className="container mf-mediation4-container p-0">
                  <div className="mf-mediation-img-card">
                        <img src={DivorceMedCard} alt="" className="card-img"/>
                        <div className="mf-mediation-card-body">
                              <Link to="/Valasi_Mediacio">
                                    <div className="mf-widget-heading-title mf-mediation-title-43m27f02 w-1" data-id="43m27f02">
                                          <h3 className="mf-heading-title">Válási mediáció</h3>
                                    </div>
                              </Link>
                        </div>
                  </div>

                  <div className="mf-mediation-img-card">
                        <img src={FamilyMedCard} alt="" className="card-img"/>
                        <div className="mf-mediation-card-body">
                              <Link to="/Csaladi_Mediacio">
                                    <div className="mf-widget-heading-title mf-mediation-title-43m27f02 w-1" data-id="43m27f02">
                                          <h3 className="mf-heading-title">Családi mediáció</h3>
                                    </div>
                              </Link>
                        </div>
                  </div>

                  <div className="mf-mediation-img-card">
                        <img src={SchoolMedCard} alt="" className="card-img"/>
                        <div className="mf-mediation-card-body">
                              <Link to="/Iskolai_Mediacio">
                                    <div className="mf-widget-heading-title mf-mediation-title-43m27f02 w-1" data-id="43m27f02">
                                          <h3 className="mf-heading-title">Iskolai mediáció</h3>
                                    </div>
                              </Link>
                        </div>
                  </div>
            </div>

      </div>         
      {/*mediation 5 section ends*/}

      {/*mediation 6 section starts*/}
      <div className="mf-page section mf-mediation5-section6" data-id="">
            <div className="container mf-mediation5-container p-0">
                  <div className="mf-mediation5-heading-box">
                        
                        <div className="mf-widget-heading-title mf-mediation-title-04m47f83 w-1" data-id="04m47f83">
                              <h2 className="mf-heading-title">A mediáció folyamata</h2>
                        </div>
                        
                  </div>
            </div>
      </div>
      {/*mediation 6 section ends*/}

      {/*mediation 7 section starts*/}
      <div className="mf-page section mf-mediation6-section7" data-id="">
            <div className="container mf-mediation6-container p-0">
                  <div className="mf-mediation-img-box">
                        <img src={Contact} alt="" className="box-img"/>
                        <div className="mf-widget-heading-title mf-mediation-title-63m28f06 w-1" data-id="63m28f06">
                              <h3 className="mf-heading-title">Kapcsolatfelvétel</h3>
                        </div>
                  </div>

                  <div className="mf-mediation-img-box">
                        <img src={FreeKonz} alt="" className="box-img"/>
                        <div className="mf-widget-heading-title mf-mediation-title-63m28f06 w-1" data-id="63m28f06">
                              <h3 className="mf-heading-title">Ingyenes konzultáció</h3>
                        </div>
                  </div>

                  <div className="mf-mediation-img-box">
                        <img src={Mediacio03} alt="" className="box-img"/>
                        <div className="mf-widget-heading-title mf-mediation-title-63m28f06 w-1" data-id="63m28f06">
                              <h3 className="mf-heading-title">Mediáció</h3>
                        </div>
                  </div>

                  <div className="mf-mediation-img-box">
                        <img src={Agrement01} alt="" className="box-img"/>
                        <div className="mf-widget-heading-title mf-mediation-title-63m28f06 w-1" data-id="63m28f06">
                              <h3 className="mf-heading-title">Megállapodás</h3>
                        </div>
                  </div>

                 
            </div>
      </div>
      {/*mediation 7 section ends*/}

      {/*mediation 8 section starts*/}
      <div className="mf-page section mf-mediation-info-section" data-id="">
            <div className="container mf-mediation-info-container p-0">
                  <div className="mf-mediation-info-box">
                        <h2 className="mediation-info-title">További információkért kattintson ide:</h2>
                  </div>  
                  <div className="mf-mediation-info-btnBx">
                        <Link to="/Gyik_mediacio"><div className="mf-mediation-info-btn btn" >GYIK - mediáció</div></Link>
                  </div>
            </div>
            
      </div>
      {/*mediation 8 section ends*/}

      {/*mediation 9 section starts*/}
      <div className="mf-page section mf-mediation8-section9" data-id="">
            <div className="container mf-mediation8-container p-0">
                  <div className="mf-mediation8-heading-box">
                        
                        <div className="mf-mediation-title-19m24f44 w-1" data-id="19m24f44">
                              <h4 className="mf-heading-title">Konfliktusok esetén, gyakran más megközelítést kell keresni, a mindenki számára elfogadható megoldás érdekében.
                              <br/>Új kezdetekre, új perspektívára, új irányra van szükség.
                              </h4>
                        </div>
                        
                  </div>
            </div>
      </div>
      {/*mediation 9 section ends*/}

      {/*contact section starts */}
      <div className="mf-page section mf-mediation-contact-section-27m10f36" data-id="27m10f36">

            <div className="container mf-mediation-contact-container p-0">
                  <div className="mf-mediation-contact-content">
                        <div className="mf-mediation-info-content">
                              <div className="mf-widget-heading-title mediation-info-title-26e5f30c" data-id="26e5f30c">
                                    <h1 className="mf-heading-title">Készen áll konfliktusai megoldására?</h1>
                              </div>
                              <div className="mf-widget-text-description mediation-info-text-22a7b451" data-id="22a7b451">
                                    <p className="mf-text-description">Tegye meg az első lépést!</p>
                              </div>
                              <div className="mf-widget-text-description mediation-info-text-32m10f63" data-id="32m10f63">
                                    <p className="mf-text-description">Kötelezettség nélkül, 20 perces ingyenes konzultációs lehetőséget biztosítok Önnek, hogy beszéljünk a helyzetéről.</p>
                              </div>
                              <div>
                              <Link to="/Kapcsolat" className="mf-mediation-contact-btn btn">Kérjen időpontot ingyenes konzultációra</Link>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      {/*contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
  
    </div>
  )
}
