import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog04Hero from "../../../image/blog_04_hero_tipp.jpg"
import Blog04_1 from "../../../image/blog_04_tippek_1.jpg"
import Blog04_2 from "../../../image/blog_04_tippek_2.jpg"
import Blog04_3 from "../../../image/blog_04_tippek_3.jpg"
import Blog04_4 from "../../../image/blog_04_tippek_4.jpg"
import Blog04_5 from "../../../image/blog_04_tippek_5.jpg"

export default function Blog_04_tippek() {
  return (
    <div className="mf-4399" id="mf-page" data-id="4399">
      <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog04Hero} alt="Tippek_az_ongondoskodas_megvalositasahoz"/>
                </div>
          

                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">Tippek az öngondoskodás megvalósításához</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2023. január 13.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>



                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">
                            <p>
                                  Az öngondoskodás, azaz a magunkkal való törődés, 
                                  odafigyelés a saját szükségleteinkre, mozgalmas 
                                  és kihívásokkal teli életünkben nem mindig élvez 
                                  prioritást. Pedig a lelki és testi egészségünk 
                                  megőrzése szempontjából elengedhetetlen, hogy 
                                  önmagunkra is figyeljünk és időt szakítsunk arra, 
                                  hogy valami kedveset tegyünk magunkért. Bizonyára 
                                  sokan hallottatok már az öngondoskodásról, 
                                  de nem biztos, hogy megtaláltátok hozzá a 
                                  megfelelő tevékenységet.
                            </p>
                            
                            <p>
                                  Amikor öngondoskodási ötleteken töröd a fejed, 
                                  kezdd azzal, hogy végig gondolod, hogy saját magad 
                                  mely részével szeretnél törődni.
                            </p>
                            <p>
                                  A fizikai öngondoskodás a testre összpontosít. 
                                  Kipróbálhatsz olyan tevékenységeket, amelyek 
                                  javítják a fizikai jólétedet.
                            </p>
                            <h4>
                                  Mozogj rendszeresen!
                            </h4>
                            <p>
                                  Ha edzési rutint keresel, 
                                  keress olyan tevékenységeket, 
                                  amelyek mozgásra késztetnek, 
                                  viszonylag könnyen elvégezhetőek 
                                  és még örömet is okoznak 
                                  számodra, pl. kerékpározás, 
                                  foci, zumba, tánctanfolyam. 
                                  A rendszeres kirándulás vagy a 
                                  jóga gyakorlása is nagyobb erőt 
                                  és energiát adhat. 
                            </p>
                            <h4>
                                  Táplálkozz egészségesen!
                            </h4>
                            <p>
                                  Nem lehet eléggé és elégszer hangsúlyozni a megfelelő, 
                                  kiegyensúlyozott táplálkozást. 
                                  Viszont mindig beszélj orvosoddal 
                                  vagy táplálkozási szakemberrel, 
                                  amikor étrendi változtatásokat akarsz végrehajtani.
                            </p>
                            <p>
                                  Az érzelmi jóléted ugyanolyan fontos, mint a fizikai.
                            </p>
                            
                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog04_1} alt="Tippek_taplalkozas"/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <h4>
                                  Vezess naplót!
                            </h4>
                            <p>
                                  A hálanapló segíthet észrevenni és értékelni az 
                                  életed apró örömeit. Alternatív 
                                  megoldásként egy álomnapló segíthet 
                                  nyomon követni, mit csinál az agyad éjszaka. 
                                  Bármelyik fajtáját is választod a napló írásának, arra ösztönöz, hogy lassíts és elgondolkodj.
                            </p>
                            <h4>
                                  Vegyél részt egy tanfolyamon!
                            </h4>
                            <p>
                                  Ha valami újat tanulsz, az segíthet 
                                  az önbizalmad növelésében. Olyan dolgot 
                                  tanulj, ami mindig is érdekelt, és amivel 
                                  tudod, hogy a későbbiekben is örömmel 
                                  fogsz foglalkozni.
                            </p>

                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog04_2}/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <p>
                                  A spirituális öngondoskodás segít abban, 
                                  hogy kapcsolatba kerülj a hitrendszereddel, ami javíthatja a motivációdat.
                            </p>
                            <h4>
                                  Meditálj!
                            </h4>
                            <p>
                                  Akár irányított, akár csendes meditációt 
                                  választasz, a lélegzetedre való 
                                  összpontosítás és a koncentráció segíthet 
                                  abban, hogy kapcsolatba léphess spirituális 
                                  oldaladdal, ha mást szeretnél, akár 
                                  kipróbálhatsz egy lelki elvonulást is.
                            </p>
                            <h4>
                                  Keress egy békés helyet!
                            </h4>
                            <p>
                                  A te spirituális tered lehet egy templom vagy 
                                  akár maga a természet – a lényeg, hogy találj egy  
                                  olyan helyet, ahol összekapcsolódhatsz a lelkeddel.  Egy ilyen helyen, 
                                  csendben ülve is békét és örömet találhatsz.
                            </p>
                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog04_3}/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <p>
                                  A gyakorlati öngondoskodás magában 
                                  foglalja azokat a szükségleteket, 
                                  amelyek abban segítenek, hogy jobban 
                                  érezd magad a bőrödben és jobb legyen 
                                  az életminőséged.
                            </p>
                            <h4>
                                  Vegyél igénybe egészségügyi szolgáltatásokat
                            </h4>
                            <p>
                                  Lehet, hogy nem tartozik kedvenc tevékenységeid közé, 
                                  de ha az orvosod, fogorvosod rendszeresen 
                                  felkeresed és az általuk javasolt vizsgálatokat 
                                  (pl. szűrővizsgálatok), kezeléseket elvégezteted, 
                                  akkor nagyobb esélye lesz annak, hogy egészséges maradj.
                            </p>
                            <h4>
                                  Tarts egy „szépségnapot”!
                            </h4>
                            <p>
                                  Az alapvető ápolás, mint például a bőrödre 
                                  való odafigyelés, a hajaddal való törődés 
                                  segíthet abban, hogy jobban érezd magad és 
                                  jobban nézzél ki. 
                            </p>
                            <p>
                                  Kapcsolataid boldogságot és motivációt adnak – figyelj oda, hogy egészségesek és tartósak legyenek.
                            </p>
                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog04_4} alt="Tippek_szepsegapolas"/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <h4>
                                  Adj ajándékot!
                            </h4>
                            <p>
                                  Nagyszerű érzés ajándékozni valamit 
                                  egy szeretett személynek, és már az is jó érzést adhat, 
                                  ha arra gondolsz, hogy valaki más napját ezzel feldobod.
                            </p>
                            <h4>
                                  Tölts időt szeretteiddel, barátaiddal!
                            </h4>
                            <p>
                                  Ha időt töltesz szeretteiddel, barátaiddal, 
                                  akkor kevésbé érzed magad egyedül. 
                                  Egy közös kirándulás, játék a barátokkal vagy a családdal sokat segíthet kapcsolataid erősebbé, szorosabbá fűzésében.
                            </p>
                            <p>
                                  Mentális egészséged általános jólétednek fontos része – vigyázz rá, gondoskodj róla.
                            </p>
                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog04_5} alt="Tippek_jutalom"/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <h4>
                                  Tarts szünetet!
                            </h4>
                            <p>
                                  Érzelmi és mentális állapotunkat negatívan befolyásolhatja 
                                  a nem elegendő pihenés vagy az alváshiány. 
                                  Még egy 15 perces, akár napközbeni szunyókálás 
                                  is segíthet abban, hogy frissebbnek és koncentráltabbnak érezd magad.
                            </p>
                            <h4>
                                  Keress terápiát!
                            </h4>
                            <p>
                                  Ha jobb életminőséget szeretnél elérni, 
                                  de azt tapasztalod, hogy mentális 
                                  betegségek vagy múltbeli traumák hatással 
                                  vannak rád, a terápia segíthet. 
                                  A terápia megtaníthatja azokat az 
                                  eszközöket és készségeket, amelyek 
                                  szükségesek ahhoz, hogy tovább lépj az 
                                  életedben. 
                            </p>
                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>

                      </div>



                      <div className="mf-blog-global-tags">
                            <p>Címkék:</p>
                            <a href="#">Önismeret</a>      
                      </div>
          </div>                  
      </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
  </div>
  )
}
