import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog09Hero from "../../../image/blog_09_hero_sikeres_hazassag.jpg"
import Blog09_1 from "../../../image/blog_09_sikeres_hazassag.png"

export default function Blog_09_mi_kell_a_hazassaghoz() {
  return (
    <div className="mf-4405" id="mf-page" data-id="4405">
    <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog09Hero} alt="Mi_kell_a_sikeres_hazassaghoz"/>
                </div>
          

                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">Mi kell a sikeres házasághoz</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2023. november 08.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>

                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">
                            <p>
                                  Az, hogy valaki mindig ugyanabba a személybe újra és újra bele tud-e szeretni, egyéni preferenciáktól és a kapcsolati dinamikától is függ. Minden kapcsolat egyedi, és nincs egy univerzális szabály arra vonatkozóan, hogy milyen mértékű vagy típusú érzelmeknek kell mindig jelen lenniük egy házasságban.  
                            </p>

                            <p>
                                  Az érzelmek változnak az idő múlásával, és a szerelem erőssége is természetes módon változik a házasságban eltelt évek alatt. A házaspárok többsége hosszú éveket tölt boldogan együtt függetlenül attól, hogy a szerelemük hőfoka nem mindig ugyanazon a lángon ég.
                            </p>

                            <h4>
                                  Mi szükséges mindehhez?
                            </h4>

                            <p>
                                  A hosszú távú kapcsolatokban a szerelem, a mély érzelmi kötődés és az intimitás mellett a megértés és az együttműködés is kiemelten fontos szerepet játszanak. Az, hogy képesek vagyunk-e átadni a másiknak az igényeinket és érzéseinket, és nyitottak vagyunk-e az ő igényei és érzéseit illetően, sokat számít egy párkapcsolatban. Az idő múlásával a kapcsolat mélysége és érettsége nőhet, ha mind a két fél hajlandó azt ápolni és fejleszteni.
                            </p>

                            <p>
                                  Fontos tehát megérteni, hogy a szerelem különböző formában és intenzitásban lehet jelen egy kapcsolatban, és egy sikeres házasság sokkal többről szól, mint pusztán az intenzív szerelem érzéséről. A kölcsönös tisztelet, empátia, megértés és elkötelezettség  kulcsfontosságú  a boldog és tartós házasságokban. Minden házaspárnak meg kell találnia azt az egyensúlyt és dinamikát, ami számukra működik, és ami hozzájárul a kölcsönös boldogságukhoz és elégedettségükhöz.
                            </p>
                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog09_1} alt="Mi_kell_a_hazassaghoz"/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <h4>
                                  Ha nézeteltérések merülnek fel, próbáld ki az alábbiakat:
                            </h4>

                            <p>
                                  <b>Beszélj!</b>  Ha valami zavar, beszélj róla, ahelyett hogy magadban tartanád.
                            </p>

                            <p>
                                  <b>Adj tiszteletet!</b> A párod érzései és kívánságai ugyanolyan értékesek, mint a sajátod. Mondd el a párodnak, hogy igyekszel szem előtt tartani az ő vágyait, elképzeléseit. 
                            </p>

                            <p>
                                  <b>Keresd a megoldást!</b> Minden kapcsolatban vannak nézeteltérések, éppen ezért fontos, hogy ha valamiben nincs egyetértés, akkor megbeszéljétek azt, és kölcsönös, kielégítő, igazságos, racionális módon megoldást találjatok rá.
                            </p>

                            <p>
                                  <b>Legyél támogató!</b> Fontos, hogy a házasság nem a versenyről szól, hanem az együttes fejlődésről, ami egymás támogatásán keresztül valósulhat meg. Akkor tudtok leginkább támogatóak lenni egymás irányába, ha ismeritek egymás szükségleteit és ki is tudjátok fejezni azt, egymás felé.
                            </p>

                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                      </div>

                      <div className="mf-blog-global-tags">
                            <p>Címkék:</p>
                            <a href="#">Kapcsolatok</a>
                            
                      </div>
                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
</div>

    )
}
