import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog23Hero from "../../../image/blog_23_miert_fontos_az egeszseges_onbecsules.png"

export default function Blog_23_miert_fontos_az_esgeszseges_onbecsules() {
  return (
    <div class="mf-4308" id="mf-page" data-id="mf-4308">
    <div class="mf-page section section-blog-globale">
          <div class="container mf-blog-globale-container p-0">
                <div class="mf-blog-globale-hero">
                      <img src={Blog23Hero} alt="Miert_fontos_az_egeszseges_onbecsules"/>
                </div>
          

                <div class="mf-blog-globale-face">
                      <div class="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 class="mf-blog-globale-heading-title">Miért fontos az egészséges önbecsülés?</h2>
                      </div>

                      <div class="mf-blog-globale-profile-container">
                            <div class="mf-blog-globale-profile">
                                  <div class="mf-blog-globale-img-container"></div>   
                      

                                  <div class="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p class="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p class="mf-blog-globale-text-description">2024. július 16.</p>
                                  </div>
                            </div>
                      
                            <div>
                            <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>



                <div class="mf-blog-globale-column">

                      <div class="mf-blog-globale-text">
                            <p>
                                  Az egészséges önbecsülés motiválhat bennünket céljaink elérésére, mert képesek vagyunk eligazodni az életben, annak tudatában, hogy képesek vagyunk elérni bármit, amire csak gondolunk. Ezen kívül, ha egészséges az önbecsülésünk, megfelelő határokat szabhatunk a kapcsolatainkban, és egészséges kapcsolatot tarthatunk fenn önmagunkkal és másokkal.
                            </p>

                            <p>
                                  <b>Az egészséges önbecsülés négy kulcsfontosságú jellemzője:</b>
                            </p>
                            

                            <ul class="blog-globale-list">
                                  <li class="blog-globale-has-before">Saját képességeink alapos ismerete.</li>
                                  <li class="blog-globale-has-before">Az egészséges önmagunkkal való kapcsolat eredményeként másokkal fenntartott egészséges kapcsolatok képessége.</li>
                                  <li class="blog-globale-has-before">Reális és megfelelő személyes elvárások.</li>
                                  <li class="blog-globale-has-before">Saját szükségleteink megértése és azok kifejezésének képessége.</li>
                                  
                            </ul>

                            <p>
                                  Van néhány egyszerű módja annak, hogy megállapítsd, egészséges-e az önértékelésed. 
                            </p>
                            <p>
                                  <b>Valószínűleg egészséges önbecsüléssel rendelkezel, ha:</b>
                            </p>
                            <ul class="blog-globale-list">
                                  <li class="blog-globale-has-before">Nem időzöl a múlt negatív tapasztalatain.</li>
                                  <li class="blog-globale-has-before">Hiszed, hogy egyenrangú vagy mindenki mással, sem nem jobb, sem nem rosszabb.</li>
                                  <li class="blog-globale-has-before">Kifejezed az igényeidet.</li>
                                  <li class="blog-globale-has-before">Magabiztosnak érzed magad.</li>
                                  <li class="blog-globale-has-before">Pozitív hozzáállással tekintesz az életre.</li>
                                  <li class="blog-globale-has-before">Képes vagy nemet mondani, amikor szeretnél.</li>
                                  <li class="blog-globale-has-before">Látod az erősségeidet és gyengeségeidet, és elfogadod őket.</li>
                            </ul>

                            <p>
                                  Az egészséges önbecsülés rendkívül fontos, mert alapvetően befolyásolja, hogyan érezzük magunkat, hogyan viszonyulunk másokhoz és milyen döntéseket hozunk az életünkben.
                            </p>
                            <p>
                                  Forrás:<Link to="https://www.verywellmind.com/what-is-self-esteem-2795868"> https://www.verywellmind.com/what-is-self-esteem-2795868</Link> 
                            </p>

                      </div>
                </div>
                <div class="mf-blog-global-tags">
                      <p>Címkék:</p>
                      <a href="#">önismeret</a>
                      
                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
        <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
</div>
)
}
