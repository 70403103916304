import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog02Hero from "../../../image/blog_02_hero_hogyan_szabjunk.jpg"
import Blog02_1 from "../../../image/blog_02_hogyan-szabjunk.jpg"

export default function Blog_02_hohyan_szabjunk() {
  return (
    <div className="mf-4397" id="mf-page" data-id="4397">
      <div className="mf-page section section-blog-globale">
            <div className="container mf-blog-globale-container p-0">
                  <div className="mf-blog-globale-hero">
                        <img src={Blog02Hero} alt="Hogyan_szabjunk_hatarokat"/>
                  </div>
            

                  <div className="mf-blog-globale-face">
                        <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                              <h2 className="mf-blog-globale-heading-title">Hogyan szabjunk határokat az ünnepek alatt</h2>
                        </div>

                        <div className="mf-blog-globale-profile-container">
                              <div className="mf-blog-globale-profile">
                                    <div className="mf-blog-globale-img-container"></div>   
                        

                                    <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                          <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                          <p className="mf-blog-globale-text-description">2022. december 21.</p>
                                    </div>
                              </div>
                        
                              <div>
                                    <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                              </div>
                        </div>
                  </div>



                  <div className="mf-blog-globale-column">

                        <div className="mf-blog-globale-text">
                              <p>
                                    Sok pár számára gondot okozhat határokat szabni az ünnepek alatt, 
                                    hiszen mind a két félnek van saját családja, illetve saját 
                                    vagy közös barátai, akik teljesen különböző irányba húzhatják 
                                    őket. Ahhoz, hogy a párkapcsolatok túléljék az ünnepeket 
                                    fontos, hogy egy pár párként, határokat tudjon szabni. 
                                    Érdemes tehát leülni és megbeszélni, hogy hol és kinek 
                                    mondjanak igent vagy nemet, de ami igazán lényeges, ne hagyják, 
                                    hogy a kapcsolatuk háttérbe szoruljon.
                              </p>
                              <h6>
                                    <i>Döntsétek el, hogy milyen hagyományokat szeretnétek!</i>
                              </h6>
                              <p>
                                    Az ünnepek kiváló alkalmat nyújtanak arra, hogy pároddal a 
                                    saját családjaitok hagyományairól beszélgessetek. Azért fontos a 
                                    hagyományokat megismerni és megvitatni, mert általuk jobban megértheted azt, 
                                    hogy ki a párod valójában, illetve segíthetnek abban, hogy a számotokra leginkább 
                                    megfelelő döntéseket hozzátok meg.
                              </p>
                              <p>
                                    Töltsetek a poharatokba a kedvenc karácsonyi italotokból, dőljetek hátra a 
                                    kényelmes kanapétokon és indulhat a beszélgetés.
                              </p>
                              <h4>
                                    Kérdések, amelyeket egymásnak feltehettek:
                              </h4>
                              <p><i>Mi volt a kedvenc ünnepi hagyományod gyerekként?</i></p>
                              <p><i>Mi most a kedvenc ünnepi hagyományod felnőttként?</i></p>
                              <p><i>Milyen ünnepi hagyományokat szeretnél folytatni saját családalapításkor?</i></p>
                              <p><i>Milyen ünnepi hagyományokat szeretnél megszüntetni vagy megváltoztatni?</i></p>
                              <p><i>Hol látsz lehetőséget arra, hogy ünnepi hagyományaidat az enyémmel ötvözd?</i></p>
                              <p><i>Ha/amikor lesz gyermeked, hogyan döntöd el, hogy mely családi hagyományokat fogod előnyben részesíteni?</i></p>
                              <p><i>Milyen alapvető értékeket látsz a legfontosabbnak az ünnepi hagyományaidban?</i></p>
                        </div>

                        <div className="mf-blog-globale-img-container">
                              <img src={Blog02_1} alt="unnepek_hatarok_szeretet"/>
                        </div>

                        <div className="mf-blog-globale-text">
                              <p>
                                    Ne feledd, hogy <b>Te</b> és a <b>Párod</b> döntitek el, milyen hagyományaitok lesznek. 
                                    Lehet, hogy mindkettőtök családja azt akarja, hogy bizonyos dolgok az ő 
                                    hagyományaik szerint történjenek, de nektek ketten, együtt kell 
                                    döntenetek és kimondanotok az „utolsó szót”.
                              </p>
                              <p>
                                    Igaz, az ünnepek stresszesek, kimerítőek és fárasztóak, de szépek, 
                                    örömteliek és izgalmasak is lehetnek. Találjatok meg minden lehetőséget, 
                                    hogy egymásra hangolódjatok, és egymás felé forduljatok. Akár egy közös 
                                    kávé és reggeli mellett, mielőtt elindulna a napotok vagy este mielőtt a 
                                    fáradságtól, kimerültségtől félájultan az ágyba esnétek. Találjátok meg 
                                    azokat a pillanatokat, amikor akár csak néhány percre is együtt lehettek.
                              </p>
                              <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>

                        </div>

                        <div className="mf-blog-global-tags">
                              <p>Címkék:</p>
                              <a href="#">Kapcsolatok</a>
                              
                        </div>
                  </div>
            </div>                  
      </div>

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

</div>
  )
}
