import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog18Hero from "../../../image/blog_18_hero_hogyan_tanuld_meg.png"

export default function Blog_18_hogyan_tanuld_meg_szeretni_onmagad() {
  return (
    <div className="mf-4301" id="mf-page" data-id="mf-4301">
      <div className="mf-page section section-blog-globale">
        <div className="container mf-blog-globale-container p-0">
          <div className="mf-blog-globale-hero">
            <img src={Blog18Hero} alt="Hogyan_tanuld_meg_szeretni_onmagad"/>
          </div>
    

          <div className="mf-blog-globale-face">
            <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
              <h2 className="mf-blog-globale-heading-title">Hogyan tanuld meg szeretni önmagad</h2>
            </div>

            <div className="mf-blog-globale-profile-container">
              <div className="mf-blog-globale-profile">
                <div className="mf-blog-globale-img-container"></div>   
    

                <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                  <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                  <p className="mf-blog-globale-text-description">2024. április 11.</p>
                </div>
              </div>
        
              <div>
              <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
              </div>
            </div>
          </div>



          <div className="mf-blog-globale-column">

                <div className="mf-blog-globale-text">
                      <p>
                            Azt gondoljuk, hogy biztosan nem nehéz szeretni önmagunkat a hibáinkkal együtt, ám sokszor mégis, igencsak nehéznek bizonyul saját magunk feltétel nélküli elfogadása. Néhányan negatív önértékeléssel, alacsony önbecsüléssel vagy méltatlanság érzésével küzdenek. Fontos tudni, hogy ezek a gondolatok és érzések nem azt tükrözik, hogy ki vagy! Ahhoz, hogy magabiztos életet tudj élni és elérd a céljaid, szükséged van önszeretetre. Az, önszeretet megtanulása, időt és erőfeszítést igénylő folyamat, amely tele van kihívásokkal. Íme, néhány tippem, amivel elindulhatsz az önszeretet felé vezető úton.
                      </p>

                      <h6>
                            1. Öngondoskodás
                      </h6>

                      <p>
                            Az önszeretet első lépése az, ha megtanulod rangsorolni magad. Kezd öngondoskodással, kezd tettekkel, ami jelentheti a testedről való gondoskodást, pl. elegendő alvás, testmozgás, táplálkozás vagy egy jó forró fürdő. De jelentheti azt is, hogy erőfeszítéseket teszel annak érdekében, hogy az életedet könnyebbé és örömtelibbé varázsold. Fontos, hogy különbséget tudj tenni, mi az, ami neked előnyös, és mi az, amit mások miatt teszel. 
                      </p>

                      <h6>
                            2. Pozitív önbeszéd
                      </h6>

                      <p>
                            Könnyű belekerülni az önbecsmérlés és az önutálat ördögi körébe, amely jelentős károkat okoz a mentális egészségben és igen ártalmas az önbecsülésre és az önbizalomra is. Önmagad szeretetét igen csak megakadályozza, ha negatív önbeszédet folytatsz. Fontos, hogy a negatív gondolataidat újra formáld és változtasd meg pozitívabbra. Látni fogod, hogy mindez hatással lesz az önmagaddal kapcsolatos érzésekre is. Bár ez nem egyik napról a másikra fog megtörténni, de az önegyüttérzés gyakorlása segíthet lehalkítani a belső kritikus hangod.
                      </p>

                      <h6>
                            3. Pozitív támogatórendszer kialakítása
                      </h6>

                      <p>
                            Nem könnyű túllépni a negatív önbeszéden, de segíthet, ha vannak olyan emberek a környezetedben, akik támogatnak, szeretnek és kiemelik az erősségeidet. Sokszor a legkeményebb kritikusai, mi vagyunk saját magunknak. Éppen ezért jó, ha másoktól megerősítést kapsz, mert az segíthet abban, hogy megtörd ezeket a negatív, kritikus gondolati köröket.
                      </p>

                      <h6>
                            4. Gyakorold a hálát
                      </h6>

                      <p>
                            A hála lehet az, az egyik legmegnyugtatóbb szokás, amit ha elsajátítasz, akkor hatékony eszközként szolgálhat az érzelmi jóléted javításában. Ahelyett, hogy negatív gondolatokon emésztenéd magad, vezess naplót arról, hogy miért voltál adott napon hálás. A hálanapló idővel, az élet szép dolgainak a megbecsüléséhez vezethet, ami segíthet megláttatni a pozitívumot saját magadban.
                      </p>

                      <h6>
                            5. Légy türelmes magadhoz, fogadd el sebezhetőséged! 
                      </h6>

                      <p>
                            A fejlődéshez idő kell, és sokszor jóval több, mint ahogy gondolnánk vagy mint, amit a médiából vagy a reklámokból felénk sugároznak. Az önszeretet része, hogy megtanulunk hitelesnek lenni és elfogadni önmagunkat. Fogadd el azt, hogy sebezhető vagy. A sebezhetőséged elfogadása kulcsfontosságú az önszeretethez, mert lehetővé teszi, hogy elenged a szégyent és az önbizalomhiányt.
                      </p>

                      <p>
                            Ha nehezen találsz rá az önszeretetre, akkor itt az ideje, hogy szakmai segítséget kérj.  Az egyéni tanácsadás hatékony eszköz lehet az önszeretet elérésében. A tanácsadás  biztonságos és támogató teret adhat az érzések feltárására, az önreflexió gyakorlására, az öntudat kialakítására, valamint az önelfogadás és az önegyüttérzés új készségeinek fejlesztésére. Segítetek azonosítani és feldolgozni azokat a dolgokat, amelyek visszatartanak az önszeretettől.
                      </p>
                      <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>



                </div>
          </div>
          <div className="mf-blog-global-tags">
                <p>Címkék:</p>
                <a href="#">önismeret</a>
                
          </div>
        </div>
      </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
