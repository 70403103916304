import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../CSS/parkapcsolat_tanacs.css"

import ParKapcs from "../../image/parkapcsolati-tanacsadas-1.png"

export default function Parkapcsolat_tanacs() {

      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };

  return (
    <div className="mf-3452"  id="mf-page" data-id="3452">
      
      {/*Hero section starts*/}
      <div className="mf-page section section-img-bg-4m5f7879" data-id="4m5f7879">

            
            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-23m68f12 w-1" data-id="23m68f12">
                                          <h1 className="mf-heading-title">Párkapcsolati tanácsadás<span>személyesen és online</span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 

            <div className="container mf-hero1-container p-0">
                  
                  <div className="mf-hero-quote-container">
                        <div className="hero-quote-box">
                              <blockquote>
                                    <h6>"Egy kapcsolat sem csupa napfény, de két ember
                                    <br/>osztozhat egy esernyőn és együtt túlélheti a vihart"
                                    </h6>
                              </blockquote>
                        <p>
                              /ismeretlen szerző/
                        </p>
                        </div>
                  </div>
            </div>
            
      </div>
      {/*Hero section ends*/}

      {/*couple first section starts*/}
      <div className="mf-page section mf-couple-section" data-id="">
            <div className="container mf-couple-container p-0">

                  <div className="mf-couple-text-block">
                        <div className="mf-widget-text-description mf-couple-text-47m93f82" data-id="47m93f82">
                              <p className="mf-text-description">
                                    Az összetartozás, a közelség, a stabilitás és az intimitás egyéni kielégítésének szükséglete 
                                    fontos egy párkapcsolat sikeréhez. Azonban a két partner kívánságát nem mindig könnyű összeegyeztetni. 
                                    A párkapcsolatra az említetteken kívül hatással van  még a munka, a gyerekek és az ő nevelésük, 
                                    a fennálló kötelezettségek és az esetleges félelmek és aggodalmak. 
                              </p>
                        </div>
                        <div className="mf-widget-text-description mf-couple-text-47m93f82" data-id="47m93f82">
                              <p className="mf-text-description">
                                    Minden ember egyedi, így természetesen másképp közelít meg bizonyos kihívásokat, 
                                    gyakran a saját származási családjukban gyermekként megismert viselkedésminták alapján. 
                                    Különösen ha stressz alá kerülnek vagy ha problémák merülnek fel, ez az eltérő 
                                    hozzáállás és megközelítés megoldhatatlannak tűnő konfliktusokhoz vezethet. 
                              </p>
                        </div>
                  </div>
            </div>
      </div>
      {/*couple first section ends*/}
      
      {/*Couple second section starts*/}
      <div className="mf-page section mf-couple1-section2" data-id="">
            <div className="container mf-couple1-container p-0">

                  <div className="mf-couple-text-block">
                        <div className="mf-widget-heading-title mf-couple-title-68m59f14 w-1" data-id="53m87f66">
                              <h3 className="mf-heading-title">Mikor javasolt a párkapcsolati tanácsadás?</h3>
                        </div>
                        <div className="mf-widget-text-description mf-couple-text-21m35f73" data-id="21m35f73">
                              <p className="mf-text-description">
                                    <b>Ha elakadás vagy nehézség tapasztalható a párkapcsolat ezen területeinek bármelyikén:</b>
                              </p>
                        </div>
                        <div className="mf-couple-content-text">
                              <ul className="mf-couple-list">
                                    <li className="couple-has-before"><a href="">Komunikációs problémák</a>, gyakori viták veszekedések.</li>
                                    <li className="couple-has-before">Nehézségek a konfliktusok megoldásában, a nehéz döntések meghozatalában.</li>
                                    <li className="couple-has-before">Válság az elkötelezettség vagy a kölcsönös bizalom körül, például hűtlenség miatt.</li>
                                    <li className="couple-has-before">Döntés meghozatala a kapcsolat folytatásáról vagy egy esetleges válásról.</li>
                                    <li className="couple-has-before">Közös életterv, közös értékek és prioritások áttekintése.</li>
                                    <li className="couple-has-before">Származási családdal kapcsolatos viták.</li>
                                    <li className="couple-has-before">Veszteségekkel, traumatikus helyzetekkel kapcsolatos válságok, például betegség, munkanélküliség.</li>
                                    <li className="couple-has-before">A pár életciklusának különböző szakaszaihoz kapcsolódó  változások vagy kihívások esetén: érzelmi elköteleződés, együttéléshez kapcsolódó megállapodások, szülővé válásról vagy több gyermekvállalásról szóló döntések, a családi élet és a munka összeegyeztetése, házastársi szerepek stb.</li>    
                              </ul>
                        </div>
                  </div>
            </div>
      </div>
      {/*Couple second section ends*/}

      {/*Couple-3 section starts*/}
      <div className="mf-page section mf-couple2-section3" data-id="">
            <div className="container mf-couple2-container p-0">
                  <div className="mf-couple-row">

                        <div className="mf-couple-info padd-20">
                              <div className="mf-widget-heading-title mf-couple-title-62m85f97 w-1" data-id="62m85f97">
                                    <h3 className="mf-heading-title">A párkapcsolati tanácsadás segíthet!</h3>
                              </div>
                              
                              <div className="mf-widget-text-description mf-couple-text-57m75f29" data-id="57m75f29">
                                    <p className="mf-text-description">
                                          Minden pár saját maga határozza meg, hogy mennyire súlyosak a problémák a saját kapcsolatában. Ami az egyik pár életében nem jelent gondot, egy másik kapcsolatban erősen megterhelő lehet.
                                    </p>
                              </div>

                              <div className="mf-widget-text-description mf-couple-text-58m64f31" data-id="58m64f31">
                                    <p className="mf-text-description">
                                          Sokéves szakmai tevékenységem alatt megtapasztaltam mennyire fontos  a sokféle megközelítés megismerése és szükség esetén kombinálása. Családkonzulensként ezeket a megközelítéseket felhasználva meg tudom mutatni hogyan erősítheti meg a párkapcsolatát, hogyan válhat érzékenyebbé az egymással való bánásmódban.
                                    </p>
                              </div>
                        </div>

                        <div className="mf-couple-img padd-20">
                              <img src={ParKapcs} alt=""/>
                        </div>


                  </div>

            </div>

      </div>
      {/*Couple-3 section ends*/}

      {/*Couple-4 section starts*/}
       <div className="mf-page section mf-couple3-section4" data-id="">
            <div className="container mf-couple3-container p-0">

                  <div className="mf-couple1-row">

                        <div className="mf-couple-info1 padd-20">

                              <div className="mf-widget-heading-title mf-couple-title-58m75f87 w-1" data-id="58m75f87">
                                    <h3 className="mf-heading-title">GYIK a párkapcsolati tanácsadásról</h3>
                              </div>

                              <div className="accordion">
                                    <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                                    <div className="label3" onClick={() => toggleAccordion(1)}>Mennyi ideig tart a párkapcsolati tanácsadás?</div>
                                    {openState[1] && (
                                          <div className="content3">
                                                <p>A tanácsadás többnyire akkor eredményes, ha a pár tud és akar is időt áldozni arra, hogy valóban foglalkozzon a problémáival.  Néha csak néhány alkalomra van szükség ahhoz, hogy a kommunikációs probléma megoldódjon és a pár visszanyerje egyensúlyát. A helyzettől függően a munka tovább is tarthat, ha a pár működési zavarai múltbéli problémákra, különösen a származási családdal való nehéz vagy konfliktusos kapcsolatra utalnak. Az első 1-2 alkalom elsősorban információgyűjtésről szól. Ezen ülések alatt megpróbáljuk közösen meghatározni a célokat, amiért érdemes együtt dolgozni.</p>
                                          </div>
                                    )}
                                    </div>


                                    <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                                    <div className="label3" onClick={() => toggleAccordion(2)}>Milyen gyakoriak az ülések?</div>
                                    {openState[2] && (
                                          <div className="content3">
                                                <p>Az ülések gyakorisága általában 2 hetente egy alkalom, de krízis idején hamarabb is sor kerülhet találkozóra.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                                    <div className="label3" onClick={() => toggleAccordion(3)}>Mennyibe kerül a párkapcsolati tanácsadás?</div>
                                    {openState[3] && (
                                          <div className="content3">
                                                <p>A <b>90 perces</b> ülés <b>22.000 forint.</b><br/>A kettős vezetésű <b>90 perces</b> ülés <b>32.000 forint.</b> </p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                                    <div className="label3" onClick={() => toggleAccordion(4)}>Van még kérdése?</div>
                                    {openState[4] && (
                                          <div className="content3">
                                                <p>Látogassa meg a <Link to="/Gyik_tanacsadas">Gyakran Ismételt Kérdések</Link> oldalt, ahol további kérdésekre is választ kaphat! </p>
                                          </div>
                                    )}
                                    </div>

                              </div>

                        </div>

                        <div className="mf-couple-info4 padd-20">

                        </div>



                  </div>
            </div>

      </div>
      {/*Couple-4 section ends*/}

      {/*Couple contact section starts*/}
      <div className="mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>           
                        </div>
                  </div>
            </div>

      </div>
      {/*Couple contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

</div>
  )
}
