import React from "react";
import { Link } from "react-router-dom";

import "../../CSS/valasi_tanacsadas.css"

import DivCoaching01 from "../../image/mf_dcoaching_img.png"
import DivCoaching02 from "../../image/mf_dc_coaching.png"
import DivCoachingLogo01 from "../../image/mf_uj logo_1.png"
import DivCoachingDec from "../../image/mf_dc_decision_img.png"
import DivCoachingProc from "../../image/mf_dc_process_img.png"
import DivCoachingEmo from "../../image/mf_dc_emotion_img.png"
import DivCoachingParent from "../../image/mf_dc_co_parenting_img.png"

export default function Valasi_tanacsadas() {
  return (

    <div className="mf-3227"  id="mf-page" data-id="3227">
      
      {/*hero section starts*/}   
      <div className="mf-page section section-img-bg-86m36f35" data-id="86m36f35">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-21m12f56 w-1" data-id="21m12f56">
                                          <h1 className="mf-heading-title">Válási tanácsadás<span>személyesen és online</span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>    
      {/*hero section ends*/}

      {/*Divorce coaching first section*/}
      <div className="mf-page section mf-dc-section" data-id="">
            <div className="container mf-dc-container p-0">
                  <div className="mf-dc-row">
                        <div className="mf-dc-left padd-15">
                              <div className="mf-dc-img">
                                    <img src={DivCoaching01} alt=""/>
                              </div>
                        </div>
                        <div className="mf-dc-right padd-15">
                              <div className="mf-text-widget">
                                    <div className="mf-widget-heading-title mf-dc-title-48m34f29 w-1" data-id="48m34f29">
                                          <h3 className="mf-heading-title">Mi a válási tanácsadás?</h3>
                                    </div>
                                    <div className="mf-widget-heading-title mf-dc-title-73m86f92 w-1" data-id="73m86f92">
                                          <h5 className="mf-heading-title">Egy professzionálisan irányított tanácsadás, amely segít leküzdeni az akadályokat, és segítséget nyújt az új jövő elképzeléséhez, megteremtéséhez.</h5>
                                    </div>
                              </div>

                        </div>
                  </div>

            </div>
      </div>

      {/*divorce coaching second section start*/}
      <div className="mf-page section mf-dc-section2" data-id="">
            <div className="container mf-dc-container2 p-0">
                  <div className="mf-dc-row-1">

                        <div className="mf-dc-left-1 padd-15">
                              <div className="mf-text-widget">
                                    <div className="mf-widget-heading-title mf-dc-title-76m39f21 w-1" data-id="76m39f21">
                                          <h3 className="mf-heading-title">Hogyan segíthet a válási tanácsadás?</h3>
                                    </div>
                                    <div className="mf-widget-text-description mf-dc-text-27m63f03" data-id="27m63f03">
                                          <p className="mf-text-description">
                                                A házasság vagy egy kapcsolat vége érzelmileg megterhelő lehet, függetlenül attól, hogy mennyire biztos Ön a döntésében.
                                          </p>
                                    </div>
                                    <div className="mf-widget-text-description mf-dc-text-27m63f03" data-id="27m63f03">
                                          <p className="mf-text-description">
                                                A válási tanácsdás célja, hogy segítsen Önnek eligazodni a válási folyamattal járó kihívásokban. 
                                                A tanácsadás segíthet az érzelmek kezelésében, a hatékony kommunikáció kialakításában  és a pozitív előrelépéshez szükséges készségek fejlesztésében.
                                          
                                          </p>
                                    </div>

                                    <div className="mf-widget-text-description mf-dc-text-27m63f03" data-id="27m63f03">
                                          <p className="mf-text-description">
                                                Válás során egy tanácsadóval való együttműködés megkönnyítheti a folyamatot. Azok, akik a válás előtt és alatt keresnek fel 
                                                egy tanácsadót, általában megalapozottabban, racionálisabban készülnek arra, hogy szembenézzenek a válás hatásaival. 
                                                A tanácsadó segíthet a továbblépés tervének kidolgozásában, beleértve a célkitűzést és a támogató hálózat létrehozását.
                                          </p>
                                    </div>
                                    
                              </div>
                        </div>


                        <div className="mf-dc-right-1 padd-15">
                              <div className="mf-dc-img-1">
                                    <img src={DivCoaching02} alt=""/>
                                    
                              </div>
                        </div>
                  </div>



            </div>
      </div>

      {/*divorce coaching second section end*/}

      {/*divorce coaching 3 section start*/}
      <div className="mf-page section mf-dc-section3" data-id="">
            <div className="container mf-dc-container3 p-0">
                  <div className="widget-container-img">
                        <img src={DivCoachingLogo01}/>
                  </div>
                  <div className="mf-widget-heading-title mf-dc-title-48m54f75 w-1" data-id="48m54f75">
                        <h3 className="mf-heading-title">Válási tanácsadóként segíthetek Önnek a válás előtti, alatti és utáni támogatásban, az Ön egyedi helyzetétől és a válási folyamat aktuális szükségleteitől függően.</h3>
                        <br/>
                        <h3 className="mf-heading-title">A velem, mint válási tanácsadóval történő munka előnye abban rejlik, hogy Ön kiválaszthatja azt a támogatási formát, amelyre szüksége van.</h3>
                  </div>
            </div>
      </div>
      {/*divorce coaching 3 section end*/}

      {/*divorce coaching 4 section start*/}
      <div className="mf-page section mf-dc-section4" data-id="">
            <div className="container mf-dc-container4 p-0">
                  <div className="mf-dc-img-card">
                        <img src={DivCoachingDec} alt="" className="dc-card-img"/>
                        <div className="mf-widget-heading-title mf-dc-title-27m68f92 w-1" data-id="27m68f92">
                              <h4 className="mf-heading-title">Áttekintés</h4>
                        </div>
                        <div className="mf-widget-text-description mf-dc-text-34m54f86" data-id="34m54f86">
                              <p className="mf-text-description">
                                    Döntést nem hozhatok senki helyett! 
                              </p>
                              <p className="mf-text-description">Azonban segíthetek 
                                    a válást fontolgató pároknak abban, hogy végiggondolják a helyzetüket 
                                    azért, hogy magabiztosabbak legyenek a házasságuk jövőjével kapcsolatos döntésekben. 
                                    </p>
                        </div>
                  </div>
                  <div className="mf-dc-img-card">
                        <img src={DivCoachingProc} alt="" className="dc-card-img"/>
                        <div className="mf-widget-heading-title mf-dc-title-27m68f92 w-1" data-id="27m68f92">
                              <h4 className="mf-heading-title">Útmutatás</h4>
                        </div>
                        <div className="mf-widget-text-description mf-dc-text-34m54f86" data-id="34m54f86">
                              <p className="mf-text-description">
                                    A válási folyamat során az útmutatás segíthet megérteni, hogy mire számíthat, segítséget nyújthat a személyes és anyagi szükségletek átgondolásában.
                              </p>
                        </div>
                  </div>
                  <div className="mf-dc-img-card">
                        <img src={DivCoachingEmo} alt="" className="dc-card-img"/>
                        <div className="mf-widget-heading-title mf-dc-title-27m68f92 w-1" data-id="27m68f92">
                              <h4 className="mf-heading-title">Érzelmi feldolgozás</h4>
                        </div>
                        <div className="mf-widget-text-description mf-dc-text-34m54f86" data-id="34m54f86">
                              <p className="mf-text-description">
                                    Támogatást nyújthatok a válási folyamatban megjelenő érzelmek feldolgozásához, hogy jelenlegi helyzetéből megerősödve lépjen tovább egy egészséges és alapjában véve boldog élet felé.  
                              </p>
                        </div>
                  </div>
                  <div className="mf-dc-img-card">
                        <img src={DivCoachingParent} alt="" className="dc-card-img"/>
                        <div className="mf-widget-heading-title mf-dc-title-27m68f92 w-1" data-id="27m68f92">
                              <h4 className="mf-heading-title">Szülői kapcsolat </h4>
                        </div>
                        <div className="mf-widget-text-description mf-dc-text-34m54f86" data-id="34m54f86">
                              <p className="mf-text-description">
                                    Az egészséges és együttműködő szülői kapcsolatok a gyermekek javát szolgálják. 
                                    A válási tanácsadás segíthet abban, hogy a gyermekeik középpontba helyezésével  jobb szülőtársakká váljanak.
                              </p>
                        </div>

                  </div>
                  

            </div>
      </div>
      {/*divorce coaching 4 section ends*/}

      {/*divorce coaching 5 section start*/}
      <div className="mf-page section mf-mediation-info-section" data-id="">
            <div className="container mf-mediation-info-container p-0">
                  <div className="mf-mediation-info-box">
                        <h2 className="mediation-info-title">További információért töltse le a válási mediációs útmutatót</h2>
                  </div>  
                  <div className="mf-mediation-info-btnBx">
                        <div className="mf-mediation-info-btn btn"><Link to="https://gemeterzsuzsa.hu/static/media/utmutato_a_valasi_mediaciohoz.pdf">Válási mediációs útmutató</Link></div>
                  </div>
            </div>
            
      </div>
      {/*divorce coaching 5 section start*/}

      {/*contact section starts */}
      <div className="mf-page section mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>
                  <div className="mf-contact-row">
                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>
                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>       
                        </div>
                  </div>
            </div>
      </div>
      {/*contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
