import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog16Hero from "../../../image/blog_16_hero_a_szuloi_ongondoskodas.png"
import Blog16_1Hero from "../../../image/blog_16_szuloi_öngondoskodas.png"

export default function Blog_16_a_szuloi_gondoskodas_jelentosege() {
  return (
    <div className="mf-4306" id="mf-page" data-id="mf-4306">
      <div className="mf-page section section-blog-globale">
        <div className="container mf-blog-globale-container p-0">
          <div className="mf-blog-globale-hero">
            <img src={Blog16Hero} alt="a_szuloi_ongondoskodas_jelentosege"/>
          </div>
    

          <div className="mf-blog-globale-face">
            <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
              <h2 className="mf-blog-globale-heading-title">A szülői öngondoskodás jelentősége</h2>
            </div>

            <div className="mf-blog-globale-profile-container">
              <div className="mf-blog-globale-profile">
                <div className="mf-blog-globale-img-container"></div>   
    

                <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                  <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                  <p className="mf-blog-globale-text-description">2024. március 28.</p>
                </div>
              </div>
        
              <div>
              <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
              </div>
            </div>
          </div>

          <div className="mf-blog-globale-column">

            <div className="mf-blog-globale-text">
                  

                  <p>
                        A szülői tanácsadásaim során sokszor hangzik el a szülők szájából, az a mondat, hogy <i>„Akkor jó egy szülő, ha az gyermeke minden vágyát, igényét és szükségletét kielégíti, bármi áron.”</i> Teljesen egyetértek azzal, hogy szeresd teljes szívedből a gyerekedet, és fontosnak tartom, hogy tudasd vele hogy különleges, gyönyörű és mindentől függetlenül szereted! De fontos, hogy <b>Te is számítasz!</b>
                  </p>

                  <p>
                        Sokszor látom a szülőkön, hogy fáradtak, kimerültek, nem tudnak már többet adni magukból, elérték a végső határaikat. Miközben szülőként adnak és adnak saját magukból, és megpróbálnak a legjobb szülei lenni a gyermeküknek, lassan feláldozzák a saját szükségleteiket. Aztán egyszer csak eljön az az pillanat, hogy az erőforrásaik már annyira kimerülnek, hogy nem tudják senki igényét kielégíteni. A legrosszabb szülőnek kezdik érezni magukat, elkeseredettek, frusztráltak lesznek és kezdi az egész helyzetet lehetetlennek érezni. 
                  </p>

            
                  <p>
                        Igen, a gyermeknevelés nem könnyű, és elég nagy nyomás (külső és belső) nehezedik szülőként rád, hogy a gyermeked számára a legjobbat biztosítsd. Azonban az, hogy feladj mindent (hobbi, idő, pihenés), hogy jó szülő legyél, hosszú távon nem célravezető megoldás.
                  </p>

            
            </div>

            <div className="mf-blog-globale-img-container">
                  <img src={Blog16_1Hero} alt="Mire szamithat a parkonzultacion"/>
            </div>

            <div className="mf-blog-globale-text">

                  <h4>
                        Amit tehetsz:
                  </h4>
                  
                  <h6>
                        Gyakorold az öngondoskodást!
                  </h6>

                  <p>
                        Ha utaztál már repülővel tudod, hogy ha használnod kell az oxigénmaszkot, akkor előbb magadra kell, hogy feltedd, mielőtt valaki másra feltennéd. Hogy miért? Mert ellenkező esetben elveszíted az eszméleted, és nem tudsz segíteni másoknak. Azaz, ha a saját igényeid, szükségleteid nem teljesülnek, akkor nem tudod kielégíteni gyermeked igényeit és szükségleteit sem.
                  </p>

                  <p>
                        Találd meg a számodra megfelelő öngondoskodást! Ez lehet barátokkal való találkozás, edzés, kirándulás, olvasás. Nem számít, hogy mit teszel, csak az, hogy rendszeres legyen és erőforrásként szolgáljon a számodra.
                  </p>

                  <h6>
                        Találd meg a szülői stílusod!
                  </h6>

                  <p>
                        Manapság nagyon sokféle nevelési trendről lehet, hallani és olvasni és többnyire mindenkinek meg van az elképzelése és a véleménye arról, hogy melyik a legjobb módszer és miért. Ha azonban a legjobb módon szeretnéd nevelni a gyermekedet, akkor a saját értékeid szerinti nevelés az, amit választhatsz. Ezzel a nevelési stílussal magabiztosan tudsz szembenézni a kihívásokkal. Te neveled a gyerekedet, és azt a magad módján kell megtenned.
                  </p>

                  <h6>
                        Ne tedd magad felelőssé gyermeked érzelmeiért!
                  </h6>

                  <p>
                        Nem teheted boldoggá a gyermeked, mert az nem lehetséges. Nem lehet az érzelmeket rájuk rákényszeríteni, legyen szó boldogságról, haragról vagy szomorúságról. Az érzések, amelyeket átélnek, az az övék, legyenek azok pozitívak vagy negatívak. Ráadásul az érzelmek jönnek és mennek, ami teljesen normális. 
                  </p>

                  <p>
                        Szülőként legjobb, amit tehetsz, hogy gyermekeddel beszélgetsz az érzelmekről, az érzelmeiről és felkészíted azok folyamatos változására. Nem tudod megmenteni a gyermeked a negatív érzelmektől és annak hatásaitól, de megtaníthatod arra, hogyan kezelje azokat megfelelően, amikor szembe találkozik velük.
                  </p>

                  <h6>
                        Végül:
                  </h6>

                  <p>
                        Először is szeresd magad és vigyázz magadra, mert csak így taníthatod meg gyermekedet is erre.
                  </p>
                  <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
            </div>
          </div>
          <div className="mf-blog-global-tags">
            <p>Címkék:</p>
            <a href="#">szülőség</a>
                
          </div>
        </div>
      </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
