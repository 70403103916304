import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog21Hero from "../../../image/blog_21_hero_hogyan_mukodik_a _valasi mediacio.png"

export default function Blog_21_hogyan_mukodik_a_valasi_mediacio() {
  return (
    <div className="mf-4301" id="mf-page" data-id="mf-4301">
    <div className="mf-page section section-blog-globale">
      <div className="container mf-blog-globale-container p-0">
        <div className="mf-blog-globale-hero">
          <img src={Blog21Hero} alt="hogyan_mukodik_a_valasi_mediacio"/>
        </div>
  

        <div className="mf-blog-globale-face">
          <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
            <h2 className="mf-blog-globale-heading-title">Hogyan működik a válási mediáció?</h2>
          </div>

          <div className="mf-blog-globale-profile-container">
            <div className="mf-blog-globale-profile">
              <div className="mf-blog-globale-img-container"></div>   
      
              <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                    <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                    <p className="mf-blog-globale-text-description">2024. május 29.</p>
              </div>
            </div>
      
            <div>
            <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
            </div>
          </div>
        </div>

        <div className="mf-blog-globale-column">

              <div className="mf-blog-globale-text">
                    <p>
                          A <Link to="/Valasi_Mediacio">válási mediáció</Link> egy olyan konfliktuskezelési mód, amelyet az emberek a peres eljárás helyett választanak (amelyben bírósághoz fordul, ahol a bíró meghallgatja mindkét ügyvéd érvelését, és döntéseket hoz a szülői felügyeletről, a kapcsolattartásról, a tartásdíjról, a közös lakás használatáról, vagyonról és egy esetleges házastársi tartásról.) 
                    </p>

                    <p>
                          A közvetítői folyamat magában foglalja egy mediátor (semleges fél) részvételét, aki segít a váláshoz szükséges szülői és pénzügyi megállapodások létrejöttében. A mediációs folyamat végén – amikor a válófélben lévő pár minden feltételben megállapodott – a Válási Mediációs Megállapodás (tartalmazza a Polgári Törvénykönyv szerinti házasság közös megegyezéssel történő felbontásához szükséges elemeket) a keresetlevél mellékleteként benyújtható a bíróságra.
                    </p>
                    <p>
                          Megértem, hogy milyen nehéz az a gondolat, hogy a mediáció során egy szobában kell ülnie azzal, akire esetleg haragszik vagy dühös. Nem mondom azt, hogy ez kellemes, de azt igen, hogy a tapasztalataim azt mutatják, hogy a mediáció működőképes megoldás a válás rendezéséhez.
                    </p>

                    <p>
                          Nem csak időt és pénz takaríthat meg a mediációval, hanem egy olyan új kezdetet is adhat válás utáni életének, amelynek úgy indul neki, hogy volt beleszólása jövője alakításába. 
                    </p>

                    <h4>
                          <i>A válási mediáció folyamata lépésről - lépésre</i>
                    </h4>

                    <h4>
                          1. Információ - kapcsolatfelvétel
                    </h4>

                    <p>
                          Az első 20 perces ingyenes telefonos vagy online beszélgetés során meghallgatom Önt. Tegyen fel annyi kérdést, amennyit szeretne, hogy megértse a válási mediáció folyamatát, így a beszélgetés közben mind a ketten mérlegelhetjük, hogy a válási mediáció-e a célravezető megoldás az Ön és házastársa számára.
                    </p>

                    <h4>
                          2. Felkészülés a válási mediációra - Gondolja át, mit is szeretne valójában és mi a legfontosabb, amit el szeretne érni a válás során.
                    </h4>

                    <p>
                          A válási mediációra való felkészüléshez először fel kell készülnie a válásra.
                    </p>
                    <p>
                          <b>Érzelmi felkészülés</b>
                    </p>

                    <p>
                          Az a tapasztalatom, hogy a válásra a gyakorlati felkészülés könnyebb, mint az érzelmi felkészülés.
                    </p>

                    <p>
                          Sokszor az egyik fél <a href="#">nem ugyanott van érzelmileg</a> mint a másik fél. Lehet, hogy az egyik fél készen áll a mediációra és talán már meg is tervezte, hogyan működhet a válás, míg a másik fél nem igazán áll készen a házasság felbontására. Mediátorként az a feladatom, hogy lehetőségeimhez mérten segítsem Önöket, hogy érzelmileg egy lapra kerüljenek. Hasznos lehet ehhez, hogy a mediációs folyamat megkezdése előtt egyéni konzultáción vagy válási tanácsadáson vegyen részt az a fél, aki még mindig úgy gondolja, hogy a házasság megoldható. 
                    </p>

                    <p>
                          <b>Pénzügyi felkészülés</b>
                    </p>

                    <p>
                          A válási mediációra való felkészülést érdemes azzal kezdeni, hogy összegyűjti a pénzügyi vonatkozású iratokat (bankszámlakivonatok, magánnyugdíjszámlák, befektetési számlák). Azzal, hogy  összegyűjtésre kerülnek ezek  a dokumentumok, azáltal simábbá válik a válási mediáció folyamata. 
                    </p>

                    <p>
                          Fontos, hogy megértse pénzügyeit, ezért miután összegyűjtötte a dokumentumokat, esetleg konzultáljon valakivel, aki tanácsot tud adni Önnek és segíti abban, hogy független és kívülálló szemszögből nézzen rá arra. Minél felkészültebb, amikor elkezdi megvitatni a pénzügyeit, annál könnyebb lesz a folyamat során a saját nézőpontjának és érdekének képviselése.
                    </p>

                    <p>
                          <b>Ügyvédi konzultáció</b>
                          
                    </p>

                    <p>
                          A folyamat során nem adok jogi tanácsot, éppen ezért érezheti úgy, hogy a válási mediáció előtt egy ügyvéddel való találkozás segíthet abban, hogy más szemszögből lásson a folyamatra. Egy olyan személlyel való találkozás, aki csak az Ön szemszögéből nézi a válást, megnyugtathatja Önt és ezáltal felkészültebbnek és tájékozottabbnak érezheti magát abban, hogy mi történhet akkor, ha nem tud megegyezni valamiben a mediáció során. Nem kell ügyvédet fogadnia mielőtt elkezdi a mediációt, de ha egy-két tanácsadásért fizet, értékes információkat kaphat, ami könnyebbé teheti a mediáció folyamatát.
                    </p>

                    <p>
                          A fentieket figyelembe véve, az előkészítő ülésre próbálja meg átgondolni, hogy mit szeretne, és mi az, ami fontos az Ön számára, és mi az, ami a legjobb a gyermekeik részére.
                    </p>

                    <h4>
                          3. Közös mediációs ülés(ek)
                    </h4>

                    <p>
                          Fontos megérteni, hogy a mediáció olyan, mint egy üzleti döntés. Az én közvetítői szerepem az, hogy segítsek Önöknek eljutni a válási folyamat végére. Tisztában vagyok vele, hogy a válás nagyon heves érzelmekkel jár. Nem tudom elvenni ezeket az érzelmeket, de együtt érzek azzal, amin keresztül mennek. 
                    </p>

                    <p>
                          Mediátorként abban segítem Önt és házastársát, hogy a folyamat gyakorlati oldalára összpontosíthassanak anélkül, hogy az érzelmek elsodornák. Abban segítem Önöket, hogy megtalálják a mindenki számára elfogadható megoldást.
                    </p>

                    <p>
                          A  gyermekeik érdekeire való összpontosítás sokkal gördülékenyebbé teheti a folyamatot és zökkenőmentesebbé azok életét.
                    </p>

                    <h4>
                          4. Megállapodás megírása
                    </h4>

                    <p>
                          Miután a felek minden lényeges kérdésben megállapodásra jutottak, sor kerül a végső megállapodás megfogalmazásra és megírására. A megállapodás nem jogi szerződés, hanem a probléma megoldásához szükséges kötelezettségek összegzése. A megállapodás egyértelműsíti, hogy a két fél miben jutott egyezségre. 
                    </p>
                    <p>
                          A <b>Válási Mediációs Megállapodás</b> tartalmazni fogja azokat az elemeket, amelyek a Polgári Törvénykönyv szerint a házasság közös megegyezéssel történő felbontásához szükségesek. A <b>Válási Mediációs Megállapodás</b> benyújtása a keresetlevél mellékleteként, lehetővé teszi, hogy a bontóper ne húzódjon hónapokon vagy éveken keresztül.
                    </p>

                    <p>
                          Zárásként egy megjegyzés:
                    </p>

                    <p>
                          Egy konfliktusos helyzet jogi úton történő rendezése során a jog, az egyik fél jogi fölényét biztosítja az ellenérdekű fél felett, és a per során született ítéletet kényszeríti rá a felekre, amelynek egy győztese és egy vesztese van.
                    </p>

                    <p>
                          Megértem, hogy a közvetítés nem minden váló pár számára megfelelő megoldás. Ha a válás nagyon vitás és túl sok a harag, vagy az egyik fél hajthatatlan és beásta magát a „lövészárokba”, akkor a peres eljárás tűnhet az egyetlen alternatívának. Azonban a tapasztalataim azt mutatják, hogy még a legellentmondásosabb válásoknál is hasznos lehet a mediáció, sőt gyakran maguk a bírák is a peres eljárásban is közvetítőhöz küldik a vitás párokat.
                    </p>

                    <p>
                          Úgy vélem, hogy a válási mediáció azoknak a pároknak hasznos megoldás, akik válásuk során bele akarnak szólni a válási megállapodás feltételeibe, jó szülői kapcsolatra próbálnak törekedni és akik, időt és jelentős pénzösszeget szeretnének megtakarítani a válás során.
                    </p>
                    <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
              </div>
        </div>
        <div className="mf-blog-global-tags">
              <p>Címkék:</p>
              <a href="/Blog">mediáció</a>
              
        </div>
      </div>
    </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
        <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>

  )
}