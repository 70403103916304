import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog13Hero from "../../../image/blog_13_hero_onbizalom.png"

export default function Blog_13_onbizalom() {
  return (
    <div className="mf-4409" id="mf-page" data-id="4409">
    <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog13Hero} alt="Az_onbizalom_sajat_magad_megismeresevel_kezdodik"/>
                </div>
          

                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">Az önbizalom saját magad megismerésével kezdődik!</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2024. február 13.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>



                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">
                            <p>
                                  Az önmagadba vetett hit elengedhetetlen, ha sikeres akarsz lenni az életben, legyen szó munkáról, kapcsolatokról vagy bármi másról. Az önbizalomról (és arról, hogy mi kell hozzá) sokkal könnyebb beszélni, mint tenni érte, cselekedni (felépíteni). Számos pszichológiai tanulmányban olvasható, hogy a bennünket érő negatív tapasztalatok, más emberek velünk szemben megfogalmazott kemény kritikája vagy a saját magunkkal szemben felállított (gyakran) képtelen elvárások az önbizalomhiány gyakori okai. 
                            </p>

                            <p>
                                  Ahhoz, hogy önbizalmunk felépüljön, időre van szükség. Ne legyünk türelmetlenek magunkkal szemben, ráadásul minél többet dolgozunk rajta annál jobban fog erősödni. Íme, néhány tipp, hogy mit tehetsz önbizalmad építéséhez, erősítéséhez.
                            </p>

                            <h4>
                                  Vigyázz a testedre!
                            </h4>

                            <p>
                                  Ha jól érzed magad a bőrödben , akkor automatikusan magabiztosabbnak is fogod érezni magad.  Éppen ezért fontos vigyázni a testünkre, legyen szó erőnlétről, étkezésről vagy akár a pihenésről. Nem kell itt óriási dolgokra gondolni. A napi rutinná tett rövid fitneszedzést, a tudatosabb, egészségesebb táplálkozást vagy a szükséges pihenést testünk meghálálja, azzal hogy <a href="">jó közérzetet</a> teremt számunkra. 
                            </p>

                            <h4>
                                  Dolgozz a gondolataidon!
                            </h4>

                            <p>
                                  A gondolataid ugyanolyan fontosak, mint a tested. Ha növelni szeretnéd önbizalmad vagy sikeresebb, jobb élet szeretnél, akkor meg kell tanulnod hogyan reagálj a dolgokra pozitívan, a negatív helyett. Hiszen a negatív gondolatok lerombolják az önbizalmad és szabotálják a sikert. A megfelelő egyensúly megtartásához, hallgasd meg a belső kritikádat, de figyelj arra, hogy az építő jellegű legyen és ne romboló.
                            </p>

                            <h4>
                                  Ismerd el az eredményeidet!
                            </h4>

                            <p>
                                  Sokkal könnyebb hinni magadban, ha azokra a konkrét eredményekre gondolsz, amelyekre büszke lehetsz. Ha tudatosítod magadban, hogy milyen fantasztikus dolgokat csináltál már eddig is az életedben, akkor valószínű, hogy jobban elhiszed, hogy a jövőben is megismételheted a sikert. Nem kell ezeknek az eredményeknek feltétlenül óriásinak lenniük, nem számítanak, hogy mekkorák, az számít, hogy te érted el. Ha nehezen megy számba venni a sikereidet, vagy nem tudod mit is gondolj annak, akkor egy tipp, hogy hogyan fogj hozzá. Pozitívnak lenni nem könnyű, de a hálanapló segíthet.
                            </p>

                            <h6>
                                  Íme, hogyan csináld:
                            </h6>

                            <p>
                                  <i>
                                        Hetente 1-3 alkalommal szánj rá esténként 10 percet. Írj fel a naplódba öt olyan dolgot, amiért hálás vagy. Felírhatsz bármit, a cél az, hogy a jó élményekre összpontosíts, és közben élvezed az ezzel járó pozitív érzelmeket. 
                                  </i>
                            </p>
                            <p>
                                  <i>Idővel eredményeid hatalmas gyűjteményt fognak képezni és hidd el, ez segíthet önbizalmad növelésében.</i>
                            </p>

                            <h4>
                                  Merj váltani, változtatni!
                            </h4>

                            <p>
                                  Vannak olyan helyzetek, amikor a külső környezet és nem a belső gondolatok az okai az alacsony önértékelésnek, akár lehet ez egy rossz, mérgező légkörű munkahely. Merj cselekedni, karriert váltani, ha ez a helyzet. Elvégezhetsz olyan képzést (online, munkaidő után), ami segíthet más pályára állni, ami újra sikerélményt hozhat a számodra.
                            </p>
                            <p>
                                  Ami a legfontosabb, hogy mindenkinek vannak kihívásai, <b>tedd meg, amit tudsz!</b> Az önbizalmat nem adják könnyen, de idővel és kitartással egyre magabiztosabb lehetsz. 
                            </p>
                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                      </div>
                </div>
                <div className="mf-blog-global-tags">
                      <p>Címkék:</p>
                      <a href="#">Önismeret</a>
                      
                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <Link to="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </Link>
      {/*Back to Top end*/}
</div>
)
}
