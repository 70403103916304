import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog19Hero from "../../../image/blog_19_hero_valas_gyakori_reakciok.png"

export default function Blog_19_gyakori_reakciok() {
  return (
    <div className="mf-4302" id="mf-page" data-id="mf-4302">
      <div className="mf-page section section-blog-globale">
        <div className="container mf-blog-globale-container p-0">
          <div className="mf-blog-globale-hero">
            <img src={Blog19Hero} alt="Valas - gyakori reakciok"/>
          </div>
    

          <div className="mf-blog-globale-face">
            <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
              <h2 className="mf-blog-globale-heading-title">Válás I. rész - Gyakori reakciók</h2>
            </div>

            <div className="mf-blog-globale-profile-container">
              <div className="mf-blog-globale-profile">
                <div className="mf-blog-globale-img-container"></div>   
    

                <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                      <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                      <p className="mf-blog-globale-text-description">2024. április 25.</p>
                </div>
              </div>
        
              <div>
              <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
              </div>
            </div>
          </div>



          <div className="mf-blog-globale-column">

                <div className="mf-blog-globale-text">
                      <p>
                            Amikor valaki válással vagy szakítással küzd, sokszor úgy érzi, hogy egyedül maradt, és a helyzetre adott reakcióit pedig nem mindig tekinti normálisnak. 
                      </p>

                      <p>
                            Első fontos lépés, hogy emlékeztesd magadat arra, <b>normálisak a reakcióid és az érzéseid:</b> a fájdalom, az önbizalomhiány, a félelmek, a kritika, a harag, a düh, a vágyakozás mind része annak, hogy gyászolsz. Egy fontos, jelentős személyt „elvesztettél”, és újra kell kezdened az életed.
                      </p>

                      <p>
                            Szánj rá néhány percet, és olvasd el az alábbiakat. Azt követően kérdezd meg magadtól, hogy van-e olyan az olvasottak között, amelyik ismerős. Ha találsz, ne essél kétségbe, vegyél egy mély levegőt és emlékeztesd magadat arra, hogy van út a gyógyuláshoz.
                      </p>

                      <h4>
                            Gyakori reakciók a válás után:
                      </h4>

                      <div className="mf-blog-globale-text">

                            <h6>
                                  Félelem:
                            </h6>

                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">„Mi van, ha örökre egyedül maradok?”</li>
                                  <li className="blog-globale-has-before">„Képes leszek-e valaha végigmenni a régi környékünkön anélkül, hogy összeomlok?”</li>
                                  <li className="blog-globale-has-before">„Mi van, ha a fájdalom sohasem múlik el?”</li>
                                  <li className="blog-globale-has-before">„A barátaink továbbra is a barátaim maradnak?”</li>
                                  <li className="blog-globale-has-before">„Mi van, ha következő partnere jobb, mint én?”</li>
                            </ul>

                            <h6>
                                  Harag:
                            </h6>

                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">"Hogy tehette ezt velem!”</li>
                                  <li className="blog-globale-has-before">„Mindenki olyan idegesítő mostanában az irodában/munkahelyemen.”</li>
                                  <li className="blog-globale-has-before">„A barátaim/családom feldühítenek - egyszerűen nem értenek meg.”</li>
                                  <li className="blog-globale-has-before">„Remélem, egyedül marad, soha nem talál magának senkit!”</li>
                                  
                            </ul>

                            <h6>
                                  Sóvárgás:
                            </h6>

                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">„Tudom, hogy nem kéne, de nagyon hiányzik.”</li>
                                  <li className="blog-globale-has-before">„ Ma nagyszerű dolog történt velem, és úgy szerettem volna megosztani vele."</li>
                                  <li className="blog-globale-has-before">„Hiányzik a lakásunk/házunk.”</li>
                                  
                            </ul>

                            <h6>
                                  Szomorúság:
                            </h6>

                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">„Nem tudom, hogy túl leszek-e valaha ezen, olyan szomorú vagyok.”</li>
                                  <li className="blog-globale-has-before">„Minden elszomorít mostanában. Soha nem szoktam, de még a reklámokon is sírok.”</li>
                                  
                                  
                            </ul>

                            <h6>
                                  Önkritika:
                            </h6>

                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">„Túl nehéz lehetett velem együtt élni, nem vagyok könnyű eset.”</li>
                                  <li className="blog-globale-has-before">„Minden az én hibám, hogy jobban kellett volna hallgatnom/ többet kellett volna tennem/stb…”</li>
                                  <li className="blog-globale-has-before">„Tényleg elengedtem magam az utóbbi időben - azt hiszem talált valakit, aki szebb/okosabb/szórakoztatóbb, mint én.”</li>
                                  
                            </ul>

                            <h6>
                                  Önbizalom:
                            </h6>

                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">„Ezt egyedül nem tudom megcsinálni.”</li>
                                  <li className="blog-globale-has-before">„Igaza volt, amikor azt mondta, hogy nélküle soha nem fog menni.”</li>
                                  <li className="blog-globale-has-before">„Azt sem tudom, hogyan randizzam.”</li>
                            </ul>

                            <p>
                                  Ezek itt mind kezdeti érzések, reakciók. Legyél empatikus saját magadhoz, ne próbáld siettetni a dolgokat. Ehelyett adj magadnak időt az újrakezdéshez.
                            </p>

                            <p>
                                  Folytatás: <Link to="/Blog20">Hogyan lehet felépülni a szakításból?</Link>
                            </p>

                            
                      </div>
                </div>
          </div>
          <div className="mf-blog-global-tags">
            <p>Címkék:</p>
            <a href="#">válás</a>
                
          </div>
        </div>
      </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>

  )
}
