import React, { useEffect }  from "react"
import { Link } from "react-router-dom";
//import { LogoNodejs } from 'react-ionicons'
import"../CSS/main.css"

import ProfilePic from "../image/gemeter_zsuzsa_profile.png"
import HomeServEgyeni from "../image/home_service_egyeni.png"
import HomeServParkapcs from "../image/home_service_parkapcsolat.png"
import HomeServCsalad from "../image/home_service_csalad.png"
import HomeServMozaik from "../image/home_service_mozaikcsalad.png"
import HomeServHazassag from "../image/home_service_hazasag_elotti.png"
import HomeServSzuloi from "../image/home_service_szuloi_coaching.png"
import HomeServValas from "../image/home_service_valas_tan.png"
import HomeServMediacio from "../image/home_service_mediacio.png"
import HomeServCsop from "../image/home_service_csoportok.png"
import HomeServOnline from "../image/home_online_konzultacio.png"

import GemZsu from "../image/gemeter_zsuzsa.png"
import Blog25Blog from "../image/blog_25_5_tipp_a_boldogabb_erosebb_parkapcsolatert.png"
import Blog24Blog from "../image/blog_24_hogyan hat a szulove_valas_a_parkapcsolatra.png"
import Blog23Blog from "../image/blog_23_miert_fontos_az egeszseges_onbecsules.png"
import Message from "../image/mf_massage.png"

export default function LandingPage() {

  return (
    <div>
      <div className="mf-3376"  id="mf-page" data-id="3376">
      {/*Home-hero section starts*/}
      <div className="mf-page section home-section-img-bg-1m1f0101" data-id="1m1f0101">

            
            <div className="container mf-home-container p-0">
                  
                  <div className="mf-home-column-wrap mf-home-col-100">
                        <div className="mf-home-widget-container">
                              <div className="mf-home-widget">
                                    <div className="home-title-7e1f8217 w-1" data-id="7e1f8217">
                                          <h1 className="mf-heading-title">"Amit a cél elérésével kapunk, közel sem olyan fontos,
                                                mint amivé válunk, amíg azt elérjük."</h1> 
                                    </div>
                                    <div className="home-text-71642346" data-id="71642346">
                                          <p className="mf-text-description">/Zig Ziglar/</p>
                                    </div>
                              </div>
                        </div>   
                  </div>
            </div>
      </div>
      {/*home-hero section ends*/}

      {/*home-about second section starts*/}
      <div className="mf-page -section mf-home-about-section" data-id="">

            <div className="container mf-home-about-container p-0">
                  <div className="mf-home-about-row">
                        <div className="mf-home-about-info padd-15">
                              <div className="mf-home-title-75f3e671 w-1" data-id="75f3e671">
                                    <h1 className="mf-heading-title">Gemeter Zsuzsa</h1>
                              </div>
                        
                              <div className="mf-home-title-41f2m498 w-1" data-id="41f2m498">
                                    <h1 className="mf-heading-title">Pár és családkonzulens,<br/>párkapcsolati mediátor, coach</h1>
                              </div>
                  
                              <div className="mf-home-about-text-43b5e218" data-id="43b5e218">
                                    <p className="mf-text-description">
                                    Hiszek abban, hogy mindenki a saját életének a szakértője és nem azért vagyok itt, 
                                    hogy megmondjam mit tegyen, hanem, hogy segítsem hozzáférni a saját bölcsességéhez és elkísérjem az ehhez vezető úton.
                                    </p>
                              </div>
                              <div className="mf-home-about-text-43b5e218" data-id="43b5e218">
                                    <p className="mf-text-description">
                                    Sokszor kérjük szakemberként „Mondjon három tulajdonságot vagy szót, ami meghatározza Önt, de ne gondolkodjon túl sokat”. 
                                    A három szó, amit én mondanék családkonzulensként és emberként, az a
                                    </p>
                              </div>
                              <div className="mf-home-about-title-82f3e673 w-1" data-id="82f3e673">
                                    <h5 className="mf-heading-title">BIZALOM, ŐSZINTESÉG, EMPÁTIA</h5>
                              </div>
                              <div className="mf-home-about-text-43b5e218" data-id="43b5e218">
                                    <p className="mf-text-description">
                                          Amit kínálok, az a nyílt őszinte kommunikáció, elfogadás és figyelem. 
                                          A megoldásközpontú modellt szem előtt tartva, segítek, hogy jobban megértse gondolatait, viselkedését, érzéseit. Végig kísérem a fejlődés és növekedés folyamatán és együtt dolgozunk ki olyan alkalmazható stratégiákat, amelyek segítenek elérni tervezett céljait.
                                    </p>
                              </div>
                              <Link to="/Rolam" className="mf-home-about-btn btn" >Tovább<span><ion-icon name="arrow-forward-outline"></ion-icon></span></Link>
                        </div>

                        <div className="mf-home-about-img padd-15">
                              <img src={ProfilePic} alt=""/>
                        </div>
                  </div>
            </div>
      </div>
      {/*Home-about second section ends*/}

      {/*home-about second section starts (responsive)*/}
      <div className="mf-page -section mf-home-about-section-responsive" data-id="">

      <div className="container mf-home-about-container-responsive p-0">
            <div className="mf-home-about-row">
                  <div className="mf-home-about-info padd-15">
                        <div className="mf-home-title-75f3e671 w-1" data-id="75f3e671">
                              <h1 className="mf-heading-title">Gemeter Zsuzsa</h1>
                        </div>
                  
                        <div className="mf-home-title-41f2m498 w-1" data-id="41f2m498">
                              <h1 className="mf-heading-title">Pár és családkonzulens,<br/>párkapcsolati mediátor, coach</h1>
                        </div>
            
                        <div className="mf-home-about-img padd-15">
                        <img src={ProfilePic} alt=""/>
                        </div>

                        <div className="mf-home-about-text-43b5e218" data-id="43b5e218">
                              <p className="mf-text-description">
                              Hiszek abban, hogy mindenki a saját életének a szakértője és nem azért vagyok itt, 
                              hogy megmondjam mit tegyen, hanem, hogy segítsem hozzáférni a saját bölcsességéhez és elkísérjem az ehhez vezető úton.
                              </p>
                        </div>
                        <div className="mf-home-about-text-43b5e218" data-id="43b5e218">
                              <p className="mf-text-description">
                              Sokszor kérjük szakemberként „Mondjon három tulajdonságot vagy szót, ami meghatározza Önt, de ne gondolkodjon túl sokat”. 
                              A három szó, amit én mondanék családkonzulensként és emberként, az a
                              </p>
                        </div>
                        <div className="mf-home-about-title-82f3e673 w-1" data-id="82f3e673">
                              <h5 className="mf-heading-title">BIZALOM, ŐSZINTESÉG, EMPÁTIA</h5>
                        </div>
                        <div className="mf-home-about-text-43b5e218" data-id="43b5e218">
                              <p className="mf-text-description">
                                    Amit kínálok, az a nyílt őszinte kommunikáció, elfogadás és figyelem. 
                                    A megoldásközpontú modellt szem előtt tartva, segítek, hogy jobban megértse gondolatait, viselkedését, érzéseit. Végig kísérem a fejlődés és növekedés folyamatán és együtt dolgozunk ki olyan alkalmazható stratégiákat, amelyek segítenek elérni tervezett céljait.
                              </p>
                        </div>
                        <Link to="/Rolam" className="mf-home-about-btn btn" >Tovább<span><ion-icon name="arrow-forward-outline"></ion-icon></span></Link>
                  </div>

                  
            </div>
      </div>
      </div>
      {/*Home-about second section ends*/}

      {/*home-service section starts*/}
      <div className="mf-section-service" data-id="">
            <div className="container mf-home-service1-container p-0">
                  <div className="mf-home-service-title-65m32f96 w-1" data-id="65m32f96">
                        <h1 className="mf-heading-title">Szolgáltatások</h1>
                  </div>
            </div>
      </div>
      {/*Home-service section ends*/}

      {/*home-service section content starts*/}
      <div className="mf-page section mf-section-service-content" data-id="">     
        <div className="container mf-home-service-container pt-5 py-lg-5 px-0">

          <div className="mf-col-15 mf-col-md-33 p-2">
            <div className="mf-home-service-content">
              <div className="mf-home-service-bkg"></div>
              <div className="mf-home-service-img">
                    <img src={HomeServEgyeni} id="profile1" alt="egyeni konzultacio"/>
              </div>
              <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                    <h1 className="mf-home-heading-title">Egyéni konzultáció</h1>
              </div>
              <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                    <p className="mf-home-service-text-description">Szeretne tisztán látni, elégedettnek érezni magát? 
                    Személyes konfliktusokat vagy kapcsolati nehézségeket kell megoldania?</p>
              </div>    
              <div>
                    <div className="mf-home-service-soc">             
                          <Link to="/Egyeni_konzultacio" className="mf-home-service-btn btn"  >Bővebben</Link> 
                    </div>               
              </div>    
            </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServParkapcs} id="profile1" alt="Parkapcsolati_tanacsadas"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Párkapcsolati tanácsadás</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretné tudni hol tart a párkapcsolatában és hogyan folytatható boldogan a közös élet? Több közelségre vágyik a párjától?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Parkapcsolat_tanacs" className="mf-home-service-btn btn">Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServCsalad} id="profile1" alt="csaladkonzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Családkonzultáció</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretné ha több harmónia lenne otthon? Jó lenne, ha nyugalom és az öröm állandó része lenne a mindennapi életének?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Csaladkonzultacio" className="mf-home-service-btn btn"  >Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServMozaik} id="profile1" alt="mozaikcsalad konzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Mozaikcsalád-konzultáció</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretné, ha családtagjai között jobb lenne a kommunikáció és összehangoltabban működne a családja? Szívesen beszélgetne eről?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Mozaikcsalad_konz" className="mf-home-service-btn btn"  >Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServHazassag} id="profile1" alt="hazassag elott"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Házasság előtti tanácsadás</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">A házasság nagy lépés! Szeretné ha Ön és a párja felkészültebben vágna neki a közös életüknek?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Hazassag_elott" className="mf-home-service-btn btn"  >Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServSzuloi} id="profile1" alt="szuloi coaching"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Szülő coaching</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szüksége van szülői stratégiára, hogy pozitív kapcsolatot alakítson ki gyermekével?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Szulo_coaching" className="mf-home-service-btn btn"  >Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServValas} id="profile1" alt="valasi tanacsadas"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Válási tanácsadás</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretné végiggondolni a helyzetét, hogy magabiztosabb döntést tudjon hozni a házassága jövőjéről?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Valasi_tanacsadas" className="mf-home-service-btn btn"  >Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServMediacio} id="profile1" alt="mediacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Mediáció</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretne aktív részvevője lenni konfliktusai megoldásának?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Mediacio" className="mf-home-service-btn btn"  >Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={HomeServCsop} id="profile1" alt="csoportok"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Csoportok & Tréningek</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description"><Link to="/OnismeretiTrening">Önismereti tréning</Link><br/><Link to="/ParkapcsolatTrening">Párkapcsolaterősítő tréning</Link><br/><Link to="/IskolaiKonfliktus">Iskolai konfliktuskezelési tréning</Link></p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Csoportok" className="mf-home-service-btn btn"  >Bővebben</Link> 
                            </div>               
                      </div>    
                </div>
          </div>
        </div>
      </div>
      {/*Home-service section content ends*/}

      {/*Home online section starts*/}
      <div className="mf-page section mf-home-online-section" data-id="">

            <div className="container mf-home-online-container p-0">
                  <div className="mf-home-online-row">

                        <div className="mf-home-online-imgbox-large">
                        <div className="mf-home-online-img padd-25">
                              <img src={HomeServOnline} alt=""/>
                        </div>
                        </div>

                        <div className="mf-home-online-info padd-25">
                              <div className="mf-home-online-imgbox-small">
                                    <div className="mf-home-online-img padd-25">
                                    <img src={HomeServOnline} alt=""/>
                                    </div>
                              </div>
                              <div className="mf-widget-home-heading-title mf-home-online-title-8e4f6721 w-1" data-id="8e4f6721">
                                    <h1 className="mf-heading-title">ONLINE KONZULTÁCIÓ</h1>
                              </div>
                        
                              <div className="mf-home-online-title-41f2m502 w-1" data-id="41f2m502">
                                    <h6 className="mf-heading-title"><i>Hozzáférés a segítséghez, bárhol is van.</i></h6>
                              </div>
                  
                              <div className="mf-home-online-text-50m5e218" data-id="50m5e218">
                                    <p className="mf-text-description">
                                          A modern  technológia segítségével a találkozás csak néhány kattintásra van. Mindez a saját otthona kényelméből.
                                    </p>
                              </div>
                              <div className="mf-home-online-btn-box">
                              <Link to="/Kapcsolat" className="mf-home-online-btn btn"  >Kérjen időpontot</Link>
                              </div>

                        </div>
                  </div>
            </div>

      </div>
      {/*Home online section ends*/}

      {/*Home-FAQ start*/}
       <div className="mf-page section mf-home-faq-section" data-id="">
            <div className="container mf-home-faq-container p-0">
                  <div className="mf-home-faqbtn-box">
                        <Link to="/Gyik_tanacsadas" className="mf-home-faq-btn btn"  >Gyakran Ismételt Kérdések és válaszok a konzultációról</Link>
                  </div>

            </div>
      </div>
      {/*Home-FAQ end*/}

      {/*Blog section starts*/}
      <div className="mf-page section mf-home-blog-section" data-id="blog">
            <div className="container mf-home-blog-container p-0">
                  <div className="mf-blog-heading-box">
                        <div className="mf-home-blog-text-56m37f39" data-id="56m37f39">
                              <p className="mf-text-description">
                                    gondolatok, tanácsok & inspirációk
                              </p>
                        </div>
                        <div className="mf-home-blog-title-27m46f81 w-1" data-id="27m46f81">
                              <h1 className="mf-heading-title">Legújabb blogbejegyzések </h1>
                        </div>
                  </div>
            </div>
      </div>
      {/*Blog section ends*/}

      {/*Post section starts*/}
      <div className="mf-page section mf-posts-section" data-id="">
            <div className="container post">
                  {/*Post box 25*/}
                  <div class="post-box mediat">
                              <img src={Blog25Blog} alt="" class="post-img"/>
                              <h2 class="category">kapcsolatok</h2>
                              <Link to="/Blog25" class="post-title">Őt tipp a boldogabb és erősebb párkapcsolatért
                              </Link>
                              <span class="post-date">2024.09.15.</span>
                              <p class="post-description">Időnként a legboldogabb párkapcsolatokban is jelentkeznek nehézségek, azonban megfelelő eszközökkel és stratégiával le lehet küzdeni a felmerülő problémákat. Párokkal végzett munkám tapasztalatai</p>
                              {/*profile*/}
                              <div class="profile">
                                    <img src={GemZsu} alt="" class="profile-img"/>
                                    <span class="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                  {/*Post box 24*/}
                  <div class="post-box mediat">
                              <img src={Blog24Blog} alt="" class="post-img"/>
                              <h2 class="category">kapcsolatok</h2>
                              <Link to="/Blog24" class="post-title">Hogyan hat a szülővé válás a párkapcsolatra?
                              </Link>
                              <span class="post-date">2024.08.13.</span>
                              <p class="post-description">Munkám során gyakran tapasztalom, hogy a párok nincsenek felkészülve arra, hogy az első gyermek születése hogyan fog hatni az életükre és a kapcsolatukra. A hozzám forduló gyermeket nevelő párok</p>
                              {/*profile*/}
                              <div class="profile">
                                    <img src={GemZsu} alt="" class="profile-img"/>
                                    <span class="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                  {/*Post box 23*/}
                  <div class="post-box mediat">
                              <img src={Blog23Blog} alt="" class="post-img"/>
                              <h2 class="category">önismeret</h2>
                              <Link to="/Blog23" class="post-title">Miért fontos az egészséges önbecsülés?
                              </Link>
                              <span class="post-date">2024.07.16.</span>
                              <p class="post-description">Az egészséges önbecsülés motiválhat bennünket céljaink elérésére, mert képesek vagyunk eligazodni az életben, annak tudatában, hogy képesek vagyunk elérni bármit, amire csak gondolunk.</p>
                              {/*profile*/}
                              <div class="profile">
                                    <img src={GemZsu} alt="" class="profile-img"/>
                                    <span class="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

            </div>
      </div>
      {/*Post section ends*/}

      {/*Home cont1 section starts*/}
      <div className="mf-page section mf-home-cont1-section" data-id="">
            <div className="container mf-home-cont1-content-container p-0">
                  <div className="mf-home-cont-title-53f2m503 w-1" data-id="53f2m503">
                        <h4 className="mf-heading-title">Segíthetek?</h4>
                  </div>
                  <div className="mf-home-cont-text-57f5e220" data-id="57f5e220">
                        <p className="mf-text-description">
                              Lépjen kapcsolatba velem az alábbi elérhetőségek egyikén!
                        </p>
                  </div>

            </div>
      </div>
      {/*Home cont1 section ends*/}
      
      {/*Home cont2 section starts*/}
      <div className="mf-page section mf-home-cont-section" data-id="">

            <div className="container mf-home-cont-container p-0">
                  <div className="mf-home-cont-row">
                        <div className="mf-home-cont-card">
                              <div className="mf-home-cont-icon">
                                    <i className="fas fa-map-marker-alt"></i>
                              </div>
                              <div className="mf-home-cont-info">
                                    <h3 className="adress">Keressen!</h3>
                                    <p className="adress-text"><Link to="https://www.google.com/maps/place/Budapest,+Platina+u.+1,+1223/@47.4122232,19.0214719,17z/data=!3m1!4b1!4m6!3m5!1s0x4741e7a87f3240c7:0xcda504e38038b15e!8m2!3d47.4122196!4d19.0240468!16s%2Fg%2F11csj4sx99?entry=ttu" target="_blank">Budapesten,</Link>  <Link to="http://maps.google.com/?q=Budaörs+napsugar+setany+4" target="_blank">Budaörsön</Link></p>
                              </div>
                        </div>
                        <div className="mf-home-cont-card">
                              <div className="mf-home-cont-icon">
                                    <i className="fas fa-phone"></i>
                              </div>
                              <div className="mf-home-cont-info">
                                    <h3 className="phone">Hívjon fel!</h3>
                                    <p className="phone-text">+36 70 418 1114</p>
                              </div>
                        </div>
                        <div className="mf-home-cont-card">
                              <div className="mf-home-cont-icon">
                                    <i className="fas fa-envelope"></i>
                              </div>
                              <div className="mf-home-cont-info">
                                    <h3 className="email">Írjon nekem!</h3>
                                    <p className="email-text">info@gemeterzsuzsa.hu</p>
                              </div>
                        </div>
                        
                        <div className="mf-home-cont-card">
                              <div className="mf-home-cont-icon">
                                    <i className="fab fa-facebook-f"></i>
                              </div>

                              <div className="mf-home-cont-info">
                                    <Link to="https://www.facebook.com/profile.php?id=100086794785366">
                                    <h3 className="social">Facebook</h3>
                                    </Link>
                                    <p className="social-text"><Link to="https://www.facebook.com/profile.php?id=100086794785366">megoldasfokusz</Link></p>
                              </div>

                        </div>
                        
                  </div>
            </div>
      </div>
      {/*Home cont2 section ends*/}

      {/*Home cont3 section starts*/}
      <div className="mf-page section mf-home-cont2-section" data-id="">

        <div className="container mf-home-cont2-container p-0">
          <h3 className="mf-home-message-title">Küldjön üzenetet<span>nekem!</span></h3>

          <div className="mf-home-cont2">
                <div className="mf-home-contact-form-box">
                      <div className="left">
                            <div className="mf-contact-img">
                                  <img src={Message} alt=""/>
                            </div>
                      </div>
                      
                      <div className="right">
                        <form action="mail.php" method="POST">
                            <div className="mf-home-contact-input-box">
                                  <input type="text" id="name" name="name"  placeholder="Név" required="required"/>
                            </div>
                            
                            <div className="mf-home-contact-input-box">
                                  <input type="email" id="email" name="email"  placeholder="e-mail cím" required="required"/>
                            </div>

                            <div className="mf-home-contact-input-box">
                                  <input type="text" id="mobil" name="mobil"  placeholder="Mobilszám" />
                            </div>

                            <div className="mf-home-contact-input-box">
                                  <textarea name="message" rows="4" cols="80" placeholder="Írjon üzenetet!" required="required"></textarea>
                            </div>
                            <div className="mf-landing-contact-check-box">
                            <label for="vehicle"></label><br/>
                            <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                       
                            <p> Elolvastam és elfogadom az<Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                            </div>
                            <div className="mf-home-contact-input-box">
                                  <input type="submit" id="send" className="send-btn" name="send" value="Küldés"/>
                            </div>
                        </form>
                      </div>
                </div>
          </div>
        </div>


      </div>
      {/*Home cont3 section ends*/}
      
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

      </div>     
    </div>
  )
}
