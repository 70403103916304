import React from "react";
import { Link } from "react-router-dom";

import "../../CSS/csoportok.css"

import OnismeretTrening from "../../image/onismereti_trening.png"
import ParkapcsTrening from "../../image/parkapcsolat_trening.png"
import IskolaiTrening from "../../image/iskolai_konfliktuskezeles_trening.png"

export default function Csoportok() {
  return (
    
      <div className="mf-3934"  id="mf-page" data-id="mf-3934">
      {/*trening hero section starts*/}
      <div className="mf-page section section-img-bg-72m13f79">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="hero-title-42m71f95 w-1" data-id="42m71f95">
                                          <h1 className="mf-heading-title">Csoportok & Tréningek</h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>

      {/*trening hero section ends*/}

      {/*trening second section starts*/}

      <div className="mf-page section mf-trening-section">
            <div className="container trening-card">
                  {/*Onismereti trening*/}
                  <div className="trening-card-box">
                        <img src={OnismeretTrening} alt="" className="trening-img"/>
                        
                        <Link to="/OnismeretiTrening" className="trening-box-title">kép - MÁS - kép</Link>
                        
                        <p className="trening-box-description">Önismereti tréning</p>
                        {/*button*/}
                        <div className="trening-btn-box">
                              <Link to="/OnismeretiTrening" className="trening-btn btn" >További információ</Link>
                        </div>

                  </div>

                  {/*Parkapcsolaterosito trening*/}
                  <div className="trening-card-box">
                        <img src={ParkapcsTrening} alt="" className="trening-img"/>
                        
                        <Link to="/ParkapcsolatTrening" className="trening-box-title">MEGFELELŐ?</Link>
                        
                        <p className="trening-box-description">Párkapcsolaterősítő kommunikációs tréning</p>
                        {/*button*/}
                        <div className="trening-btn-box">
                              <Link to="/ParkapcsolatTrening" className="trening-btn btn" >További információ</Link>
                        </div>

                  </div>

                  {/*Iskolai konfliktuskezelesi trening*/}
                  <div className="trening-card-box">
                        <img src={IskolaiTrening} alt="" className="trening-img"/>
                        
                        <Link to="/IskolaiKonfliktus" className="trening-box-title">Békés Megoldás Útján </Link>
                        
                        <p className="trening-box-description">Iskolai konfliktuskezelési tréning</p>
                        {/*button*/}
                        <div className="trening-btn-box">
                              <Link to="/IskolaiKonfliktus" className="trening-btn btn" >További információ</Link>
                        </div>

                  </div>

            </div>
      </div>
      {/*trening second section ends*/}

      {/*Home-contact section starts */}

      <div className="mf-page section mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                        
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>               
                        </div>
                  </div>
            </div>

      </div>

      {/*home-contact section ends*/}

      {/*Back to Top start*/}
            <a href="#" className="back-to-top">
                  <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
            </a>
      {/*Back to Top end*/}

    </div>
  )
}
