import React from "react";
import { Link } from "react-router-dom";

import "../../CSS/csaladi_mediacio.css"

import MediacioFamily from "../../image/mf_csaladi_med.png"

export default function Csaladi_mediacio() {
  return (
    <div className="mf-3578"  id="mf-page" data-id="3578">
      
      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-51m03f64" data-id="51m03f64">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-04m23f95 w-1" data-id="04m23f95">
                                          <h1 className="mf-heading-title">Családi mediáció<span>személyesen és online</span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>
      {/*hero section ends*/}

      {/*family mediation first section starts*/}
      <div className="mf-page section mf-fmediation-section" data-id="">
        <div className="container mf-fmediation-container p-0">
              <div className="mf-fmediation-row">

                    <div className="mf-fmediation-info padd-20">
                          <div className="mf-widget-text-description mf-fmediation-text-07m35f81" data-id="07m35f81">
                                <p className="mf-text-description">
                                      A családok óriási stresszt és fájdalmat élhetnek át konfliktusok és nézeteltérések idején. Egy mediátor csökkentheti ezeket a fájdalmas érzéseket azáltal, hogy biztonságos környezetet teremt a kérdések megvitatásához, és segíthet olyan megoldások megtalálásában, amelyek a legjobb eredményt nyújtják a családi kapcsolatoknak.   
                                </p>
                          </div> 
                          <div className="mf-widget-text-description mf-fmediation-text-07m35f81" data-id="07m35f81">
                                <p className="mf-text-description">
                                      A családi mediáció egy gördülékeny problémamegoldó folyamat, ahol a közvetítő segít a családnak felfedezni a kommunikáció, a konfliktusok és a problémák megoldásának hatékonyabb módjait. A mediáció során minden családtag lehetőséget kap arra, hogy ismertethesse a saját álláspontját. Összességében a családi  mediáció segíti a kapcsolatok helyreállítását és a nézeteltérések megoldását. 
                                </p>
                          </div>  
                    </div>

                    <div className="mf-fmediation-img padd-20">
                          <img src={MediacioFamily} alt=""/>
                    </div>
              </div>
        </div>
      </div>
      {/*Family mediation first section ends*/}

      {/*Family mediation second section starts*/}
      <div className="mf-page section mf-fmediation1-section2" data-id="">
            <div className="container mf-fmediation1-container p-0">
                  <div className="mf-fmediation-text-block">
                        <div className="mf-widget-heading-title mf-fmediation-title-06m35f27 w-1" data-id="06m35f27">
                              <h3 className="mf-heading-title">A családi mediáció hatékony megoldási mód lehet az alábbi konfliktusok esetén:</h3>
                        </div>
                        <div className="mf-fmediation-content-text">
                              <ul className="mf-fmediation-list">
                                    <li className="fmediation-has-before">Szülő - kamasz konfliktus.</li>
                                    <li className="fmediation-has-before">Testvérek közötti konfliktus.</li>
                                    <li className="fmediation-has-before">Családtagok, rokonok közötti konfliktus.</li>   
                              </ul>
                        </div>
                  </div>
            </div>
      </div>
      {/*Family mediation second section ends*/}

      {/*Family mediation 3 section starts*/}
      <div className="mf-page section mf-mediation-info-section" data-id="">
            <div className="container mf-mediation-info-container p-0">
                  <div className="mf-mediation-info-box">
                        <h2 className="mediation-info-title">További információkért kattintson ide:</h2>
                  </div>  
                  <div className="mf-mediation-info-btnBx">
                  <Link to="/Gyik_mediacio"><div className="mf-mediation-info-btn btn" >GYIK - mediáció</div></Link>
                  </div>
            </div>
            
      </div>
      {/*Family mediation 3 section ends*/}

      {/*Mediation-contact section starts */}
      <div className="mf-page section mf-mediation-contact-section-27m10f36" data-id="27m10f36">

            <div className="container mf-mediation-contact-container p-0">
                  <div className="mf-mediation-contact-content">
                        <div className="mf-mediation-info-content">
                              <div className="mf-widget-heading-title mediation-info-title-26e5f30c" data-id="26e5f30c">
                                    <h1 className="mf-heading-title">Készen áll konfliktusai megoldására?</h1>
                              </div>
                              <div className="mf-widget-text-description mediation-info-text-22a7b451" data-id="22a7b451">
                                    <p className="mf-text-description">Tegye meg az első lépést!</p>
                              </div>
                              <div className="mf-widget-text-description mediation-info-text-32m10f63" data-id="32m10f63">
                                    <p className="mf-text-description">Kötelezettség nélkül, 20 perces ingyenes konzultációs lehetőséget biztosítok Önnek, hogy beszéljünk a helyzetéről.</p>
                              </div>
                              <div>
                              <Link to="/Kapcsolat" className="mf-mediation-contact-btn btn">Kérjen időpontot ingyenes konzultációra</Link>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      {/*home-contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
