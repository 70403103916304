import React, { useState }  from "react";
import { Link } from "react-router-dom";

import "../../CSS/gyik_mediation.css"
import "../../CSS/gyik.css"

export default function Gyik_mediacio() {

      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };
      
    return (
      <div className="mf-3654"  id="mf-page" data-id="3654">
           
      {/*hero section starts*/}
      <div className="mf-page section mediation-faq-section-img-bg-3m4f7587" data-id="3m4f7587">

            <div className="container mediation-faq-container p-0">
                  
                  <div className="mf-faq-column-wrap mf-col-100">
                        <div className="mf-faq-widget-container">
                              <div className="mf-widget">
                                    <div className="mf-widget-heading-title mediation-faq-title-65m89f36 w-1" data-id="65m89f36">
                                          <h1 className="mf-heading-title">Gyakran ismételt kérdések<span>Mediáció</span></h1> 
                                    </div>
                              </div>
                        </div>   
                  </div>
            </div>
      </div>
      {/*hero section ends*/}

      {/*faq first section starts*/}
      <div className="mf-page section mediation-faqs-section" data-id="">
            <div className="container mediation-faqs-container p-0">
                  <div className="mediation-faqs-box">
                        <div className="mf-widget-text-description mediation-faqs-text-69b32e91 w-1" data-id="69b32e91">
                              <p className="mf-text-description">Összegyűjtöttem a <b>mediációval</b> kapcsolatos leggyakrabban feltett kérdéseket. 
                                    Ha kérdésére nem kapott választ, kérem <Link to="/Kapcsolat">forduljon hozzám</Link> bizalommal, minden kérdésére szívesen válaszolok.</p>
                        </div>
                  </div>
            </div>
      </div>
      {/*faq first section ends*/}

      {/*FAQ toggle section starts*/}
      <div className="mf-page section mediation-faq-toggle-section-62m5f296" data-id="62m5f296">

            <div className="container mediation-faq-toggle-container p-0">

            <div className="accordion">

                  <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(1)}>
                  Miért a mediáció?
                  </div>
                        {openState[1] && (
                        <div className="content">
                              <p>
                              A mediáció azon az elgondoláson alapul, hogy egy kis támogatással az emberek a saját szükségleteiknek
                              leginkább megfelelő módon meg tudják oldani a konfliktusaikat. A mediátor ezt a támogatást nyújthatja,
                              mégpedig úgy hogy kézben tartja a tárgyalás menetét a lefektetett szabályokkal kezeli az indulatokat és
                              a felek között konstruktív kommunikációt alakít ki.
                              </p>
                        </div>
                        )}
                  </div>

                  <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(2)}>
                        Hogyan működik a mediáció?
                  </div>
                        {openState[2] && (
                        <div className="content">
                              <p><b>Előtte</b></p>
                              <p>Beszélje meg a mediációt, mint konfliktusmegoldási  lehetőséget a másik féllel. A mediáción való részvétel önkéntes! Megállapodás csak akkor fog születni, ha mindkét fél motivált a konfliktus megoldásában, és hajlandó némi időt és erőfeszítést fektetni ennek a megvalósítására.</p>
                              <p>Lépjen kapcsolatba velem telefonon vagy e-mailben, hogy minden érintett féllel ingyenes,  kezdeti konzultációt egyeztethessek. A cél a helyzet megértése és az, hogy mindegyik fél  válaszoljon a kérdésekre, és eldöntse, hogy kívánja-e folytatni ezt a folyamatot. Feltéve, hogy ez így van, minden féllel külön-külön részletesebb premediációs ülést ütemezek, hogy segítem az érintetteket a mediációra való további felkészülésben.</p>
                              <p><b>A mediáció</b></p>
                              <p>Amint ezek a külön ülések megtörténtek, ütemezhető a mediáció megkezdése. A mediáció során minden résztvevőnek biztosítva van a lehetőség arra, hogy elmondja, ő hogyan látja a helyzetet. Az álláspontok ismertetésekor felszínre került  témák fogják a továbbiakban irányítani a megbeszélést. Cél, hogy az egyes témák megtárgyalásakor minden érintett megossza a véleményét  és meghallgassa a másik fél elképzeléseit és aggályait. Attól függően, hogy hogyan haladnak a tárgyalások a mediáció zárulhat  <b>megállapodással/részmegállapodással.</b> Előfordul azonban, hogy az ülés végére, még maradnak megoldatlan témák és a felek tovább akarják folytatni a beszélgetést, ilyenkor második vagy harmadik ülésre is sor kerülhet.</p>
                              <p>A cél mindig az, hogy <b>minden érintett fél</b> számára a legjobb megegyezés szülessen. A megállapodás jogilag nem kötelező érvényű, de jogi megállapodás vagy bírósági végzés alapjaként használható fel.</p>
                              <p><b>Utána</b></p>
                              <p>Amennyiben igény merül fel, van utánkövetésre lehetőség. Célja, hogy megválaszolásra kerüljenek az esetlegesen felmerülő kérdések, és kiderüljön hogyan működik a megállapodás.</p>
                        </div>
                        )}
                  </div>

                  <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(3)}>Mit csinál a mediátor?</div>
                        {openState[3] && (
                        <div className="content">
                                    <p>A mediátor feladata a mediáción résztvevő felek közötti kommunikáció irányítása, és a beszélgetés a megfelelő irányba tartása.</p>
                                    <p>A mediátor nem foglal állást és nem dönt a felek helyett. A közvetítő nem jár el bíróként, ügyvédként, tanácsadóként, egyik fél számára sem ad jogi tanácsot. A mediátor  nem tud garantálni semmilyen eredményt. A mediátor a kommunikációt segíti a felek között, a felek erőfeszítése és elköteleződése vezethet csak sikeres eredményhez.</p>
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(4)}>Mi a garancia a sikerre?</div>
                        {openState[4] && (
                        <div className="content">
                              <p>Csak Ön tudja garantálni ennek a folyamatnak a sikerét.</p>
                              <p>A mediátor nem garantál, és nem is tud garantálni semmilyen eredményt.</p>
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[5] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(5)}>Tud segíteni a mediátor a döntésben?</div>
                        {openState[5] && (
                        <div className="content">
                              <p>Nem. A mediátor nem olyan, mint a bíró, nincs döntési jogköre, és mediációt sem erre a célra tervezték.</p>
                              <p>A cél, hogy a résztvevők betekintést nyerjenek egymás perspektívájába, hogy utána olyan döntést hozzanak, amely mind a kettőjüknek jobban elfogadható és megfelel a jövőben.</p>
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[6] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(6)}>Elhozhatom az ügyvédem?</div>
                        {openState[6] && (
                  <div className="content">
                              <p>A mediáción való részvételhez nincs szükség ügyvédre. Az eljárás nem része a hagyományos bírósági, peres eljárásoknak. Mindazonáltal, ha bármelyik fél úgy dönt, hogy ügyvédet kér, a méltányosság érdekében általában mindkét félnek jogi képviselettel kell akkor megjelennie. Azonban a jogi képviselők semmiképpen sem helyettesíthetik az érdekelt feleket.</p>
                              
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[7] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(7)}>Miért előnyösebb a mediáció, mint a bírósági eljárás?</div>
                        {openState[7] && (
                  <div className="content">
                              <p>A pereskedés gyakoribb és ismertebb lehetőség, mint a mediáció, amikor az emberek között viták vannak. Azonban mediáció  bármely bírósági eljárással párhuzamosan folytatható; ezek nem egymást kizáró lehetőségek.</p>
                              <p>A mediáció néhány előnye a peres eljárással szemben:</p>
                              <ul className="accordion-list">
                                    <li className="accordion-has-before">A mediátor segíti a felek közötti kommunikációt azzal a céllal, hogy képessé tegye őket arra, hogy együtt dolgozzanak egy kölcsönösen elfogadható megoldás létrehozásán. A peres eljárások során a feleket általában elriasztják minden közvetlen kommunikációtól, nehogy akaratlanul is gyengítsék pozíciójukat.</li>
                                    <li className="accordion-has-before">A mediáció bizalmas, a folyamat során elhangzott információkat és a megszületett megállapodást is minden fél bizalmasan kezeli. A bírósági eljárások szükségszerűen nyilvánosak.</li>
                                    <li className="accordion-has-before">A mediáció általában sokkal rövidebb időn belül megvalósítható és zárható le, és lényegesen alacsonyabb költséggel jár, mint a peres eljárás.</li>
                                    <li className="accordion-has-before">A konfliktusban részt vevő feleknek nincs szükségük ügyvédekre a közvetítés során, de szükség esetén bármikor jogi tanácsot kérhetnek.</li>  
                              </ul>
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[8] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(8)}>A másik fél kérte a mediációt. Hogyan lehetek biztos abban, hogy az eljárás tisztességes lesz és nem lesz elfogult a mediátor velem szemben?</div>
                        {openState[8] && (
                  <div className="content">
                              <p>A mediátor pártatlan személy, aki egyik fél érdekét sem képviseli az ülés során.</p>
                              <p><b>Miben nyilvánul meg a pártatlanság:</b></p>
                              <ul className="accordion-list">
                                    <li className="accordion-has-before">Minden résztvevővel egyenlő időt töltök a közvetítés előkészítése során, és a mediáció alatt kiegyensúlyozzam a beszédidőt a felek között. Egész idő alatt azon dolgozom, hogy mindkét fél a mediációs teret biztonságosnak, barátságosnak és nyitottnak érezze, azért hogy hatékony párbeszédet folytathassanak.</li>
                                    <li className="accordion-has-before">Nem fejezzek ki preferenciákat, nem foglalok állást és nem értékelem a résztvevők által tett javaslatokat vagy a megállapodást.</li>
                                    <li className="accordion-has-before">Mindkét fél köteles a díjból egyenlő részt fizetni.</li>     
                              </ul>
                              
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[9] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(9)}>Lehet online a mediáció?</div>
                        {openState[9] && (
                  <div className="content">
                              <p>Igen: az első  mediáció előtti megbeszélés és maga a mediáció is megtartható Zoom, Google Meet vagy hasonló platformon keresztül. Kérem, vegye figyelembe, hogy ez további adatvédelmi és titoktartási kockázatokat jelent, amelyeket megbeszélhetünk, ha ezt a lehetőséget választja.</p>
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[10] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(10)}>Mit kell tennem, hogy maximalizáljam a siker lehetőségét?</div>
                        {openState[10] && (
                  <div className="content">
                              <p>A nyitott elme, valamint az új ötletek meghallgatására és mérlegelésére való hajlandóság elengedhetetlen a sikeres mediációhoz. Ez nem azt jelenti, hogy a másik fél nézőpontját sajátjaként kell hogy elfogadja, hanem inkább azt, hogy Ön egyértelműen megértse a másik fél elképzeléseit, aggályait és érdekeit. Hasonlóképpen, a saját érdekek, értékek és szükségletek világos elképzelése elengedhetetlen a hatékony kommunikációhoz. A közvetítői ülésre készülve gondolja át, mik lehetnek ezek az érdekek, értékek és igények, és hogyan kommunikálhatja ezeket úgy, hogy ne legyen vádaskodó. Például: „Érezni akarom, hogy a hozzájárulásaimat megbecsülik”, szemben azzal, hogy „Mindig lerombolod az ötleteimet”.</p>
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[11] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(11)}>Alá kell-e írni valamilyen dokumentumot?</div>
                        {openState[11] && (
                  <div className="content">
                              <p>Az egyetlen dokumentum, amit a közvetítés elején alá kell írni az a mediációs nyilatkozat. Ennek a megállapodásnak az a célja, hogy a résztvevő felek aláírásukkal igazolják, hogy megismerték a folyamat alapszabályait és elfogadták azokat, illetve megerősítik a mediáció folytatása iránti elkötelezettségüket. </p>
                              
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[12] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(12)}>Mi a mediáció hátránya?</div>
                        {openState[12] && (
                  <div className="content">
                              <p>A mediáció hátránya, hogy minden vitában álló félnek bele kell egyezni a mediációba, meg kell jelennie, azaz önkéntes személyes részvétel szükséges hozzá, senkit sem lehet rákényszeríteni a részvételre.</p>
                        </div>
                         )}
                  </div>

                  <div className={`contentBx ${openState[13] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(13)}>Még több kérdésem lenne!</div>
                              {openState[13] && (
                  <div className="content">
                                    <p>Kérem hívjon fel a +36 70 418 1114-es telefonszámon vagy írjon nekem az info@gemeterzsuzsa.hu címre, és mindent megteszek, hogy segíthessek.</p>
                              </div>
                               )}
                        </div>

                        
                  </div>

            </div>
      </div>
      {/*FAQ toggle section ends*/}

      {/*Home-contact section starts */}
      <div className="mf-page section faq-contact-section" data-id="3872">

            <div className="mf-container mf-faq-contact-container">
                  <div className="mf-faq-contact-content-container center">
                        <div className="mf-faq-contact-content">
                              <div className="mf-widget-heading-title faq-contact-title-26e5f30c" data-id="26e5f30c">
                                    <h1 className="mf-heading-title">Kérdése van?</h1>
                              </div>
                              <div className="mf-widget-text-description faq-contact-text-22a7b451" data-id="22a7b451">
                                    <p className="mf-text-description">Küldjön üzenetet nekem!</p>
                              </div>
                              <div>
                              <Link to="/Kapcsolat" className="mf-faq-contact-btn btn">Lépjen kapcsolatba velem</Link>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      {/*home-contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
