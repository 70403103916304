import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog20Hero from "../../../image/blog_20_hero_valas__hogyan_lehet.png"

export default function Blog_20_hogyan_lehet() {
  return (
    <div className="mf-4308" id="mf-page" data-id="mf-4308">
      <div className="mf-page section section-blog-globale">
        <div className="container mf-blog-globale-container p-0">
          <div className="mf-blog-globale-hero">
            <img src={Blog20Hero} alt="Valas_Hogyan_lehet_felepulni_a_valasbol"/>
          </div>
    

          <div className="mf-blog-globale-face">
            <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
              <h2 className="mf-blog-globale-heading-title">Válás II. rész- Hogyan lehet felépülni a válásból</h2>
            </div>

            <div className="mf-blog-globale-profile-container">
              <div className="mf-blog-globale-profile">
                <div className="mf-blog-globale-img-container"></div>   
        
                <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                  <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                  <p className="mf-blog-globale-text-description">2024. május 09.</p>
                </div>
              </div>
        
              <div>
              <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
              </div>
            </div>
          </div>

          <div className="mf-blog-globale-column">

                <div className="mf-blog-globale-text">
                      <p>
                            Amikor valaki szerelemes megnyílik egy másik személy – vágyai, szükségletei, céljai és álmai – felé. Közel érzi magát a másikhoz, együtt haladnak ugyanazon az úton, ugyanabba az irányba. 
                      </p>

                      <p>
                            A válás azonban azt jelenti, hogy nincs tovább közös út, a kapcsolat megszűnik és véget ér valami, ami addig jó és ismerős volt. A jövővel kapcsolatos tervek megváltoznak és maga a jövő is homályosnak, kiszámíthatatlannak tűnik.
                      </p>

                      <p>
                            Míg a válás nehéz és fájdalmas, addig vannak egészséges módszerek a felépülésre, a megbirkózásra és a továbblépésre. Bár mindenki másképp gyászol és kezeli a szakítás okozta fájdalmat, a következő öt lépés hasznos lehet az egészséges felépüléshez:
                      </p>

                      <p>
                            <b>Adj időt magadnak:</b> A válás általában érzelmek özönét szabadítja fel, beleértve a haragot, a szorongást és a félelmet. Néha ezek az érzések akkor támadnak fel, amikor legkevésbé számítasz rájuk. Az ilyen reakció normális, ne érezd úgy, hogy vissza kell tartanod az érzelmeid, különösen közvetlenül a válás után, nyugodtan engedd előtörni azokat. Jó tudni azonban, hogy  idővel ezeknek az érzéseknek az intenzitása alábbhagy.  
                      </p>

                      <p>
                            <b>Beszélj a fájdalmadról:</b> A válásról való beszélgetés megkönnyítheti a gyógyulást, mivel segít perspektívát nyerni, és tisztán látni a történteket.
                      </p>

                      <p>
                            <b>Találj ismét önmagadra:</b> Szánj időt arra, hogy emlékez, ki is vagy a kapcsolaton kívül. Amikor új fejezetet kezdesz, próbálj meg olyan tevékenységekkel foglalkozni, amelyeket korábban szeretettél, de egy ideje nem csináltál. Vagy próbálj ki új hobbikat és tevékenységeket. 
                      </p>

                      <p>
                            <b>Légy türelmes saját magaddal:</b> Kényeztesd magad! Enged meg magadnak, hogy részt vegyél olyan öngondoskodási rituálékban amelyek jólesnek, mint pl. vásárlás, új frizura vagy édességek rágcsálása.
                      </p>

                      <p>
                            <b>Ápold kapcsolataidat:</b> Fordulj a családodhoz, barátaidhoz segítségért. Találkozz velük, legyen szó akár egy közös ebédről, kirándulásról, vagy egyszerűen csak egy közös csevegésről. Ezek a kapcsolatok feltétel nélküliek és kölcsönösek, segíthetnek megbirkózni a válással járó sok érzelemmel. 
                      </p>

                      <h4>
                            A szakításból való gyógyulás jelei
                      </h4>

                      <p>
                            Minden embernél egyedi az, hogy mennyi időbe telik, amíg túljut a válás okozta fájdalmon, de az következő jelek azt mutatják, hogy egészségesen gyógyulsz a válásból:
                      </p>

                      <ul className="blog-globale-list">
                            <li className="blog-globale-has-before">Az érzelmi hullámvölgyek kevésbé intenzívek</li>
                            <li className="blog-globale-has-before">Belátásod van arra, hogy miért történt a válás.</li>
                            <li className="blog-globale-has-before">Azon kapod magad, hogy a múlt helyett a jövőbe nézel.</li>
                            <li className="blog-globale-has-before">Felismered a jót is a rossz mellett a régi kapcsolatodban.</li>
                            <li className="blog-globale-has-before">Jobban megérted a saját viselkedésedet.</li>
                      </ul>
                      <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>



                </div>
          </div>
          <div className="mf-blog-global-tags">
            <p>Címkék:</p>
            <a href="#">válás</a>
                
          </div>
        </div>
      </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
