import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../CSS/onismeret_trening.css"

import OnismeretTrain01 from "../../image/mf_self_trening_1.png"
import OnismeretTrain02 from "../../image/mf_self_trening_3.png"

export default function Onismereti_trening() {
  
      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };
  
  return (
    <div className="mf-3935"  id="mf-page" data-id="mf-3935">
      {/*trening hero section starts*/}
      <div className="mf-page section section-img-bg-73m11f89">

<div className="container mf-hero-container p-0">
      
      <div className="mf-column-wrap mf-col-100">
            <div className="mf-widget-container">
                  <div className="mf-hero-widget">
                        <div className="hero-title-69m71f93 w-1" data-id="69m71f93">
                              <h1 className="mf-heading-title">Önismereti tréning</h1> 
                        </div>
                  </div>
            </div>
      </div>
</div> 
      </div>
      
      {/*self trening first section starts*/}
      
      <div className="mf-page section mf-self-section">
            <div className="container mf-self-container p-0">
                  <div className="mf-self-title-38m42f56 w-1">
                        <h1 className="mf-heading-title">kép - MÁS - kép</h1>
                  </div>
                  <div className="mf-self-text-20m53f48">
                        <h2 className="mf-text-description">Lépj egy lépéssel közelebb önmagad teljesebb megértéséhez!</h2>
                  </div>
            </div>
      </div>

      {/*self trening first section ends*/}

      {/*self trening 2 section starts*/}
      <div className="mf-page section mf-self-section-1">
            <div className="container mf-self-container p-0">
                  <div className="mf-self-row">
                        <div className="self-img-box padd-15">
                              <img src={OnismeretTrain01} alt=""/> 
                        </div>
                        <div className="self-box-1 padd-15">
                              <div className="mf-self-text-21m01f97">
                                    <h2 className="mf-text-description">Önmagunk felfedezése egy egész életen át tartó utazás belső énünk feltárásán kersztül, 
                                          amely  során megpróbáljuk felfedezni, kik vagyunk, mik az erősségeink, mi a célunk az életben és mely alapelvek vezetnek bennünket.
                                           Mivel mindannyian különböző módon gondolkodunk, tanulunk, cselekszünk, érzünk és érzékeljük a körülöttünk lévő világot, hasznos időt szánni arra, hogy jobban megismerjük és megértsük önmagunkat. 
                                           Önmagunk megismerése pedig segít abban, hogy reális énképet tudjunk kialakítani magunkról.  
                                    </h2>
                              </div>
                              <div className="mf-self-text-21m01f97">
                                    <h2 className="mf-text-description"> A tréning során használt technikák és stratégiák segítenek abban,
                                           hogy jobban megismerd önmagad, felfedezd személyiségedet és támogatnak abban, hogy elérd a céljaidat.</h2>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      {/*Self trening 2 section ends*/}

      {/*Self trening 3 section starts*/}
      <div className="mf-page section mf-self-section2">
            <div className="container mf-self-container1 p-0">
                  <div className="mf-self-text-box">
                        <div className="mf-self-title-40m42f50 w-1">
                              <h3 className="mf-heading-title">Mit nyújt a tréning?</h3>
                        </div>
                        <div className="mf-self-content-text">
                              <ul className="mf-self-list">
                                    <li className="self-has-before"><b>Lehetőséget a személyes fejlődésre:</b> megtudhatod, hogy milyen szinten van most a magadba vetett hited, ami fontos kiindulópont a személyes fejlődésed útján. Megtanulhatod magadat - mintegy "kilépve önmagadból" - kívülről mások szemével látni. </li>
                                    <li className="self-has-before"><b>Saját erősségek, értékek felfedezését:</b> a tréning segít abban, hogy felszínre kerüljenek viselkedésed eddig rejtve maradt mozgatórugói.</li>
                                    
                                    <li className="self-has-before"><b>Egészséges határok kifejezését és felállítását:</b> egyszerű és hatékony módszerek alkalmazásán keresztül elsajátíthatod  hogyan fejezd ki sikeresen szükségleteidet és hogyan mondjál nemet.</li>
                                    <li className="self-has-before"><b>A konfliktusok jobb megértését és kezelését:</b> megismerheted a konfliktusok mögött meghúzódó szükségleteket, értékrendbeli különbségeket és hogyan lehet nyertes-nyertes helyzetet teremteni.</li>
                                    <li className="self-has-before"><b>A kommunikációs készség fejlesztését:</b> megtanulhatod kifejezni magadat úgy, hogy amit mondasz az ne tűnjön  bírálatnak vagy minősítésnek. Nyitottabbá válhatsz önmagad ás mások érzéseinek, szükségleteinek a felismerésére és támogatására.</li>
                              </ul>
                        </div>
                  </div>


            </div>
      </div>
      {/*Self trening 3 section ends*/}

      {/*Self trening 4 section starts*/}
      <div className="mf-page section mf-self-info-section" data-id="">
            <div className="container mf-self-info-container p-0">
                   
                  <div className="mf-self-info-btnBx">
                        <Link to="/OnismeretForm"><div className="mf-self-btn btn">érdekel a tréning</div></Link>
                  </div>
            </div>  
      </div>
      {/*Self trening 4 section ends*/}

      {/*Self trening 5 section starts*/}

      <div className="mf-page section mf-self-section-5">
            <div className="container mf-self-container5 p-0">
                  <div className="mf-self-row">
                        <div className="self-img-box-2 padd-15">
                              <img src={OnismeretTrain02} alt=""/> 
                        </div>
                        <div className="self-box-3 padd-15">
                              <div className="mf-self-title-48m21f39">
                                    <h2 className="mf-heading-title">Az önismereti tréning tematikája</h2>
                              </div>
                              <div className="mf-self-content-text">
                                    <ul className="mf-self-list">
                                          <li className="self-has-before">Egymásra hangolva</li>
                                          <li className="self-has-before">Ki vagyok én?</li>
                                          <li className="self-has-before">Időutazás a múltba</li>
                                          <li className="self-has-before">Határokon innen és túl</li>
                                          <li className="self-has-before">Érzelmeink hullámain </li>
                                          <li className="self-has-before">Konfliktusok avagy "Ki nyer a végén?"</li>
                                          <li className="self-has-before">Zsiráfnyelven beszélni - az Együttérző Kommunikáció használata</li>
                                          <li className="self-has-before">Jövő útjai</li>
                                    </ul>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      {/*Self trening 5 section ends*/}

      {/*Self trening 6 section starts*/}
      <div className="mf-page section mf-self-section-6">
            <div className="container mf-self-container-6 p-0">
                  <div className="self-card">
                        <h2 className="self-card-title">RÉSZLETEK </h2>
                        
                        <h3 className="self-card-title-1">Kezdés: 2024. augusztus 07.</h3>
                        <h3 className="self-card-title-2">További időpontok:</h3>
                        <div className="mf-self-content-text">
                              <ul className="mf-self-list">
                                    <li className="self-has-before">2024. augusztus 15., csütörtök (17:00-20:00)</li>
                                    <li className="self-has-before">2024. augusztus 21., szerda (17:00-20:00)</li>
                                    <li className="self-has-before">2024. augusztus 28., szerda (17:00-20:00)</li>
                                    <li className="self-has-before">2024. szeptember 4., szerda (17:00-20:00)</li>
                                    <li className="self-has-before">2024. szeptember 11., szerda (17:00-20:00)</li>
                                    <li className="self-has-before">2024. szeptember 18., szerda (17:00-20:00)</li>
                                    <li className="self-has-before">2024. szeptember 25., szerda (17:00-20:00)</li>
                                    
                              </ul>
                        </div>
                        <h3 className="self-card-title-3">Helyszín: <span>2040 Budaörs, Napsugár sétány 4.</span></h3>   
                  </div>
            </div>
      </div>
      {/*Self trening 6 section ends*/}

      {/*Self trening 7 section starts*/}
      <div className="mf-page section mf-self-section-7">
            <div className="container mf-self-container-7 p-0">
                  <div className="self-card1">
                        <h2 className="self-card1-title-1">AZ ÖNISMERETI TRÉNING RÉSZVÉTELI DÍJA:<span>56.000 Ft</span></h2>

                        <h3 className="self-card1-title-2">A 2024. augusztusában induló tréningnél</h3>
                        <ul className="mf-self-list">
                              <li className="self-has-before"><b>30.000 Ft: 2024. július 22-ig</b> (ez az első részlet)</li>
                              <li className="self-has-before"><b>26.000 Ft: 2024. augusztus 1-ig</b> (ez a második részlet)</li>
                        </ul>
                        <h3 className="self-card1-title-3">A tréning minimum 6-8 fő jelentkezése esetén indul!</h3>
                  </div>
            </div>
      </div>
      {/*Self trening 7 section ends*/}

      {/*Self trening 8 section starts*/}
      <div className="mf-page section mf-self-info-section" data-id="">
            <div className="container mf-self-info-container p-0">
                   
                  <div className="mf-self-info-btnBx">
                  <Link to="/Kapcsolat"><div className="mf-self-btn btn">érdekel a tréning</div></Link>
                  </div>
            </div>  
      </div>
      {/*Self trening 8 section ends*/}

      <div className="mf-page section mf-self-section-8" data-id="">
            <div className="container mf-self-container-8 p-0">

                  <div className="mf-self1-row">

                        <div className="mf-self-info2 padd-15">

                              <div className="mf-self-title-24m32f10 w-1" data-id="86m23f65">
                                    <h3 className="mf-heading-title">GYIK az önismereti tréningről</h3>
                              </div>

                              <div className="accordion">

                                    <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                                    <div className="label8" onClick={() => toggleAccordion(1)}>Kinek ajánlott az önismereti tréning?</div>
                                    {openState[1] && (
                                          <div className="content8">
                                                <p>A tréning mindenki számára hasznos, aki szeretné jobban megismerni önmagát, fejleszteni készségeit és elérni céljait.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                                    <div className="label8" onClick={() => toggleAccordion(2)}>Milyen előképzettség szükséges?</div>
                                    {openState[2] && (
                                          <div className="content8">
                                                <p>Nincs szükség előképzettségre, a tréning minden érdeklődő számára nyitott.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                                    <div className="label8" onClick={() => toggleAccordion(3)}>Hogyan történik a jelentkezés?</div>
                                    {openState[3] && (
                                          <div className="content8">
                                                <p>Jelentkezés:</p>
                                                <ul className="accordion-list">
                                                      <li className="accordion-has-before">E-mailben lehet jelentkezni.<Link to="/Kapcsolat">(info@gemeterzsuzsa.hu) </Link></li>
                                                      <li className="accordion-has-before">Válaszolok és megírom a következő lépéseket. </li>
                                                      <li className="accordion-has-before">A jelentkezéseket a befizetések sorrendjében fogadom.</li>
                                                      <li className="accordion-has-before">Az első részlet, 30.000 forint befizetése (átutalással) a megadott határidőn belül.</li>
                                                      <li className="accordion-has-before">Visszaigazolom, hogy megérkezett az első részlet és így biztosítva van a helyed a tréningen.</li>  
                                                </ul>
                                          </div>
                                    )}
                                    </div>


                                    <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                                    <div className="label8" onClick={() => toggleAccordion(4)}>Hogyan fizethetek?</div>
                                    {openState[4] && (
                                          <div className="content8">
                                                <p>Banki átutalással, a jelentkezést követően a visszaigazoló e-mailben is elküldöm a számlaszámot.</p>
                                                <p>Bankszámlaszám: HU03 1040 1938 8676 7677 7565 1006</p>
                                                <p>FONTOS! A közleménybe a NÉV+ÖNISMERETI TRÉNING szöveget kell beírni!</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[5] ? 'active' : ''}`}>
                                    <div className="label8" onClick={() => toggleAccordion(5)}>Hogyan mondhatom le?</div>
                                    {openState[5] && (
                                          <div className="content8">
                                                <p>Ha valamilyen okból le kell mondanod a tréningen való részvételt, 
                                                akkor a tréning hetében történő lemondás esetén már csak a részvételi díj 50%-át tudom visszafizetni, 48 órán belüli lemondás esetén azonban már ezt sem.</p>
                                          </div>
                                    )}
                                    </div>
                                    
                              </div>
                        </div>

                        <div className="mf-self-info3 padd-15">

                        </div>



                  </div>
            </div>

      </div>

      <div className="mf-page section mf-self-info-section" data-id="">
            <div className="container mf-self-info-container p-0">
                   
                  <div className="info-text-box">

                        <div className="self-info-title-29m50f30">
                              <h1 className="mf-heading-title">Ha bármilyen kérdésed van, ne habozz kapcsolatba lépni velem!</h1>
                        </div>
                        <div className="self-info-text-27m77f98" >
                              <p className="mf-text-description"><Link to="/Kapcsolat">info@gemeterzsuzsa.hu</Link></p>
                        </div>
                        <div className="self-info-text-38m10f63">
                              <p className="mf-text-description">Telefon: +36 70 418 1114</p>
                        </div>
                        

                  </div>
            </div>  
      </div>

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

</div>
      

   
  )
}
