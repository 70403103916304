import Footer from "./components/Footer";
import Header from "./components/Header";
import LandingPage from "./components/LandingPage";
import ScrollToTop from "./components/ScrollToTop";

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom"

import Rolam from "./components/pages/Rolam"
import Szolgaltatasok from "./components/pages/Szolgaltatasok"
import Egyeni_konzultacio from "./components/pages/Egyeni_konzultacio"
import Parkapcsolat_tanacs from "./components/pages/Parkapcsolat_tanacs"
import Csaladkonzultacio from "./components/pages/Csaladkonzultacio"
import Mozaikcsalad_konz from "./components/pages/Mozaikcsalad_konz"
import Hazassag_elott from "./components/pages/Hazassag_elott"
import Szulo_coaching from "./components/pages/Szulo_coaching"
import Valasi_tanacsadas from "./components/pages/Valasi_tanacsadas"
import Csoportok from "./components/pages/Csoportok"
import OnismeretiTrening from "./components/pages/Onismereti_trening"
import OnismeretiForm from "./components/pages/Onismeret_form"
import ParkapcsolatTrening from "./components/pages/Parkapcsolaterosito"
import IskolaiKonfliktus from "./components/pages/Iskolai_konfliktuskezeles"
import Mediacio from "./components/pages/Mediacio"
import Valasi_Mediacio from "./components/pages/Valasi_mediacio"
import Csaladi_Mediacio from "./components/pages/Csaladi_mediacio"
import Iskolai_mediacio from "./components/pages/Iskolai_mediacio"
import Online_konz from "./components/pages/Online_konz"
import Blog from "./components/pages/Blog"
import Arak from "./components/pages/Arak"
import Gyik_tanacsadas from "./components/pages/Gyik_tanacsadas"
import Gyik_mediacio from "./components/pages/Gyik_mediacio"
import Kapcsolat from "./components/pages/Kapcsolat"
import Blog01 from "./components/pages/blog/Blog_01_het_plusz_egy"
import Blog02 from "./components/pages/blog/Blog_02_hohyan_szabjunk"
import Blog03 from "./components/pages/blog/Blog_03_uj_ev"
import Blog04 from "./components/pages/blog/Blog_04_tippek"
import Blog05 from "./components/pages/blog/Blog_05_mire_szamithat"
import Blog06 from "./components/pages/blog/Blog_06_stresszkezeles"
import Blog07 from "./components/pages/blog/Blog_07_hazassagi_tanacsadas"
import Blog08 from "./components/pages/blog/Blog_08_egyeni_konzultacio"
import Blog09 from "./components/pages/blog/Blog_09_mi_kell_a_hazassaghoz"
import Blog10 from "./components/pages/blog/Blog_10_a_jo_kommunikacio"
import Blog11 from "./components/pages/blog/Blog_11_parod_szeretetnyelve"
import Blog12 from "./components/pages/blog/Blog_12_elismerni_az_erzeseket"
import Blog13 from "./components/pages/blog/Blog_13_onbizalom"
import Blog14 from "./components/pages/blog/Blog_14_a_valasi_mediacio_folyamata"
import Blog15 from "./components/pages/blog/Blog_15_ot_lepes_a_hekony_parkapcsolati_kommunikaciohoz"
import Blog16 from "./components/pages/blog/Blog_16_a_szuloi_gondoskodas_jelentosege"
import Blog17 from "./components/pages/blog/Blog_17_el_akarok_valni"
import Blog18 from "./components/pages/blog/Blog_18_hogyan_tanuld_meg_szeretni_onmagad"
import Blog19 from "./components/pages/blog/Blog_19_gyakori_reakciok"
import Blog20 from "./components/pages/blog/Blog_20_hogyan_lehet"
import Blog21 from "./components/pages/blog/Blog_21_hogyan_mukodik_a_valasi_mediacio"
import Blog22 from "./components/pages/blog/Blog_22_a_parkapcsolat_epitokovei"
import Blog23 from "./components/pages/blog/Blog_23_miert_fontos_az_esgeszseges_onbecsules"
import Blog24 from "./components/pages/blog/Blog_24_hogyan_hat_a_szulove_valas"
import Blog25 from "./components/pages/blog/Blog_25_ot_tipp_a_boldogabb_erosebb"
;



function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <div>
        <Header/>
        <ScrollToTop>
        <Routes>
          <Route path="/Rolam" element={<Rolam/>}/>
          <Route path="/Szolgaltatasok" element={<Szolgaltatasok/>}/>
            <Route path="/Egyeni_konzultacio" element={<Egyeni_konzultacio/>}/>
            <Route path="/Parkapcsolat_tanacs" element={<Parkapcsolat_tanacs/>}/>
            <Route path="/Csaladkonzultacio" element={<Csaladkonzultacio/>}/>
            <Route path="/Mozaikcsalad_konz" element={<Mozaikcsalad_konz/>}/>
            <Route path="/Hazassag_elott" element={<Hazassag_elott/>}/>
            <Route path="/Szulo_coaching" element={<Szulo_coaching/>}/>
            <Route path="/Valasi_tanacsadas" element={<Valasi_tanacsadas/>}/>
            <Route path="/Csoportok" element={<Csoportok/>}/>
            <Route path="/OnismeretiTrening" element={<OnismeretiTrening/>}/>
            <Route path="/OnismeretForm" element={<OnismeretiForm/>}/>
            <Route path="/ParkapcsolatTrening" element={<ParkapcsolatTrening/>}/>
            <Route path="/IskolaiKonfliktus" element={<IskolaiKonfliktus/>}/>
          <Route path="/Mediacio" element={<Mediacio/>}/>
            <Route path="/Valasi_Mediacio" element={<Valasi_Mediacio/>}/>
            <Route path="/Csaladi_Mediacio" element={<Csaladi_Mediacio/>}/>
            <Route path="/Iskolai_Mediacio" element={<Iskolai_mediacio/>}/>
          <Route path="/Online_konz" element={<Online_konz/>}/>
          <Route path="/Blog" element={<Blog/>}/>
            <Route path="/Blog01" element={<Blog01/>}/>
            <Route path="/Blog02" element={<Blog02/>}/>
            <Route path="/Blog03" element={<Blog03/>}/>
            <Route path="/Blog04" element={<Blog04/>}/>
            <Route path="/Blog05" element={<Blog05/>}/>
            <Route path="/Blog06" element={<Blog06/>}/>
            <Route path="/Blog07" element={<Blog07/>}/>
            <Route path="/Blog08" element={<Blog08/>}/>
            <Route path="/Blog09" element={<Blog09/>}/>
            <Route path="/Blog10" element={<Blog10/>}/>
            <Route path="/Blog11" element={<Blog11/>}/>
            <Route path="/Blog12" element={<Blog12/>}/>
            <Route path="/Blog13" element={<Blog13/>}/>
            <Route path="/Blog14" element={<Blog14/>}/>
            <Route path="/Blog15" element={<Blog15/>}/>
            <Route path="/Blog16" element={<Blog16/>}/>
            <Route path="/Blog17" element={<Blog17/>}/>
            <Route path="/Blog18" element={<Blog18/>}/>
            <Route path="/Blog19" element={<Blog19/>}/>
            <Route path="/Blog20" element={<Blog20/>}/>
            <Route path="/Blog21" element={<Blog21/>}/>
            <Route path="/Blog22" element={<Blog22/>}/>
            <Route path="/Blog23" element={<Blog23/>}/>
            <Route path="/Blog24" element={<Blog24/>}/>
            <Route path="/Blog25" element={<Blog25/>}/>
          <Route path="/Arak" element={<Arak/>}/>
            <Route path="/Gyik_tanacsadas" element={<Gyik_tanacsadas/>}/>
            <Route path="/Gyik_mediacio" element={<Gyik_mediacio/>}/>
          <Route path="/Kapcsolat" element={<Kapcsolat/>}/>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        </ScrollToTop>  
      </div>
        <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;
