import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog14Hero from "../../../image/blog_14_hero_mediacio.png"

export default function Blog_14_a_valasi_mediacio_folyamata() {
  return (
    <div className="mf-4301" id="mf-page" data-id="mf-4301">
      <div className="mf-page section section-blog-globale">
        <div className="container mf-blog-globale-container p-0">
            <div className="mf-blog-globale-hero">
                  <img src={Blog14Hero} alt="A_valasi_mediacio_folyamata"/>
            </div>
      
            <div className="mf-blog-globale-face">
                  <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                        <h2 className="mf-blog-globale-heading-title">A mediáció folyamata</h2>
                  </div>

                  <div className="mf-blog-globale-profile-container">
                        <div className="mf-blog-globale-profile">
                              <div className="mf-blog-globale-img-container"></div>   
                  

                              <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                    <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                    <p className="mf-blog-globale-text-description">2024. február 29.</p>
                              </div>
                        </div>
                  
                        <div>
                        <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                        </div>
                  </div>
            </div>

            <div className="mf-blog-globale-column">

                  <div className="mf-blog-globale-text">
                        <p>
                              A mediáció egy önkéntes és bizalmas folyamat, amely lehetővé teszi azt, a felek egy semleges harmadik fél segítségével pres eljárás nélkül megállapodásra jussanak vitás kérdésekben. Ha mediációt fontolgat, elengedhetetlen, hogy tisztán megértse a folyamat működését. Ebben a bejegyzésben megismerkedhet a mediáció lépéseivel, így betekintést kaphat arra vonatkozóan, hogy mire számíthat.
                        </p>

                        <h4>
                              Információgyűjtés - kapcsolatfelvétel
                        </h4>

                        <p>
                              A mediáció általában egy kezdeti 20 prces ingyenes telefonos vagy online konzultációval kezdődik. A beszélgetés célja egyrészt az, hogy Ön megismerje a mediáció folyamatát és válaszokat kapjon  a  kérdéseire, másrészt a kapott információkból egyértelműen kiderüljön, hogy a mediáció a megfelelő megoldás, az Ön/Önök helyzetét figyelembe véve. Ez megtehető együtt vagy a másik féltől függetlenül.
                        </p>

                        <h4>
                              Előkészítés
                        </h4>

                        <p>
                              Miután mindkét fél beleegyezik a mediációba, a következő lépés az összes szükséges információ összegyűjtése.
                        </p>

                        <h4>
                              Közös mediációs ülés(ek): A főbb témák és prioritások meghatározása
                        </h4>

                        <p>
                              A mediációs ülés(ek) segítenek azonosítani és rangsorolni a megoldandó problémákat. Ez például válási mediáció esetén magában foglalhatja az ideiglenes pénzügyi intézkedéseket, a lakáshasználatot, a szülői együttműködésre vonatkozó szabályokat, a szülői felügyeleti jogot,  a kapcsolattartást és a tartásdíjat, illetve még egyéb releváns dolgokat is.
                        </p>

                        <h4>
                              Tárgyalás és problémamegoldás
                        </h4>

                        <p>
                              A mediációs ülés(e)k során a hangsúly a tárgyaláson és a problémamegoldáson van. A cél az, hogy nyílt kommunikáción keresztül, a különböző lehetőségek feltárásával, olyan tisztességes és tartós megállapodás szülessen, amely megfelel minden érintett szükségleteinek és érdekeinek.
                        </p>


                        <h4>
                              Megállapodás megírása
                        </h4>

                        <p>
                              Miután a felek minden lényeges kérdésben megállapodásra jutottak, sor kerül a végső megállapodás megfogalmazásra és megírására. A megállapodás nem jogi szerződés, hanem a probléma megoldásához szükséges kötelezettségek összegzése. A megállapodás egyértelműsíti, hogy a két fél miben jutott egyezségre. 
                        </p>
                        <p>
                              A <b>Válási Mediációs Megállapodás</b> tartalmazni fogja azokat az elemeket, amelyek a Polgári Törvénykönyv szerint a házasság közös megegyezéssel történő felbontásához szükségesek. A <b>Válási Mediációs Megállapodás</b> benyújtása a keresetlevél mellékleteként, lehetővé teszi, hogy a bontóper ne húzódjon hónapokon vagy éveken keresztül.
                        </p>
                        <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                  </div>
            </div>
            <div className="mf-blog-global-tags">
                  <p>Címkék:</p>
                  <a href="#">mediáció</a>        
            </div>
        </div>
      </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
