import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog08Hero from "../../../image/blog_08_hero_egyeni_tanacsadas.png"
import Blog08_01 from "../../../image/blog_08_hero_egyeni_tanacsadas.png"

export default function Blog_08_egyeni_konzultacio() {
  return (
    
    <div className="mf-4404" id="mf-page" data-id="4404">
    <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog08Hero} alt="Egyeni_tanacsadas"/>
                </div>
          
                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">Egyéni tanácsadás</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2023. október 24.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>

                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">

                            <p>
                                  Valójában sok olyan ember, aki elégedett az életével, rendszeresen jár tanácsadásra, mert szüksége van egy olyan biztonságos helyre, ahol ítélkezésmentes környezetben meg tudja osztani az érzéseit, gondolatait. Nem mindig könnyű és egyszerű a barátokhoz vagy a családhoz fordulni tanácsért, hiszen nincs rá garancia, hogy ilyenkor az érzések nem sérülnek, vagy bizonyos határok nem kerülnek átlépésre. Az ilyen beszélgetések akár még jobban összezavarhatják az érzéseket és a gondolatokat.  
                            </p>

                            <h4>
                                  Mit tehet értem az egyéni tanácsadás?
                            </h4>

                            <p>
                                  Az egyéni tanácsadás során megtanulhatja, hogyan hozzon jobb döntéseket a saját érdekében, a saját életében. Problémái átbeszélésével eljuthat az Önnek megfelelő megoldás felé. <b>A tanácsadás  segíthet, hogy újra felfedezze önmagát! </b>
                            </p>

                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog08_01} alt="Egyeni_tanacsadas"/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <h4>
                                  Néhány ok, amiért érdemes egyéni tanácsadást igénybe venni:
                            </h4>
                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">Szeretne túllépni egy szakításon.</li>
                                  <li className="blog-globale-has-before">Családi problémákba ütközik.</li>
                                  <li className="blog-globale-has-before">Főiskolán/egyetemen vagy egy új munkahelyen kezd.</li>
                                  <li className="blog-globale-has-before">Válást fontolgat.</li>
                                  <li className="blog-globale-has-before">Ismerkedési nehézségei vannak.</li>
                                  <li className="blog-globale-has-before">Gyász veszteség feldolgozásakor.</li>   
                            </ul>

                            <p>
                                  Ha kíváncsi arra, hogy az egyéni tanácsadás segíthet-e Önnek, forduljon hozzám bizalommal, és tudjon meg többet. Nincs vesztenivalója, de nyerhet bőven. Az egyéni tanácsadás lehetőséget nyújt leküzdeni a nehézségeit és olyan készségeket alakít ki, amelyek segítik Önt abban, hogy magabiztosabban nézzen szembe a jövőbeli kihívásokkal.
                            </p>

                            <h4>
                                  Mire számíthat, ha először jár tanácsadáson:
                            </h4>
                            <p>
                                  Az első alkalom arról fog szólni, hogy egy kicsit jobban megismerjük egymást. A találkozó egyik legfontosabb része az, hogy megfogalmazásra kerüljenek az Ön egyéni szükségletei, és az, hogy mit szeretne elérni a közös munka során.
                            </p>

                            <p>
                                  Vannak, akiknek nincsenek céljaik, amikor először jönnek, csak azt szeretnék, hogy valakivel beszélgethessenek. Ez teljesen rendben van. Azt a támogatást fogom nyújtani Önnek, amelyre szüksége van ahhoz, hogy megnyíljon és beszélni tudjon mindenről, amiről szeretne.
                            </p>

                            <h4>
                                  A tanácsadó szerepe
                            </h4>

                            <p>
                                  A bizalomra, empátiára épülő ügyfél-tanácsadó kapcsolat létfontosságú a tanácsadás pozitív kimeneteléhez. Tanácsadójaként a folyamat során a következőkkel fogom támogatni Önt: 
                            </p>

                            <ul className="blog-globale-list1">
                                  <li className="blog-globale-has-before1">Biztonságos, ítélkezésmentes környezetet teremtek.</li>
                                  <li className="blog-globale-has-before1">Empatikus támogatást nyújtok céljai eléréséhez.</li>
                                  <li className="blog-globale-has-before1">Segítem az önismeret és a személyes növekedés útján.</li>
                                  <li className="blog-globale-has-before1">Segítek a célok és stratégiák meghatározásában, hogy elérje a kívánt változásokat.</li>
                            </ul>

                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                      </div>

                      <div className="mf-blog-global-tags">
                            <p>Címkék:</p>
                            <a href="#">Tanácsadás</a>
                            
                      </div>
                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
</div>
    )
}
