import React from "react"
import { Link } from "react-router-dom";

import"../../CSS/mf_service.css"

import ServEgyeni from "../../image/home_service_egyeni.png"
import ServParkapcs from "../../image/home_service_parkapcsolat.png"
import ServCsalad from "../../image/home_service_csalad.png"
import ServMozaik from "../../image/home_service_mozaikcsalad.png"
import ServHazassag from "../../image/home_service_hazasag_elotti.png"
import ServSzuloi from "../../image/home_service_szuloi_coaching.png"
import ServValas from "../../image/home_service_valas_tan.png"
import ServMediacio from "../../image/home_service_mediacio.png"
import ServCsop from "../../image/home_service_csoportok.png"

export default function Szolgaltatasok() {
  return (
  <div>
    <div className="mf-7954"  id="mf-page" data-id="7954">
      {/*Hero first section starts*/}
      <div className="mf-page section section-img-bg-3m3f1622" data-id="3m3f1622">
            
        <div className="container mf-container p-0">
              
          <div className="mf-column-wrap mf-col-100">
            <div className="mf-widget-container">
              <div className="mf-widget">
                <div className="title-85f6e329 w-1" data-id="85f6e329">
                  <h1 className="mf-heading-title">Szolgáltatások</h1> 
                </div>
                <div className="mf-widget-text-description text-51b7e42c" data-id="51b7e42c">
                  <p className="mf-text-description"></p>
                </div>
              </div>
            </div>   
          </div>
        </div>
      </div>

      {/*home-service section content starts*/}
      <div className="mf-page section mf-section-service-content" data-id="">     
        <div className="container mf-home-service-container">    
          <div className="mf-col-15 mf-col-md-33 p-2">
            <div className="mf-home-service-content">
              <div className="mf-home-service-bkg"></div>
              <div className="mf-home-service-img">
                <img src={ServEgyeni} id="profile1" alt="egyeni konzultacio"/>
              </div>
              <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                <h1 className="mf-home-heading-title">Egyéni konzultáció</h1>
              </div>
              <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                <p className="mf-home-service-text-description">Szeretne tisztán látni, elégedettnek érezni magát? Személyes konfliktusokat vagy kapcsolati nehézségeket kell megoldania?</p>
          </div>    
            <div>
              <div className="mf-home-service-soc">             
                    <Link to="/Egyeni_konzultacio" className="mf-home-service-btn btn"  >Bővebben </Link> 
              </div>               
            </div>    
          </div>
        </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
            <div className="mf-home-service-content">
                  <div className="mf-home-service-bkg"></div>
                  <div className="mf-home-service-img">
                        <img src={ServParkapcs} id="profile1" alt="egyeni konzultacio"/>
                  </div>
                  <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                        <h1 className="mf-home-heading-title">Párkapcsolati tanácsadás</h1>
                  </div>
                  <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                        <p className="mf-home-service-text-description">Szeretné tudni hol tart a párkapcsolatában és hogyan folytatható boldogan a közös élet? Több közelségre vágyik a párjától?</p>
                  </div>    
                  <div>
                        <div className="mf-home-service-soc">             
                              <Link to="/Parkapcsolat_tanacs" className="mf-home-service-btn btn"  >Bővebben </Link> 
                        </div>               
                  </div>    
            </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={ServCsalad} id="profile1" alt="egyeni konzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Családkonzultáció</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretné ha több harmónia lenne otthon? Jó lenne, ha nyugalom és az öröm állandó része lenne a mindennapi életének?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Csaladkonzultacio" className="mf-home-service-btn btn"  >Bővebben </Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={ServMozaik} id="profile1" alt="egyeni konzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Mozaikcsalád-konzultáció</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretné, ha családtagjai között jobb lenne a kommunikáció és összehangoltabban működne a családja? Szívesen beszélgetne eről?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Mozaikcsalad_konz" className="mf-home-service-btn btn"  >Bővebben </Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={ServHazassag} id="profile1" alt="egyeni konzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Házasság előtti tanácsadás</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">A házasság nagy lépés! Szeretné ha Ön és a párja felkészültebben vágna neki a közös életüknek?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Hazassag_elott" className="mf-home-service-btn btn"  >Bővebben </Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={ServSzuloi} id="profile1" alt="egyeni konzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Szülő coaching</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szüksége van szülői stratégiára, hogy pozitív kapcsolatot alakítson ki gyermekével?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Szulo_coaching" className="mf-home-service-btn btn"  >Bővebben </Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={ServValas} id="profile1" alt="egyeni konzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Válási tanácsadás</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretné végiggondolni a helyzetét, hogy magabiztosabb döntést tudjon hozni a házassága jövőjéről?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Valasi_tanacsadas" className="mf-home-service-btn btn"  >Bővebben </Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
                <div className="mf-home-service-content">
                      <div className="mf-home-service-bkg"></div>
                      <div className="mf-home-service-img">
                            <img src={ServMediacio} id="profile1" alt="egyeni konzultacio"/>
                      </div>
                      <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                            <h1 className="mf-home-heading-title">Mediáció</h1>
                      </div>
                      <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                            <p className="mf-home-service-text-description">Szeretne aktív részvevője lenni konfliktusai megoldásának?</p>
                      </div>    
                      <div>
                            <div className="mf-home-service-soc">             
                                  <Link to="/Mediacio" className="mf-home-service-btn btn"  >Bővebben </Link> 
                            </div>               
                      </div>    
                </div>
          </div>

          <div className="mf-col-15 mf-col-md-33 p-2">
            <div className="mf-home-service-content">
              <div className="mf-home-service-bkg"></div>
              <div className="mf-home-service-img">
                    <img src={ServCsop} id="profile1" alt="egyeni konzultacio"/>
              </div>
              <div className="mf-widget-home-service-heading-title home-service-title-29fe5372" data-id="29fe5372">
                    <h1 className="mf-home-heading-title">Csoportok & Tréningek</h1>
              </div>
              <div className="mf-widget-home-service-text-description home-service-text-31f6b357" data-id="31f6b357">
                <p className="mf-home-service-text-description"><Link to="/OnismeretiTrening">Önismereti tréning</Link><br/><Link to="/ParkapcsolatTrening">Párkapcsolaterősítő tréning</Link><br/><Link to="/IskolaiKonfliktus">Iskolai konfliktuskezelési tréning</Link></p>
              </div>    
                <div>
                      <div className="mf-home-service-soc">             
                            <Link to="/Csoportok" className="mf-home-service-btn btn"  >Bővebben </Link> 
                      </div>               
                </div>    
            </div>
          </div>
        </div>
      </div>
      {/*Home-service section content ends*/}

      {/*contact section starts */}

      <div className="mf-page section mf-contact-section" data-id="">

        <div className="container mf-contact-container p-0">

          <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
            <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
          </div>

          <div className="mf-contact-row">

            <div className="mf-contact-content padd-10">
                  <h3 className="contact-title">Kapcsolat</h3>

                  <div className="contact-info">
                        <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                        <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                        <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                  </div>

            </div>
                
            <div className="mf-contact-form padd-10">
            <form action="mail.php" method="POST">
                  <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                  <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                  <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                  <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                 
                  <div className="mf-home-contact-check-box">
                  <label for="vehicle"></label><br/>
                  <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
            
                  <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                  </div>
                  <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
            </form>                                      
            </div>
          </div>
        </div>

      </div>

      {/*contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
        <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  </div>
  )
}
