import React, {useRef, useEffect} from 'react'
import { Link } from "react-router-dom";

import "../../CSS/online_konz.css"

export default function Online_konz() {

  return (
    <div className="mf-3763"  id="mf-page" data-id="3763">

      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-67m41f74" data-id="67m41f74">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="hero-title-45m11f77 w-1" data-id="45m11f77">
                                          <h1 className="mf-heading-title">Online konzultáció<span></span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>
      {/*hero section ends*/}

      {/*Online first section starts*/}
      <div className="mf-page section mf-online-section" data-id="">
            <div className="container mf-online-container p-0">
                  <div className="mf-online-text-block">
                        <div className="mf-widget-heading-title mf-online-title-87m92f34 w-1" data-id="87m92f34">
                              <h3 className="mf-heading-title">Miért az online konzultáció?</h3>
                        </div>
                        <div className="mf-family-content-text">
                              <div className="mf-widget-text-description mf-online-text-21m35f73" data-id="21m35f73">
                                    <p className="mf-text-description">
                                          Ha Magyarországon belüli vagy kívüli földrajzi távolság, utazás vagy időbeosztás miatt nem lehetséges a konzultáción való személyes részvétel, akkor  lehetőség van akár a teljes vagy egyes ülések online történő lebonyolítására.
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-online-text-21m35f73" data-id="21m35f73">
                                    <p className="mf-text-description">
                                          Ezt a módszert számos más országban pl. USA, Svédország, Hollandia és Németország, már évek óta alkalmazzák, és előnyei számos tanulmányban olvashatóak. A „Journal of Affective Disorders” című folyóirat egyik cikke szerint az online terápián résztvevőknél a  hatás sok esetben még tovább is tartott, mint a személyes  terápián résztvevőknél.
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-online-text-21m35f73" data-id="21m35f73">
                                    <p className="mf-text-description">
                                          Mindaddig, amíg van egy jó internetkapcsolata és egy megszakításoktól mentes privát tere a konzultáció lebonyolítására, addig az új technológiából  profitálhatunk, és előnyünkre fordítva olyan szakmai munkát végezhetünk, ami egyébként nem lenne lehetséges.
                                    </p>
                              </div>
                              
                        </div>
                  </div>
            </div>
      </div>
      {/*Online first section ends*/}

      {/*Online second section starts*/}
      <div className="mf-page section mf-online1-section2" data-id="">
            <div className="container mf-online1-container p-0">

                  <div className="mf-online-text-block">
                        <div className="mf-widget-heading-title mf-online-title-24m56f77 w-1" data-id="24m56f77">
                              <h3 className="mf-heading-title">Hogyan működik az online konzultáció?</h3>
                        </div>
                        <div className="mf-widget-text-description mf-online-text-30m88f62" data-id="30m88f62">
                              <p className="mf-text-description">
                                    <b>Ha az online konzultáció mellett döntött vagy felmerült még kérdés Önben:</b>
                              </p>
                        </div>
                        <div className="mf-online-content-text">
                              <ul className="mf-online-list">
                                    <li className="online-has-before">Kérem, vegye fel velem a kapcsolatot telefon vagy e-mailen!</li>
                                    <li className="online-has-before">Röviden beszélünk és felteheti a kérdéseit. Igény esetén időpontot egyeztetünk.</li>
                                    <li className="online-has-before">Az online konzultáció időtartama 60 perc vagy 90 perc, a díja az Árak menüpont alatt található.</li>
                                    <li className="online-has-before">Az időpontegyeztetést követően a megbeszélt napon és időpontban felveszem Önnel a kapcsolatot.</li>    
                              </ul>
                        </div>
                        <div className="mf-widget-text-description mf-online-text-37m88f60" data-id="37m88f60">
                              <p className="mf-text-description">
                                    <i><b>Szeretném felhívni a figyelmét, hogy a beszélgetések képi vagy elektronikus rögzítését törvény tiltja!</b></i>
                              </p>
                        </div>
                  </div>
            </div>
      </div>
      {/*Online second section ends*/}

      {/*contact section starts*/}
      <div className="mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.html" method="POST">
                              <input type="text"name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                        
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" className="contact-btn" name="send" value="Küldés"/>
                        </form>    
                        </div>
                  </div>
            </div>

      </div>
      
      {/*<div className="contact-btn"><Button  type="submit">Küldés <i className="fas fa-paper-plane"></i></Button>
                              </div>*/}
      {/*contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
