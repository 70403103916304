import React, { useState }  from "react";
import { Link } from "react-router-dom";

import "../../CSS/egyeni_konz.css"

import EKonzAdult from "../../image/mf_adult_konzultacio-3.png"
import EKonzEgyeni from "../../image/mf_egyeni_konzultacio_2.png"

export default function Egyeni_konzultacio() {

      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };
      
  return (
    <div className="mf-3843"  id="mf-page" data-id="3843">
      {/*Hero section starts*/}
      <div className="mf-page section section-img-bg-4m6f7843" data-id="4m6f7843">

            
            <div className="container mf-adult-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-adult-widget">
                                    <div className="mf-widget-heading-title adult-title-32m3f273 w-1" data-id="32m3f273">
                                          <h1 className="mf-heading-title">Egyéni konzultáció <span>személyesen és online</span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 

            <div className=" container mf-adult1-container p-0">
                  
                  <div className="mf-adult-quote-container">
                        <div className="adult-quote-box">
                              <blockquote>
                                    <h6>"Amit magadról gondolsz, sokkal fontosabb,
                                    <br/>mint amit mások gondolnak rólad."
                                    </h6>
                              </blockquote>
                        <p>
                              /Seneca/
                        </p>
                        </div>
                  </div>
            </div>
            
      </div>
      {/*Hero section ends*/}

      {/*Adult first section starts*/}
      <div className="mf-page section mf-adult-section" data-id="">

            <div className="container mf-adult2-container p-0">
                  <div className="mf-adult-row">

                        <div className="mf-adult-img padd-25">
                              <img src={EKonzAdult} alt=""/>
                        </div>

                        <div className="mf-adult-info padd-25">
                              <div className="mf-widget-heading-title mf-adult-title-26m4f227 w-1" data-id="">
                                    <h1 className="mf-heading-title">Nem kell mindent egyedül csinálnia!</h1>
                              </div>
                        
                              <div className="mf-widget-text-description mf-adult-text-20m02f57" data-id="50m5e218">
                                    <p className="mf-text-description">
                                          Ez olyan egyszerűen hangzik, de valójában bátorság kell ahhoz, hogy elismerjünk egy pillanatnyi „gyengeséget”, és segítséget kérjünk. Ön az első fontos lépést teszi meg most, amikor támogatást keres.
                                    </p>
                              </div>
                              <div className="mf-adult-btn-box">
                              <Link to="/Kapcsolat" className="mf-adult-btn btn">Küldjön üzenetet!</Link>
                              </div>

                        </div>
                  </div>
            </div>

      </div>
      {/*Adult first section ends*/}

      {/*Adult second section starts*/}
      <div className="mf-page section mf-adult1-section2" data-id="">
            <div className="container mf-adult3-container p-0">
                  <div className="mf-adult-text-block">
                        <div className="mf-widget-heading-title mf-adult-title-67m43f12 w-1" data-id="67m43f12">
                              <h3 className="mf-heading-title">Hogyan segíthet az egyéni konzultáció?</h3>
                        </div>
                        <div className="mf-widget-text-description mf-adult-text-14m5f346" data-id="14m5f346">
                              <p className="mf-text-description">
                                    Az egyéni konzultáció bárki számára hasznos lehet élete bármely szakaszában. 
                                    A konzultációs tér, egy biztonságos hely, ahol elgondolkodhat, saját magára összpontosíthat 
                                    és olyan dolgokkal foglalkozhat amelyek nem úgy mennek, ahogy szeretné.  Akár a munkájában 
                                    szeretne jobban teljesíteni, akár magabiztosabb szülővé szeretne válni, vagy csak saját magát 
                                    szeretné jobban megismerni és elfogadni, én aktívan együttműködök Önnel, abban, hogy olyan 
                                    változást érjen el amilyet szeretne. A konzultációs ülések lehetőséget teremtenek arra, 
                                    hogy  nyíltan, őszintén beszéljen a nehézségeiről, a problémáiról, a céljairól, a vágyairól, a terveiről. 
                                    A folyamat sikerességét számos terápiás módszer és technika segíti, amelyek az ülések során 
                                    elsajátításra kerülnek, hogy  Ön ezeket a technikákat a  későbbiekben önállóan is tudja 
                                    alkalmazni a mindennapok során.
                              </p>
                        </div>
                  </div>

            </div>
      </div>
      {/*Adult second section ends*/}

      {/*Adult-3 section starts*/}
      <div className="mf-page section mf-adult2-section3" data-id="">
            <div className="container mf-adult4-container p-0">
                  <div className="mf-adult-text-block">
                        <div className="mf-widget-heading-title mf-adult-title-67m43f12 w-1" data-id="67m43f12">
                              <h3 className="mf-heading-title">Mikor érdemes egyéni konzultációt igénybe venni?</h3>
                        </div>
                        <div className="mf-adult-content-text">
                              <ul className="mf-adult-list">
                                    <li className="adult-has-before">Ha elégedetlen a jelenlegi életkörülményeivel, és az élete egyszerűen nem ott van ahol szeretné.</li>
                                    <li className="adult-has-before">Ha minden energiáját arra fordítja, hogy másképp legyenek a dolgok, de mégsem sikerül elérni azt.</li>
                                    <li className="adult-has-before">Ha nehéznek talál megtörni bizonyos viselkedéseket.</li>
                                    <li className="adult-has-before">Ha jelentős veszteség vagy  változás miatti válságon megy keresztül (válás vagy szakítás, gyász, betegség, munkahelyelvesztés) és úgy érzi, hogy ezeket az eseményeket nehezen tudja kezelni.</li>
                                    <li className="adult-has-before">Ha nem érzi magát boldognak és nem tudja hogyan változtasson a dolgokon.</li>  
                              </ul>
                        </div>
                  </div>
            </div>
      </div>
      {/*Adult-3 section ends*/}

      {/*Adult-4 section starts*/}
      <div className="mf-page section mf-adult3-section4" data-id="">
            <div className="container mf-adult5-container p-0">
                  <div className="mf-adult2-row">

                        <div className="mf-adult-info1 padd-20">
                              <div className="mf-widget-heading-title mf-adult-title-78m27f13 w-1" data-id="78m27f13">
                                    <h3 className="mf-heading-title">Mit nyer a konzultációval?</h3>
                              </div>
                              <div className="mf-adult-content-text1">
                                    <ul className="mf-adult-list1">
                                          <li className="adult1-has-before">Az ülések alatt újra felfedezi saját képességeit és erőit.</li>
                                          <li className="adult1-has-before">Javulni fog az önértékelése, önismerete.</li>
                                          <li className="adult1-has-before">Az önfeltárás és az önelfogadás révén jobban tudatában lesz gondolatainak, érzéseinek és megtanul megbirkózni a nehézségeivel.</li>
                                          <li className="adult1-has-before">Javulni fognak a másokkal való kapcsolatai.</li>
                                          <li className="adult1-has-before">Hatékonyabb lesz a kommunikációja.</li>
                                          <li className="adult1-has-before">Felfedezi, hogy mire van szüksége és mire vágyik a kapcsolataiban.</li>
                                          <li className="adult1-has-before">Megtanulja használni azokat az eszközöket, amelyekkel a kívánt változásokat elérheti.</li>
                                          
                                    </ul>
                              </div>
                              <div className="mf-widget-text-description mf-adult-text-25m65f98" data-id="25m65f98">
                                    <p className="mf-text-description">
                                          A cél az, hogy Ön megerősödve és magabiztosan távozzon a konzultációs folyamat végén.
                                    </p>
                              </div>
                        </div>

                        <div className="mf-adult-img-1 padd-20">
                              <img src={EKonzEgyeni} alt=""/>
                        </div>


                  </div>

            </div>

      </div>
      {/*Adult-4 section ends*/}

      {/*Adult-5 section starts*/}
      <div className="mf-page section mf-adult4-section5" data-id="">
            <div className="container mf-adult6-container">

                  <div className="mf-adult3-row">

                        <div className="mf-adult-info2 padd-20">

                              <div className="mf-widget-heading-title mf-adult-title-86m23f65 w-1" data-id="86m23f65">
                                    <h3 className="mf-heading-title">GYIK az egyéni konzultációról</h3>
                              </div>

                              <div className="accordion">
                              <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                                    <div className="label1" onClick={() => toggleAccordion(1)}>Mennyi ideig tart a konzultáció?</div>
                                    {openState[1] && (
                                          <div className="content1">
                                                <p>Néha csak néhány konzultációs ülésre van szükség, de helyzettől függően a munka tovább is tarthat. Az első 1-2 alkalom elsősorban információgyűjtésről szól.  Ezen ülések alatt megpróbáljuk közösen meghatározni a célokat, amiért érdemes dolgozni.</p>
                                          </div>
                                    )}
                                    </div>


                                    <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                                    <div className="label1" onClick={() => toggleAccordion(2)}>Milyen gyakoriak az ülések?</div>
                                    {openState[2] && (
                                          <div className="content1">
                                                <p>Az ülések gyakorisága általában 2 hetente egy alkalom, de krízis idején hamarabb is sor kerülhet találkozóra.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                                    <div className="label1" onClick={() => toggleAccordion(3)}>Mennyibe kerül a konzultáció?</div>
                                    {openState[3] && (
                                          <div className="content1">
                                                <p>Az egyéni konzultáció <b>60 perces</b>, és <b>16.000 forint</b>ba kerül. </p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                                    <div className="label1" onClick={() => toggleAccordion(4)}>Van még kérdése?</div>
                                    {openState[4] && (
                                          <div className="content1">
                                                <p>Látogassa meg a <Link to="/Gyik_tanacsadas">Gyakran Ismételt Kérdések</Link> oldalt, ahol további kérdésekre is választ kaphat! </p>
                                          </div>
                                    )}
                                    </div>

                              </div>

                        </div>

                        <div className="mf-adult-info3 padd-20">

                        </div>



                  </div>
            </div>

      </div>
      {/*Adult-5 section ends*/}

      {/*Adult-6 contact section starts*/}
      <div className="mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-adult-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>                         
                        </div>
                  </div>
            </div>
      </div>
      {/*Adult-6 contact section ends*/}

      {/*Back to Top start*/}
         <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
            </a>
      {/*Back to Top end*/}

</div>
  )
}
