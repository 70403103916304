import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog06Hero from "../../../image/blog_06_hero_stresszkezeles.jpg"
import Blog06_1Hero from "../../../image/blog_06_stresszkezeles.jpg"

export default function Blog_06_stresszkezeles() {
  return (
    <div className="mf-4402" id="mf-page" data-id="4402">
            <div className="mf-page section section-blog-globale">
                  <div className="container mf-blog-globale-container p-0">
                        <div className="mf-blog-globale-hero">
                              <img src={Blog06Hero} alt="Hogyan_segitheted_szeretteid_stresszkezeleset"/>
                        </div>
                  
      
                        <div className="mf-blog-globale-face">
                              <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                                    <h2 className="mf-blog-globale-heading-title">Hogyan segítheted szeretteid stresszkezelését</h2>
                              </div>
      
                              <div className="mf-blog-globale-profile-container">
                                    <div className="mf-blog-globale-profile">
                                          <div className="mf-blog-globale-img-container"></div>   
                              
      
                                          <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                                <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                                <p className="mf-blog-globale-text-description">2023. február 21.</p>
                                          </div>
                                    </div>
                              
                                    <div>
                                    <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                                    </div>
                              </div>
                        </div>
      
      
      
                        <div className="mf-blog-globale-column">
      
                              <div className="mf-blog-globale-text">
                                    <p>
                                          <i>Volt már olyan érzésed, hogy felrobbansz, ha még egy kis nyomás nehezedik rád?</i>
                                    </p>
                                    
                                    <p>
                                          Szülinapi bulira készülődve, az egyik lufiba több levegőt fújtam a kelleténél és nem tudtam megkötni. Még ha talán sikerült volna is, előbb utóbb kidurrant volna, ha csak egy kisebb nyomás is éri.
                                    </p>

                                    <p>
                                          Mit tettem? Nem fújtam bele többet és meg sem kötöttem, hanem kiengedtem a levegőből, enyhítettem a benne lévő nyomáson.
                                    </p>

                                    <p>
                                          Hogy miért épp ez a hasonlatot jutott az eszembe a stresszről? Mert, amikor túl sok stressz ér bennünket, akkor legtöbben úgy érezzük magunkat, mint egy túlfújt léggömb, és általában a hozzánk legközelebb állóktól, a családtagjainktól várjuk azt, hogy segítsenek stresszünk csökkentésében. A tapasztalatok azonban azt mutatják, hogy a családtagok az elvárások ellenére nem hogy csökkentik a meglévő stresszt, hanem gyakran éppen az ellenkezőjét teszik, növelik azt.
                                    </p>

                                    <h4>
                                          Mindezt hogyan tesszük?
                                    </h4>
                                    <ul className="blog-globale-list">
                                          <li className="blog-globale-has-before">Kéretlen tanácsot adunk: elkezdjük mondani, hogyan oldja meg a másik a problémáját.</li>
                                          <li className="blog-globale-has-before">Korlátozzuk az érzéseiket vagy kisebbítjük azokat, ilyen mondatokat használva: „Ezen nincs miért keseregni.” vagy „Azt hiszem, túldramatizálod.”</li>
                                          <li className="blog-globale-has-before">Ítéletet alkotunk azzal, hogy ilyet mondunk: „Soha nem tettem volna ilyet.”</li>
                                          <li className="blog-globale-has-before">Az ellenség mellé állunk: „Nagyjából értem, mit akart a főnököd mondani ezzel.”</li>
                                          <li className="blog-globale-has-before">Vagy vele együtt szorongunk és dühösebbek leszünk, mint ő maga.</li>
                                    </ul>
                              </div>


                              <div className="mf-blog-globale-img-container">
                                    <img src={Blog06_1Hero} alt="Hogyan segitheted szeretteid stresszkezeleset"/>
                              </div>
      
                              <div className="mf-blog-globale-text">
                                    <h4>
                                          Mit tehetünk a fentiek helyett?
                                    </h4>
                                    <p>
                                          Tegyünk olyan dolgokat, amelyek segítik a másikban csökkenteni a nyomást. Épp úgy, mint amikor egy túlfújt léggömbből kiengedjük a levegőt.
                                    </p>
                                    <h6>
                                          <i>Használd ehhez a következő kérdéseket:</i>
                                    </h6>

                                    <ul className="blog-globale-list1">
                                          <li className="blog-globale-has-before1">„Mi a legnehezebb most neked?”</li>
                                          <li className="blog-globale-has-before1">„Milyen érzéseid vannak?”</li>
                                          <li className="blog-globale-has-before1">„Mit gondolsz, mire van most szükséged?”</li>
                                          <li className="blog-globale-has-before1">„Tudok valamit tenni, amivel támogathatlak?”</li>
                                          <li className="blog-globale-has-before1">„Mit gondolsz az egészről?”</li>
                                    </ul>
                                    <h6>
                                          <i>A kérdések mellett, a következőket teheted még:</i>
                                    </h6>

                                    <ul className="blog-globale-list1">
                                          <li className="blog-globale-has-before1">Légy a szövetségese:„Itt vagyok melletted, támogatlak téged.”</li>
                                          <li className="blog-globale-has-before1">Tedd érvényessé az érzéseit: „Értem miért haragszol.” vagy „Megértem, hogy szomorúnak érzed magad.”</li>
                                          <li className="blog-globale-has-before1">Légy jó hallgatóság: „Figyelek rád, szeretnék még többet tudni, hallani.”</li>
                                          <li className="blog-globale-has-before1">Fogadd el az ő nézőpontját. Az elfogadás nem azt jelenti, hogy egyet is értesz vele, de ezzel támogathatod őt.</li>
                                    </ul>
                                    <p>
                                          Mindezek a technikák segíthetik a másikat abban, hogy csökkentse a saját stresszét.
                                    </p>
                                    <p>
                                          Engedd, hogy a szeretteid is nyugodtan kiengedjék a levegőt. Nem kell velük együtt szoronganod, és nem neked kell megoldást találnod a problémáikra.
                                    </p>
                                    <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>

                                    
                                    

      
                              </div>
      
                              <div className="mf-blog-global-tags">
                                    <p>Címkék:</p>
                                    <a href="#">Kommunikáció</a>
                                    
                              </div>
                        </div>
      
      
      
                  </div>                  
      

            </div>
            {/*Back to Top start*/}
            <a href="#" className="back-to-top">
                  <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
            </a>
            {/*Back to Top end*/} 
      </div>
  )
}
