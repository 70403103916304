import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <footer className="copyright">
        <div className="credit"> Copyright ©2022-2024 <Link to="https://www.webrabbit.hu">Webrabbit</Link> | All rights reserved <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf"> | Adatvédelmi tájékoztató</Link></div>
      </footer>
    </div>
  )
}
