import React from "react";
import { Link } from "react-router-dom";
import "../../CSS/arak.css"

export default function Arak() {
  return (
      <div className="mf-3123"  id="mf-page" data-id="3123">

      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-26m39f10" data-id="26m39f10">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-42m34f89 w-1" data-id="42m34f89">
                                          <h1 className="mf-heading-title">Árak</h1> 
                                    </div>
                              </div>
                        </div>   
                  </div>
            </div>
      </div>
      {/*hero section ends*/}

      {/*rates first section starts*/}
      <div className="mf-page section mf-rates-section" data-id="">
            <div className="container mf-rates-container p-0">
                  <div className="mf-rates-text-block">
                        <div className="mf-widget-heading-title mf-rates-title-15m43f29 w-1" data-id="15m43f29">
                              <h3 className="mf-heading-title">Mennyibe kerülnek a szolgáltatások?</h3>
                        </div>
                        <div className="mf-rates-content-text">
                              <ul className="mf-rates-list">
                                    <li className="rates-has-before">Egyéni konzultáció (60 perc) - 16.000 forint</li>
                                    <li className="rates-has-before">Pár- és családkonzultáció (90 perc) - 22.000 forint</li>
                                    <li className="rates-has-before">Pár- és családkonzultáció kettős vezetéssel (90 perc) - 32.000 forint</li>
                                    <li className="rates-has-before">Mediáció első szakasz(60 perc) - 20.000 forint<br/> második szakasz (120 perc) - 40.000 forint</li>
                                    <li className="rates-has-before">Csoportok - árak az aktuálisan meghírdetett csoportoknál vannak feltüntetve.</li>    
                              </ul>
                        </div>
                  </div>
            </div>
      </div>
      {/*rates first section ends*/}

      {/*rates second section starts*/}
      <div className="mf-page section mf-rates1-section2" data-id="">
            <div className="container mf-rates1-container p-0">
                  <div className="mf-rates1-text-block">
                        <div className="mf-rates-title-68m25f16 w-1" data-id="68m25f16">
                              <h3 className="mf-heading-title">Lemondási feltételek</h3>
                        </div>
                  
                        <div className="mf-rates-content-text">
                              <div className="mf-widget-text-description mf-rates-text-78m26f12" data-id="78m26f12">
                                    <p className="mf-text-description">
                                          A célom az, hogy minőségi szolgáltatást nyújtsak mindenki számára. A meg nem jelenés, a késői érkezés és a lemondás másoknak is kényelmetlenséget okoz. Kérem vegye figyelembe  a lemondásra vonatkozó szabályt!
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-rates-text-78m26f12" data-id="78m26f12">
                                    <p className="mf-text-description">
                                          Amennyiben időpont lemondásra van szüksége, mert a megbeszélt időpontban nem tud részt venni a foglalkozáson kérem, <b>legalább 24 órával</b> korábban mondja le azt telefonon <b>+36 70 418 1114 </b> vagy  e-mailben <b>info@gemeterzsuzsa.hu</b> címen! Az Ön időben történő lemondása lehetővé teszi, hogy más is hozzáférjen a szolgáltatáshoz. 
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-rates-text-78m26f12" data-id="78m26f12">
                                    <p className="mf-text-description">
                                          Későnek minősül a lemondás, ha az időpontot, a megbeszélt időpont előtt <b>kevesebb mint 24 órával</b> mondja le. Amennyiben a lemondás nem történik meg időben, abban az  esetben lemondási díj kerül felszámításra, amely a teljes díj 100%-a.
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-rates-text-78m26f12" data-id="78m26f12">
                                    <p className="mf-text-description">
                                          <b>Köszönöm együttműködését!</b>
                                    </p>
                              </div>
                              
                        </div>
                  </div>
            </div>
      </div>
      {/*rates second section ends*/}

      {/*rates 3 section start*/}
      <div className="mf-page section mf-rates2-section3" data-id="">
            <div className="container mf-rates2-container p-0">
                  <div className="mf-rates2-text-block">
                        <div className="mf-widget-heading-title mf-rates-title-69m25f35 w-1" data-id="69m25f35">
                              <h3 className="mf-heading-title">Fizetési mód</h3>
                        </div>
                        <div className="mf-rates-content-text">
                              <div className="mf-widget-text-description mf-rates-text-74m29f10" data-id="78m26f12">
                                    <p className="mf-text-description">
                                          A szolgáltatások kifizetése a konzultáció/ tanácsadás végén kézpénzben vagy banki átutalással történik.
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-rates-text-74m29f10" data-id="74m29f10">
                                    <p className="mf-text-description">
                                          <i>A szolgáltatások díja áfa mentes.</i>
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-rates-text-74m28f11" data-id="74m28f11">
                                    <p className="mf-text-description">
                                          <b>A szolgáltatások kártyával történő fizetésére nincs lehetőség!</b>
                                    </p>
                              </div>
                              <div className="mf-widget-heading-title mf-rates-title-57m21f79 w-1" data-id="57m21f79">
                                    <h4 className="mf-heading-title">Ha további kérdése van, forduljon hozzám bizalommal.</h4>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      {/*rates 3 section ends*/}

      {/*contact section starts */}
      <div className="mf-page section mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>         
                        </div>
                  </div>
            </div>
      </div>
      {/*contact section ends*/}

      {/*Back to Top start*/}
       <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

    </div>
  )
}
