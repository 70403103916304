import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog11Hero from "../../../image/blog_11_hero_szeretetnyelv.png"
import Blog11_1 from "../../../image/blog_11_szeretetnyelv_1.png"
import Blog11_2 from "../../../image/blog_11_szeretetnyelv.png"


export default function Blog_11_parod_szeretetnyelve() {
  return (
    <div className="mf-4407" id="mf-page" data-id="4407">
    <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog11Hero} alt="Miert_fontos_ismerni_paraod_szeretetnyelvet"/>
                </div>
          

                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">Miért fontos ismerni párod szeretetnyelvét</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2024. február 01.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>



                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">
                            <p>
                                  Azt gondolom, hogy manapság többet várunk a <a href="">párkapcsolattól</a> és a házasságtól, mint eddig bármikor és ez igen csak jelentős kihívás elé állít bennünket. Sokan és sokszor teszik fel azt a kérdést, akár már a házasságkötésük után néhány hónappal, hogy <i>"Mi történt a szerelmünkkel?"</i> 
                            </p>

                            <p>
                                  A feltett kérdésre <b>Dr. Gary Chapman</b><i> "Az egymásra hangolva - Az öt szeretetnyelv a házasságban"</i> című könyvében próbál megoldást kínálni. Chapman azt állítja, hogy amikor szerelemről, szeretetről beszélünk, nem hagyhatjuk figyelmen kívül azt a tényt, hogy az emberek különbözőképpen fejezik ki a szeretetüket a másik iránt és véleménye szerint abban is különbségek adódnak, ahogyan szeretnek szeretetet kapni.
                            </p>
                            <p>
                                  Vannak, akik az ajándékoknak örülnek, mert ettől érzik azt, hogy fontosak a másiknak, mások a másikkal töltött minőségi időből érzik azt, hogy szeretve vannak, megint mások abból érzik, hogy szeretik őket, ha elismerést kapnak vagy dicsérő szavakkal illetik őket. Ahogy a példákból is látható, egyáltalán nem biztos, hogy valakinek a szeretetnyelve ugyanaz, mint a párjáé. Éppen ezért a fenti különbségek, amelyek a szeretet adás-kapásából fakadnak, jelentkeznek sokszor a párkapcsolatokban a szeretet, törődés érzésének hiányaként. Sok esetben a párok bizonytalanok vagy pedig nem is igazán tudják, hogy a partnerüknek mire lenne szüksége ahhoz, hogy szeretve érezze magát.
                            </p>
                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog11_1} alt="Szeretetnyelv_minosegi_ido"/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <p>
                                  Úgy vélem bölcs döntés lehet megvizsgálni és kideríteni, hogy mi a saját, és mi a párunk szeretetnyelve, ha egy szeretetteljes és kielégítő párkapcsolatban szeretnénk élni. Most pedig nézzük meg közelebbről mindegyiket, és azt, hogy hogyan kapcsolódhatsz velük a kapcsolatodban.
                            </p>

                            <h4>
                                  Elismerő szavak
                            </h4>

                            <p>
                                  Sok ember szereti, ha dicséretet és elismerést kap azért, amit csinál. Annak a személynek, akinek az elismerő szavak a szeretetnyelve, annak, a szóbeli elismerés, biztatás azt jelenti, hogy törődnek vele, fontosnak tartják a tetteit, és a felé irányuló elismerés igazán értékes a számára.  Még ha valakinek nem is ez az elsődleges szeretetnyelve, de a párja számára ez tűnik a legfontosabbnak, akkor a dicsérő, elismerő szavak használata segíthet a kapcsolatuk erősebbé tételében.
                            </p>

                            <h6>
                                  Példák az elismerő szavakra:  
                            </h6>

                            <ul className="blog-globale-list1">
                                  <li className="blog-globale-has-before1"><i>"Köszönöm, hogy elmosogattál."</i></li>
                                  <li className="blog-globale-has-before1"><i>"Nagyon büszke vagyok rád, hogy kitartasz az álmaid mellett."</i></li>
                                  <li className="blog-globale-has-before1"><i>"Milyen jól áll neked ez az új hajszín."</i></li>
                            </ul>

                            <h4>
                                  Minőségi idő
                            </h4>

                            <p>
                                  Azok, akik a minőségi időt tekintik elsődleges szeretetnyelvüknek, azok attól érzik szeretve magukat, ha a társuk osztatlan figyelmét élvezhetik. A rájuk szánt idő, a másik szeretetét közvetíti a számukra. Ha valakinek a minőségi idő az elsődleges szeretetnyelve, és a mindennapi elfoglaltságok miatt túl kevés időt tölt együtt a párjával, akkor előbb vagy utóbb elégedetlenséget fog érezni. Hiszen a minőségi idő lényege az együttlét, az együtt töltött idő, ami azt fejezi ki, hogy törődünk egymással és élvezzük egymás társaságát.
                            </p>

                            <h6>
                                  Példák a minőségi időre:  
                            </h6>

                            <ul className="blog-globale-list1">
                                  <li className="blog-globale-has-before1"><i>Közös ebéd a párunkkal.</i></li>
                                  <li className="blog-globale-has-before1"><i>Egy nehéz nap után egy közös séta.</i></li>
                                  <li className="blog-globale-has-before1"><i>A másik meghallgatása, ha beszélgetni szeretne velünk, anélkül, hogy mást csinálnánk közben.</i></li>
                                  <li className="blog-globale-has-before1"><i>Egy közös hobbiban való részvétel, pl. közös főzés vagy túrázás.</i></li>
                            </ul>

                      </div>

                      <div className="mf-blog-globale-img-container">
                            <img src={Blog11_2} alt="Szeretetnyelv_szivessegek"/>
                      </div>

                      <div className="mf-blog-globale-text">
                            <h4>
                                  Szívességek
                            </h4>

                            <p>
                                  A legtöbben nem csak azt szeretjük, ha elmondják nekünk, mennyire szeretnek bennünket, hanem azt is értékeljük, hogy ezt tettekre is váltják. Azok az emberek, akik szeretetnyelvüknek a szívességeket tartják, különösen szeretik, ha valaki tesz valamit értük, mert ők ettől érzik igazán szeretve magukat. 
                            </p>

                            <h6>
                                  Példák a szívességekre:
                            </h6>

                            <ul className="blog-globale-list1">
                                  <li className="blog-globale-has-before1"><i>Segítség a házimunkában, amikor a másik fáradt vagy leterhelt.</i></li>
                                  <li className="blog-globale-has-before1"><i>Segítségnyújtás, ha a másiknak nehézségei vannak egy feladat elvégzésével.</i></li>
                                  <li className="blog-globale-has-before1"><i>Egy meglepetés ebéd vagy vacsora elkészítése.</i></li>
                                  <li className="blog-globale-has-before1"><i>A heti bevásárlás felajánlása.</i></li>
                                  <li className="blog-globale-has-before1"><i>Egy csésze kávé elkészítése.</i></li>
                            </ul>

                            <h4>
                                  Ajándékok
                            </h4>

                            <p>
                                  Az ajándék a szeretet látható, kézzel fogható jelképe. Ha valakinek az ajándékozás az elsődleges szeretetnyelve, az a párjától kapott ajándékot féltve fogja őrizni, mint párja szeretetének megnyilvánulását. Azok, aki szeretnek ajándékokat kapni és adni, azok büszkeséget és örömet érezhetnek, ha sikerül olyasmit adni a párjuknak, amit a másik értékel. Azonban ez a szeretetnyelv nem csak a materializmusról szól, az is ajándék lehet a másiknak, ha önmagunkat, azaz a jelenlétünket ajándékozzuk a másiknak. 
                            </p>

                            <h6>
                                  Példák az ajándékozásra:
                            </h6>

                            <ul className="blog-globale-list1">
                                  <li className="blog-globale-has-before1"><i>Egy meglepetés ajándékcsomag egy személyes üzenettel.</i></li>
                                  <li className="blog-globale-has-before1"><i>Egy kis ajándék, például kedvenc édesség vagy virágok.</i></li>
                                  <li className="blog-globale-has-before1"><i>Párja születésnapjának megtervezése és annak felkutatása, hogy mi az, aminek igazán örülne.</i></li>
                            </ul>

                            <h4>
                                  Testi érintés
                            </h4>

                            <p>
                                  Azok, akiknek a testi érintés a szeretetnyelvük, azt értékelik igazán, ha közel vannak a partnerükhöz. A testi érintés kifejező formái lehetnek az ölelés, a csók, a kézfogás vagy a szexuális érintkezés. Azoknak, akiknek az elsődleges szeretetnyelve a testi érintés, az ilyen fizikai gesztusok biztonságot, szeretetet jelentenek, mindezek hiányában bizonytalanná válhatnak a társuk szeretetét illetően. Ezek a testi érintések erősítik az érzelmi köteléket és segítenek egy mély és erős kapcsolat kialakításában.
                            </p>

                            <h6>
                                  Példák a testi érintésre:
                            </h6>

                            <ul className="blog-globale-list1">
                                  <li className="blog-globale-has-before1"><i>Egy meleg ölelés, amikor a másik szomorú vagy stresszes.</i></li>
                                  <li className="blog-globale-has-before1"><i>Kéz fogása vagy simogatása, beszélgetés közben.</i></li>
                                  
                            </ul>

                            <p>
                                  Érdemes tudni, hogy a legtöbb embernek nem egy, hanem több szeretetnyelve is lehet, de többnyire van egy domináns, amely leginkább közvetíti számára a szeretetet. Az egyes szeretetnyelvek megismerése és a partnerünk szeretetnyelvének a figyelembevétele segíthet bennünket abban, hogy közelebb kerüljünk a másikhoz és mélyebb, intimebb kapcsolatot alakítsunk ki a másikkal. 
                            </p>

                            <p>
                                  Több ingyenes online kviz és értékelés, köztük a hivatalos 5 Love languages Quiz <Link to="http://www.5lovelanguages.com/quizzes">(http://www.5lovelanguages.com/quizzes)</Link>, segíthet a szeretetnyelv azonosításában.
                            </p>
                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>

                      </div>

                      <div className="mf-blog-global-tags">
                            <p>Címkék:</p>
                            <a href="#">Kapcsolatok</a>
                            
                      </div>


                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
</div>
  )
}
