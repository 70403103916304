import React, { useState }  from "react";
import { Link } from "react-router-dom";

import "../../CSS/hazassag_elott.css"

import PreMarried_01 from "../../image/mf_premarital_img.png"
import PreMarried_02 from "../../image/mf_premarital_img3.png"

export default function Hazassag_elott() {

      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };

  return (
    <div className="mf-3258"  id="mf-page" data-id="3258">

      
      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-62m14f61" data-id="62m14f61">

            <div className="mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-43m72f24 w-1" data-id="43m72f24">
                                          <h1 className="mf-heading-title">Házasság előtti tanácsadás<span>személyesen és online</span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>
      {/*hero section ends*/}

      {/*premarital first section start*/}
      <div className="mf-page section mf-premarital-section" data-id="">
            <div className="pre1">
            
            <div className="container mf-premarital-container p-0">
            <div className="mf-premarital-row">
                 

                 <div className="mf-premarital-info padd-15">
                       <div className="mf-text-description " data-id="04m06f54">
                             <p className="mf-premarital-text-04m06f54">
                                   A házasság előtti tanácsadás segíthet Önnek és partnerének felkészülni a házassággal járó kihívásokra.
                             </p>
                       </div> 
                       <div className="mf-text-description" data-id="04m09f70">
                             <p className="mf-premarital-text-04m09f70">
                                   A szerelem gyönyörű dolog, házasságban élni szintén az! 
                             </p>
                       </div>
                       <div className="mf-text-description " data-id="04m10f43">
                             <p className="mf-premarital-text-04m10f43">
                                   A kapcsolatok kezdete általában mesébe illő, és ugyan ki ne vágyna a filmek és mesék boldogan éltek, míg meg nem haltak happy end-jére.  Azonban ahhoz, hogy egy kapcsolat és a vele járó boldogság tartós maradjon, igen sok erőfeszítésre van szükség.  A házasság előtti tanácsadás abban segíthet, hogy tudatosabban, nyitott szemmel és szívvel tekintsenek a kapcsolatukra. Ha így tesznek, akkor reálisabb elvárásaik lesznek a házasélettel kapcsolatban.
                             </p>
                       </div>   
                 </div>
                 < div className="mf-premarital-img">
                       <img src={PreMarried_01} alt="premarital"/>
                 </div>
                 
            </div>
            </div>
            </div>
            <div className="pre2">
                  
            <div className="container mf-premarital-container p-0">
            <div className="mf-premarital-row">
                 

                 <div className="mf-premarital-info padd-15">
                       <div className="mf-text-description " data-id="04m06f54">
                             <p className="mf-premarital-text-04m06f54">
                                   A házasság előtti tanácsadás segíthet Önnek és partnerének felkészülni a házassággal járó kihívásokra.
                             </p>
                       </div> 
                       <div className="mf-text-description" data-id="04m09f70">
                             <p className="mf-premarital-text-04m09f70">
                                   A szerelem gyönyörű dolog, házasságban élni szintén az! 
                             </p>
                       </div>
                       <div className="mf-text-description " data-id="04m10f43">
                             <p className="mf-premarital-text-04m10f43">
                                   A kapcsolatok kezdete általában mesébe illő, és ugyan ki ne vágyna a filmek és mesék boldogan éltek, míg meg nem haltak happy end-jére.  Ahhoz, azonban, hogy egy kapcsolat és a vele járó boldogság tartós maradjon, igen sok erőfeszítésre van szükség.  A házasság előtti tanácsadás abban segíthet, hogy tudatosabban, nyitott szemmel és szívvel tekintsenek a kapcsolatukra. Ha így tesznek, akkor reálisabb elvárásaik lesznek a házasélettel kapcsolatban.
                             </p>
                       </div>   
                 </div>
                 
                 
            </div>
            </div>
            < div className="mf-premarital-img">
                       <img src={PreMarried_01} alt="prematital"/>
                 </div>
            </div>
      </div>
      {/*premarital first section ends*/}

      {/*premarital second section start*/}
      <div className="mf-page section mf-premarital-section-img-bg-76m23f84" data-id="mf-premarital-section-img-bg-76m23f84">
            <div className="container mf-premarital-container-2 p-0">
                  <div className="mf-text-description " data-id="06m04f91">
                        <p className="mf-premarital-text-06m04f91">
                              Az esküvő egy nap, a házasság egy életre szóló utazás.
                        </p>
                  </div> 
            </div>
      </div>
      {/*premarital second section start*/}

      {/*premarital 3 section start*/}
      <div className="mf-page section mf-premarital-section-3" data-id="mf-premarital-section-2">
            <div className="container mf-premarital-container-3 p-0">
                  <div className="mf-text-description " data-id="43m56f84">
                        <p className="mf-premarital-text-43m56f84">
                              Jó, ha azokkal a kulcsokkal indulnak el a házasságban, amelyek egy szilárd 
                              családi alap felépítéséhez szükségesek. <br/>A házasság előtti tanácsadás éppen ezen kulcsok megszerzéséhez segítheti hozzá Önöket.
                        </p>
                  </div> 
            </div>
            <div className="container mf-premarital-container-4 p-0">
                  <div className="mf-text-description " data-id="54m87f61">
                        <p className="mf-premarital-text-54m87f61">
                              Hogyan?
                        </p>
                  </div> 
            </div>
      </div>
      {/*premarital 3 section ends*/}

      {/*premarital 4 section start*/}
      <div className="mf-page section mf-premarital-section-4" data-id="">
            <div className="container mf-premarital-container-5 p-0">
                  <div className="mf-premarital-text-block">
                        
                        <div className="mf-premarital-content-text">
                              <ul className="mf-premarital-list">
                                    <li className="premarital-has-before">A közös munkánk során megtanulhatják, hogyan kommunikáljanak egymással és hogyan oldják meg a konfliktusaikat egészségesebb módon, illetve hogyan mélyítsék el a kapcsolatukban a bizalmat.</li>
                                    <li className="premarital-has-before">Felmérjük közösen kapcsolatuk erősségeit és azt, hogy melyek azok a területek, ahol növekedésre van szükségük.</li>
                                    <li className="premarital-has-before">Segítek az Önök igényeinek és céljainak megfelelően abban, hogy átgondolják az esküvő utáni életüket és megalkothassák közös jövőképüket.</li>   
                              </ul>
                        </div>
                         
                  </div>
            </div>
      </div>
      {/*premarital 4 section ends*/}

      {/*premarital 5 section start*/}
      <div className="pre1">
      <div className=" mf-premarital-section-5" data-id="mf-premarital-section-5">
            <div className="mf-premarital-container-6 p-0">
                  <div className="mf-premarital-row">

                        <div className="mf-premarital-img-1 padd-15">
                              <img src={PreMarried_02} alt="prematital"/>
                        </div>
                        <div className="mf-premarital-info-1 padd-15">
                              <div className="mf-heading-title w-1" data-id="52m67f34">
                                    <h3 className="mf-premarital-title-52m67f34">
                                          Gyakori okok, ami miatt a párok házasság előtti tanácsadást kérnek:
                                    </h3>
                              </div>

                              <div className="mf-premarital-content-text-1">
                                    <ul className="mf-premarital-list-1">
                                          <li className="premarital-1-has-before">fel szeretnének készülni a házasság kihívásaira,</li>
                                          <li className="premarital-1-has-before">nehézségeik vannak a kapcsolatukban, és szeretnének foglalkozni azokkal, mielőtt házasságot kötnének,</li>
                                          <li className="premarital-1-has-before">intimitási nehézségeik vannak,</li>
                                          <li className="premarital-1-has-before">szeretnének biztosak lenni abban, hogy a fontos kérdésekben hasonlóan vélekednek. Ez magában foglalhat olyan dolgokat, mint a gyermekvállalás, gyereknevelés, a pénzügyek vagy a költözés kérdései.</li>
                                    </ul>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      </div>
      <div className="pre2">
      <div className="mf-page section mf-premarital-section-5" data-id="mf-premarital-section-5">
            <div className="container mf-premarital-container-6 p-0">
                  <div className="">

                        <div className="mf-premarital-img-1 padd-15">
                              <img src={PreMarried_02} alt="prematital"/>
                        </div>
                        <div className="mf-premarital-info-1 padd-15">
                              <div className="mf-heading-title w-1" data-id="52m67f34">
                                    <h3 className="mf-premarital-title-52m67f34">
                                          Gyakori okok, ami miatt a párok házasság előtti tanácsadást kérnek:
                                    </h3>
                              </div>

                              <div className="mf-premarital-content-text-1">
                                    <ul className="mf-premarital-list-1">
                                          <li className="premarital-1-has-before">fel szeretnének készülni a házasság kihívásaira,</li>
                                          <li className="premarital-1-has-before">nehézségeik vannak a kapcsolatukban, és szeretnének foglalkozni azokkal, mielőtt házasságot kötnének,</li>
                                          <li className="premarital-1-has-before">intimitási nehézségeik vannak,</li>
                                          <li className="premarital-1-has-before">szeretnének biztosak lenni abban, hogy a fontos kérdésekben hasonlóan vélekednek. Ez magában foglalhat olyan dolgokat, mint a gyermekvállalás, gyereknevelés, a pénzügyek vagy a költözés kérdései.</li>
                                    </ul>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      </div>
      {/*premarital 5 section ends*/}

      {/*premarital 6 section start*/}
      <div className="mf-page section mf-premarital-section-6" data-id="mf-premarital-section-6">
            <div className="container mf-premarital-container-7 p-0">

                  <div className="mf-premarital-row-1">

                        <div className="mf-premarital-info-3 padd-15">

                              <div className="mf-heading-title  w-1" data-id="47m74f74">
                                    <h3 className="mf-premarital-title-47m74f74">GYIK a házasság előtti tanácsadásról</h3>
                              </div>

                              <div className="accordion">
                                   <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                                    <div className="label6" onClick={() => toggleAccordion(1)}>Mennyi ideig tart a konzultáció?</div>
                                    {openState[1] && (
                                          <div className="content6">
                                                <p>A legtöbb pár 5-8 alkalommal jön, hogy igényeiknek és céljaiknak megfelelően megalkossák a jövőképüket.</p>
                                          </div>
                                    )}
                                    </div>


                                    <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                                    <div className="label6" onClick={() => toggleAccordion(2)}>Milyen gyakoriak az ülések?</div>
                                    {openState[2] && (
                                          <div className="content6">
                                                <p>Az ülések gyakorisága általában 2 hetente egy alkalom.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                                    <div className="label6" onClick={() => toggleAccordion(3)}>Mennyibe kerül a házasság előtti tanácsadás?</div>
                                    {openState[3] && (
                                          <div className="content6">
                                                <p>A házasság előtti tanácsadás <b>90 perces</b>, és <b>22.000 forint</b>ba kerül. </p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                                    <div className="label6" onClick={() => toggleAccordion(4)}>Van még kérdése?</div>
                                    {openState[4] && (
                                          <div className="content6">
                                                <p>Látogassa meg a <Link to="/Gyik_tanacsadas">Gyakran Ismételt Kérdések</Link> oldalt, ahol további kérdésekre is választ kaphat! </p>
                                          </div>
                                    )}
                                    </div>

                              </div>

                        </div>

                        <div className="mf-premarital-info4 padd-15">

                        </div>

                  </div>
            </div>

      </div>
      {/*premarital 6 section ends*/}

      {/*contact section starts*/}
      <div className="mf-contact-section" data-id="">

            <div className="mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                        
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                          </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>           
                        </div>
                  </div>
            </div>

      </div>
      {/*contact section ends*/}  

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

    </div>
  )
}
