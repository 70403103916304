import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog05Hero from "../../../image/blog_05_hero_mire_szamithat.jpg"
import Blog05_1 from "../../../image/blog_05_mire_szamithat_1.jpg"
import Blog05_2 from "../../../image/blog_05_mire_szamithat_2.jpg"

export default function Blog_05_mire_szamithat() {
  return (
    <div className="mf-4401" id="mf-page" data-id="4401">
            <div className="mf-page section section-blog-globale">
                  <div className="container mf-blog-globale-container p-0">
                        <div className="mf-blog-globale-hero">
                              <img src={Blog05Hero} alt="Mire szamithat a parkonzultacion"/>
                        </div>
                  
      
                        <div className="mf-blog-globale-face">
                              <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                                    <h2 className="mf-blog-globale-heading-title">Mire számíthat a párkonzultáción</h2>
                              </div>
      
                              <div className="mf-blog-globale-profile-container">
                                    <div className="mf-blog-globale-profile">
                                          <div className="mf-blog-globale-img-container"></div>   
                              
      
                                          <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                                <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                                <p className="mf-blog-globale-text-description">2023. január 18.</p>
                                          </div>
                                    </div>
                              
                                    <div>
                                          <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                                    </div>
                              </div>
                        </div>
      
      
      
                        <div className="mf-blog-globale-column">
      
                              <div className="mf-blog-globale-text">
                                    <p>
                                          A kutatások azt mutatják, hogy a válások elsődleges 
                                          okai közé a kommunikáció és az intimitás 
                                          hiánya, illetve a folyamatos veszekedések 
                                          tartoznak. Még ha nem is jutott el a válás 
                                          gondolatáig, de amikor párterápiára 
                                          jelentkezik, valószínűleg már küzd egy 
                                          ideje a párjával és aggódik vagy fél, hogy 
                                          mi lesz a kapcsolatukkal.
                                    </p>
                                    
                                    <p>
                                          Ha megteszi a nagy lépést abba az irányba, 
                                          hogy megosztja a nehézségeit egy 
                                          szakemberrel, akkor az első alkalom nagyon 
                                          nyugtalanító lehet, különösen, ha még 
                                          sohasem vett részt konzultáción. 
                                          Sokféle érzése lehet, bizonytalannak 
                                          érezheti magát, hogy is fog kinézni a 
                                          folyamat.
                                    </p>
                                    <p>
                                          Szeretnék a mostani bejegyzésemmel betekintést nyújtani abba, 
                                          hogyan zajlik egy konzultáció, hogy amikor találkozik velem, akkor már az igazán fontos dolgokra tudjon Ön és a párja is összpontosítani.
                                    </p>
                                    <p>
                                          Fontos tisztán látni, hogy a konzultáció egy hasznos módszer lehet, 
                                          de nem csodaszer. Ha eljön az első ülésre 
                                          és arra számít, hogy a problémái azonnal 
                                          megoldódnak, akkor csalódni fog. 
                                          A párkonzultáció Öntől, a partnerétől és természetesen 
                                          tőlem is munkát igényel, és nem  lesz olyan egyszerű, mint a filmekben.
                                    </p>
                                    <h4>
                                          Az első ülés
                                    </h4>
                                    <p>
                                          Mindenkinek személyes oka van a konzultáció 
                                          igénybevételére, éppen ezért nincs jó 
                                          vagy rossz ok sem a részvételre. 
                                          Próbáljanak meg időben és kipihenten 
                                          érkezni a találkozóra. Az első ülés 
                                          elsősorban információgyűjtésről fog szólni 
                                          és 90 percig tart.
                                    </p>
                                    <p>
                                          Olyan kérdéseket teszek fel, hogy mi 
                                          történik jelenleg a kapcsolatukban, 
                                          mióta tapasztalják a problémát. 
                                          Megkérem, hogy meséljenek a nehézségeikről, 
                                          és arról hogy miben remélnek változást. 
                                          Az ülés alatt közösen megpróbáljuk 
                                          feltárni, hogy mi az, ami arra késztette 
                                          Önöket, hogy eljöjjenek párkonzultációra 
                                          és mi az elképzelésük a folyamat végéről.
                                    </p>
                                    <p>
                                          Az ülés végén röviden összefoglalom az 
                                          elhangzottakat és közösen megpróbálunk 
                                          világos célokat felvázolni aszerint, hogy 
                                          mi az, amit szeretnének elérni a folyamat 
                                          végére.
                                    </p>
                                    <p>
                                          A konzultáció végén, amennyiben úgy döntenek, 
                                          hogy szeretnék tovább folytatni a 
                                          megkezdett közös munkát, akkor megbeszéljük 
                                          a találkozások gyakoriságát és időpontot 
                                          egyeztetünk a következő alkalomra.
                                    </p>
                              </div>
      
                              <div className="mf-blog-globale-img-container">
                                    <img src={Blog05_1} alt="Mire szamithat a parkonzultacion"/>
                              </div>
      
                              <div className="mf-blog-globale-text">
                                    <h4>
                                          A második és az azt követő ülések
                                    </h4>
                                    <p>
                                          A találkozások célja, hogy alkalomról – alkalomra 
                                          haladva megerősödjön a párkapcsolat három fő területe: 
                                          a partnerség, a konfliktuskezelés és a közös értékrendszer.
                                    </p>
                                    <p>
                                          Az egyes alkalmakon együtt megvizsgáljuk, 
                                          hogy mely területeken működik jól a 
                                          kapcsolatuk és hol vannak nehézségeik. 
                                          A beszélgetéseken keresztül támogatni 
                                          fogom önöket, hogy egy biztonságos és jól 
                                          működő kapcsolat kialakításához szükséges 
                                          készségeket elsajátítsák. A beszélgetések 
                                          alatt segíteni fogom Önöket, hogy 
                                          megtanulják a produktív beszélgetést, 
                                          hogy mélyebb szintre kerüljenek a 
                                          kapcsolatukban.
                                    </p>
                                    <p>
                                          Az ülések végén valószínűleg adni fogok „házi feladatot”, 
                                          amit el kell végezniük a következő alkalomig. Ezek a feladatok segítik az ülésen megbeszéltek elmélyítését.
                                    </p>
                              </div>


                              <div className="mf-blog-globale-img-container">
                                    <img src={Blog05_2} alt="Mire szamithat a parkonzultacion"/>
                              </div>

                              <div className="mf-blog-globale-text">

                                    <h6>
                                          Találkozásaink során a következő dolgokat tanulhatják meg:
                                    </h6>


                                    <ul className="blog-globale-list">
                                          <li className="blog-globale-has-before">Hogyan értsük meg jobban a másikat.</li>
                                          <li className="blog-globale-has-before">Hogyan beszéljük meg a problémákat.</li>
                                          <li className="blog-globale-has-before">Hogyan azonosítsuk a nemkívánatos kommunikációs szokásokat.</li>
                                          <li className="blog-globale-has-before">Hogyan kezelhetjük a negatív érzéseinket.</li>
                                          <li className="blog-globale-has-before">Hogyan kerülhetünk közelebb a másikhoz, hogy mélyebb intimitást érjünk el.</li>
                                    </ul>

                                    <p>
                                          Ha úgy gondolja, hogy készen áll arra, hogy változtasson kapcsolatán, akkor a párkonzultáció egy jó kiindulási lehetőség.
                                    </p>
                                    <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
      
                              </div>
                        </div>
      
                        <div className="mf-blog-global-tags">
                              <p>Címkék:</p>
                              <a href="#">Tanácsadás</a>
                              
                        </div>
                  </div>                  

            </div>

           {/*Back to Top start*/}
            <a href="#" className="back-to-top">
                  <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
            </a>
            {/*Back to Top end*/} 
      </div>
  )
}
