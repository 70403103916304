import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog01Hero from "../../../image/blog_01_hero_7_kulcs.png"
import Blog01Kulcs1 from "../../../image/blog_01_7_kulcs_1.jpg"
import Blog01Kulcs2 from "../../../image/blog_01_7_kulcs_2.jpg"


export default function Blog_01_het_plusz_egy() {
  return (
    <div className="mf-4396" id="mf-page" data-id="4396">
      <div className="mf-page section section-blog-globale">
            <div className="container mf-blog-globale-container p-0">
                  <div className="mf-blog-globale-hero">
                        <img src={Blog01Hero} alt="Het_plusz_egy_kulcs_a_sikeres_kapcsolatokhoz"/>
                  </div>
            

                  <div className="mf-blog-globale-face">
                        <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                              <h2 className="mf-blog-globale-heading-title">Hét plusz egy kulcs a sikeres kapcsolatokhoz</h2>
                        </div>

                        <div className="mf-blog-globale-profile-container">
                              <div className="mf-blog-globale-profile">
                                    <div className="mf-blog-globale-img-container"></div>   
                        

                                    <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                          <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                          <p className="mf-blog-globale-text-description">2022. november 21.</p>
                                    </div>
                              </div>
                        
                              <div>
                              <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                              </div>
                        </div>
                  </div>



                  <div className="mf-blog-globale-column">

                        <div className="mf-blog-globale-text">
                              <p>
                                    Minden egyes sikeres kapcsolatnak vannak közös jellemzői, 
                                    legyen ez a kapcsolat romantikus, munkahelyi, baráti vagy családi. 
                                    A kommunikáció mindig fontos eleme az egészséges, jól működő kapcsolatoknak, 
                                    de vannak még további fontos tényezők is amelyek befolyással vannak rá.
                              </p>
                              <h4>Válalj felelőséget!</h4>
                              <p>
                                    Feledkezz el az 50-50 százalékról. 
                                    Vállalj teljes felelősséget a kapcsolataidért. A kapcsolatok rendszeres törődést és alkalmazkodást igényelnek.
                              </p>
                              <p>
                                    Vajon te megadod-e kapcsolataid sikeréhez a szükséges időt és odafigyelést?
                              </p>
                              <h4>
                                    Légy megbízható!
                              </h4>
                              <p>
                                    A kapcsolatok típusától függetlenül a megbízhatóság kulcsfontosságú tényező, 
                                    elengedhetetlen, hogy megbízhassanak benned és számíthassanak rád. 
                                    A bizalom és a megbízhatóság fontosságát egy kapcsolatban nem lehet túlbecsülni.
                              </p>
                        </div>


                        <div className="mf-blog-globale-img-container">
                              <img src={Blog01Kulcs1} alt="kulcs_megbizhatosag"/>
                        </div>

                        <div className="mf-blog-globale-text">
                              <h4>
                                    Bocsáss meg hamar!
                              </h4>
                              <p>
                                    Mindenki követ el hibákat, és mindenkinek vannak rossz napjai. 
                                    Még neked is! Ha nem tudsz megbocsátani egy kapcsolatban, 
                                    az többnyire azért van, mert azt látod, hogy a másik személy 
                                    nem vállal felelősséget a saját részéért.
                              </p>
                              <h4>
                                    Figyelj!
                              </h4>
                              <p>
                                    A kommunikáció kulcsfontosságú, és csak akkor válik sikeressé, 
                                    ha a teljes figyelmedet a másik felé fordítod. Kapcsold ki a televíziót és a laptopod, 
                                    vedd le a telefonod hangját. Adj időt a másiknak. 
                                    Ha valami nem világos vagy egyértelmű a beszélgetések során, kérdezz rá és tisztázd.
                              </p>
                              <h4>
                                    Mondd el az igényeidet!
                              </h4>
                              <p>
                                    Tudasd másokkal, hogy mire van szükséged és mit vársz el tőlük. 
                                    A legtöbb nézeteltérés – bármilyen kapcsolatot is nézünk – abból fakad, 
                                    hogy figyelmen kívül hagyják valakinek a szükségleteit és elvárásait. 
                                    Merj megszólalni és mondd el az igényeidet! Sokszor elvárjuk másoktól,
                                    hogy olvassanak a gondolatainkban vagy bizonyos módon viselkedjenek. 
                                    Ez nem igazságos és nem is hatékony.
                              </p>
                        </div>

                        <div className="mf-blog-globale-img-container">
                              <img src={Blog01Kulcs2} alt="kulcs_velemeny"/>
                        </div>

                        <div className="mf-blog-globale-text">
                              <h4>
                                    Légy elfogadó!
                              </h4>
                              <p>
                                    Mindenkinek vannak hibái. 
                                    Az a képesség, hogy elfogadd, 
                                    valaki hiányosságait vagy figyelmen kívül hagyd a hibáit, 
                                    a kapcsolatok működéséhez elengedhetetlen. 
                                    Elfogadás nélkül egy kapcsolat az állandó elégedetlenség és szomorúság forrása lesz.
                              </p>
                              <h4>
                                    Értékeld rendszeresen a kapcsolataid!
                              </h4>
                              <p>
                                    Az autódat is úgy vezeted, hogy időnként ellenőrzöd, hogy rendben van-e minden rajta. 
                                    Viszonyulj ugyanezzel a hozzáállással a kapcsolataidhoz is. Időnként vizsgáld meg azokat, 
                                    hogyan működnek és tedd meg a szükséges lépéseket a megfelelő működés és megerősítés érdekében.
                              </p>
                              <h4>
                                    Plusz egy javaslat: Tarts szünetet!
                              </h4>
                              <p>
                                    Gyakran pillanatok alatt mondunk ki valamit, amit megbánunk és évek kellenek a jóvátételéhez. 
                                    Ha hajlamos vagy arra, hogy a pillanat hevében olyan dolgokat mondj, ami túl bántó, akkor tanulj 
                                    meg szünetet tartani, mielőtt bármit mondanál. Mély levegővétel és tíz másodperc szünet már 
                                    kiküszöbölheti a kapcsolati nehézségek nagy részét.
                              </p>
                              <p>
                                    Kapcsolataink minősége befolyásolja boldogságunkat és sikereinket. A jól működő és támogató 
                                    kapcsolatok gazdagítják életünket.
                              </p>
                              <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                        </div>

                        <div className="mf-blog-global-tags">
                              <p>Címkék:</p>
                              <a href="#">Kapcsolatok</a>
                              
                        </div>


                  </div>





            </div>


            

      </div>
      

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}




      
</div>
  );
}
