import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Modal} from 'react-bootstrap'; 
import "../../CSS/onismeret_trening.css"
import "../../CSS/form.css"

import OnismeretTrain01 from "../../image/mf_self_trening_1.png"
import OnismeretTrain02 from "../../image/mf_self_trening_3.png"
  
class Onismeret_form extends React.Component{

    constructor(){  
        super();  
        this.state={  
          show:false 
        };  
        this.handleModal = this.handleModal.bind(this);
      } 
     
      handleModal = () => {  
        this.setState({show:!this.state.show});  
      } ; 

      render(){

        const { openState, show } = this.state;

        console.log("Component rendered"); 
  return (
    <div>
    <div className="mf-page section section-img-bg-73m11f89">

<div className="container mf-hero-container p-0">
      
      <div className="mf-column-wrap mf-col-100">
            <div className="mf-widget-container">
                  <div className="mf-hero-widget">
                        <div className="hero-title-69m71f93 w-1" data-id="69m71f93">
                              <h1 className="mf-heading-title">Önismereti tréning</h1> 
                        </div>
                  </div>
            </div>
      </div>
</div> 
      </div>
   

      <div className="appli-box">    
      
            <div >
                <div className="appli-container">
                    <div className="form">
                    <h2 >Kapcsolatfelvétel</h2>
                    <form action="form.php" method="POST">
                        <div>
                        
                        <div className="appli-inputBox">
                            <label>Neved (kötelező)</label>
                            <input type="text" name="name"  id="name" required="required"/>
                        </div>

                        <div className="appli-inputBox">
                            <label>Születési neved (kötelező)</label>
                            <input type="text" name="birthname" id="birthname" required="required"/>
                        </div>

                        <div className="appli-inputBox">
                            <label>Születési dátumod (kötelező)</label>
                            <input type="text" name="birthday" id="birthday" required="required"/>
                        </div>

                        <div className="appli-inputBox">
                            <label>Születési helyed (kötelező)</label>
                            <input type="text" name="birthplace" id="birthplace" required="required"/>
                        </div>

                        <div className="appli-inputBox">
                            <label>Édesanyád születési neve (kötelező)</label>
                            <input type="text" name="mother" id="mother" required="required"/>
                        </div>

                        <div className="appli-inputBox">
                            <label>Legmagasabb iskolai végzettséged (kötelező)</label>
                            <input type="text" name="school" id="school" required="required"/>
                        </div>

                        <div className="appli-inputBox">
                            <label>Számlázási címed (kötelező)</label>
                            <input type="text" name="addres" id="addres" required="required"/>
                        </div>

                        <div className="appli-inputBox">
                            <label>Telefonszámod</label>
                            <input type="text" name="phone" id="phone" required="required"/>
                        </div>  

                        <div className="appli-inputBox">
                            <label>e-mail cím (kötelező)</label>
                            <input type="text" name="email" id="email" required="required"/>
                        </div>
                        <div className="appli-inputBox">
                            <label>Kérlek írj néhány mondatot magadról és a motivációidról! (kötelező)</label>
                            <textarea name="motivation" id="motivation" required="required"/>
                        </div>
                        <div className="appli-inputBox">
                            <label>Egyéni kérdésed, kérésed</label>
                            <textarea name="question" id="question" />
                        </div>
                        <div className="appli-checkbox">
                            <label for="vehicle"></label><br/>
                            <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/><br/>
                        </div>
                        <div className="alert">
                            <p> Elolvastam és elfogadom az <a href="#" >Adatvédelmi tájékoztatót</a>. A jelentkezési lapon szereplő személyes adataim kezeléséhez és megőrzéséhez hozzájárulok. Adataim továbbítását csak a képzésemben közreműködő személyek és a jogszabályban felhatalmazott szervek felé engedélyezem. Megismertem és tudomásul vettem az adatkezelési tájékoztatóban foglaltakat, így azt, hogy személyes adataim a 2013. évi LXXVII. Tv. 21.§ előírásai alapján kerülnek kezelésre. Megőrzésük a jogszabály alapján 8 év. Adataim továbbítását csak a képzésemben közreműködő személyek és a jogszabályban felhatalmazott szervek (pl. Felnőttoktatási Adatszolgáltató Rendszer) felé engedélyezem.</p>
                        </div>

                        <div className="appli-button-container">
                            
                            <input type="submit" id="send" name="send" className="appli-button" value="Küldés"/>
                        </div>
                        </div>              
                    </form>
                    </div>
                </div>
            </div>
          
  </div>
</div>
   
)
}
}

export default Onismeret_form;
