import React from "react";

export default function Iskolai_konfliktuskezeles() {
  return (
    <div className="mf-3934"  id="mf-page" data-id="mf-3934">
      {/*trening hero section starts*/}
      <div className="mf-page section section-img-bg-72m13f79">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="hero-title-42m71f95 w-1" data-id="42m71f95">
                                          <h1 className="mf-heading-title">Iskolai Konfliktuskezelés</h1> 
                                          <h1 className="mf-heading-title">Feltöltés alatt</h1>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>
    </div>
  )
}
