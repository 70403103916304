import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../CSS/mozaikcsalad_konz.css"

import MozaikCsalad from "../../image/mf_mozaikcsalad_.png"


export default function Mozaikcsalad_konz() {

      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };

  return (
    <div className="mf-3562"  id="mf-page" data-id="3562">
      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-47m58f32" data-id="47m58f32">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-24m87f64 w-1" data-id="24m87f64">
                                          <h1 className="mf-heading-title">Mozaikcsalád-konzultáció<span>személyesen és online</span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 

            <div className="mf-adult1-container p-0">
                  
                  <div className="mf-adult-quote-container">
                        <div className="adult-quote-box">
                              <blockquote>
                                    <h6></h6>
                              </blockquote>
                        <p></p>
                        </div>
                  </div>
            </div>


      </div>
      {/*hero section ends*/}

      {/*mosaic first section starts*/}
      <div className="mf-page section mf-mosaic-section" data-id="">
            <div className="container mf-mosaic-container p-0">

                  <div className="mf-mosaic-text-block">
                        <div className="mf-widget-text-description mf-mosaic-text-79m84f61" data-id="79m84f61">
                              <p className="mf-text-description">
                                    A mozaikcsalád vagy idegen szóval a Patchwork család  egy olyan családtípus napjainkban használt neve, 
                                    amely nagyon régóta létezik. Korábban általában az egyik szülő halála volt a kialakulás alapja,  
                                    manapság azonban a válások számának növekedésével hozható összefüggésbe a létrejöttük. Ezek a családok, 
                                    sem mentesek a konfliktusoktól és nehézségektől. Többnyire a felnőttek életében megjelenő új 
                                    szerelem és az azzal járó változások teremthetnek nehéz helyzetet.
                              </p>
                        </div>
                        <div className="mf-widget-text-description mf-mosaic-text-79m84f61" data-id="79m84f61">
                              <p className="mf-text-description">
                                    A mozaikcsaláddá válás egy hosszú folyamat eredménye, nemzetközi tanulmányok alapján elmondható, 
                                    hogy általában a mozaikcsaládoknak öt évre van szükségük, ahhoz, hogy a tagjai összecsiszolódjanak 
                                    és a közös fejlődés útjára lépjenek. Ahhoz, hogy jól tudjon működni, minden egyes tagnak meg kell 
                                    találnia benne a helyét, a szerepét és a feladatát. Mindehhez egy dologra van  szükség: türelemre.
                              </p>
                        </div>

                        <div className="mf-widget-text-description mf-mosaic-text-79m84f61" data-id="79m84f61">
                              <p className="mf-text-description">
                                    Természetes, hogy a pár tagjai, más más mintát hoznak a származási családjukból, és a korábbi volt 
                                    házastárssal is kialakult már egy szabály- és értékrendszer. Azonban a mozaikcsalád jó működéséhez 
                                    feltétlenül szükség van ismételten új, közös szabályok kialakítására. A rendszer megfelelő működéséhez 
                                    elengedhetetlen, hogy a két szülő között, egymást támogató kapcsolat alakuljon ki.
                              </p>
                        </div>
                  </div>
            </div>
      </div>
      {/*mosaic first section ends*/}

      {/*Mosaic second section starts*/}
      <div className="mf-page section mf-mosaic1-section2" data-id="">
            <div className="container mf-mosaic1-container p-0">
                  <div className="mf-mosaic-row">

                        <div className="mf-mosaic-info padd-15">
                              
                              <div className="mf-widget-text-description mf-mosaic-text-12m32f63" data-id="12m32f63">
                                    <p className="mf-text-description">
                                          Minden család megtapasztal valamilyen konfliktust, de a mozaikcsaládban több olyan egyedi kihívás is van, amelyet a legtöbb szülő nem vesz észre, amíg először nem találkozik velük.
                                    </p>
                              </div>

                              <div className="mf-widget-text-description mf-mosaic-text-11m32f61" data-id="11m32f61">
                                    <p className="mf-text-description">
                                          A családkonzultáció során a mozaikcsalád tagjainak lehetősége  van arra, hogy megtalálják helyüket és megértsék a családban betöltött szerepüket, szembenézzenek a feléjük közvetített szerepelvárásokkal és meghatározásra kerüljenek azok a szerepek, amelyek segítik a család mindennapi működését.
                                    </p>
                              </div>
                        </div>

                        <div className="mf-mosaic-img padd-15">
                              <img src={MozaikCsalad} alt=""/>
                        </div>
                  </div>
            </div>
      </div>
      {/*Mosaic second section ends*/}

      {/*Mosaic-3 section starts*/}
      <div className="mf-page section mf-mosaic2-section3" data-id="">
            <div className="container mf-mosaic2-container p-0">

                  <div className="mf-mosaic1-row">

                        <div className="mf-mosaic-info1 padd-20">

                              <div className="mf-widget-heading-title mf-mosaic-title-65m21f82 w-1" data-id="65m21f82">
                                    <h3 className="mf-heading-title">GYIK a mozaikcsalád-konzultációról</h3>
                              </div>

                              <div className="accordion">
                              <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                                    <div className="label4" onClick={() => toggleAccordion(1)}>Mennyi ideig tart a mozaikcsalád tanácsadás?</div>
                                    {openState[1] && (
                                          <div className="content4">
                                                <p>Néha csak néhány alkalomra van szükség ahhoz, hogy a család visszanyerje az egyensúlyát. A helyzettől függően a munka tovább is tarthat. Az első 1-2 alkalom elsősorban információgyűjtésről szól. Ezen ülések alatt megpróbáljuk közösen meghatározni a célokat, amiért érdemes dolgozni.</p>
                                          </div>
                                    )}
                                    </div>


                                    <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                                    <div className="label4" onClick={() => toggleAccordion(2)}>Milyen gyakoriak az ülések?</div>
                                    {openState[2] && (
                                          <div className="content4">
                                                <p>Az ülések gyakorisága általában 2 hetente egy alkalom, de krízis idején hamarabb is sor kerülhet találkozóra.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                                    <div className="label4" onClick={() => toggleAccordion(3)}>Mennyibe kerül a mozaikcsalád-konzultáció?</div>
                                    {openState[3] && (
                                          <div className="content4">
                                                <p>A <b>90 perces</b> ülés <b>22.000 forint.</b><br/>A kettős vezetésű <b>90 perces</b> ülés <b>32.000 forint.</b> </p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                                    <div className="label4" onClick={() => toggleAccordion(4)}>Van még kérdése?</div>
                                    {openState[4] && (
                                          <div className="content4">
                                                <p>Látogassa meg a <Link to="/Gyik_tanacsadas">Gyakran Ismételt Kérdések</Link> oldalt, ahol további kérdésekre is választ kaphat! </p>
                                          </div>
                                    )}
                                    </div>

                              </div>

                        </div>

                        <div className="mf-mosaic-info4 padd-20">

                        </div>
                  </div>
            </div>

      </div>
      {/*Mosaic-3 section ends*/}

      {/*Couple contact section starts*/}
      <div className="mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                        
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>       
                        </div>
                  </div>
            </div>

      </div>
      {/*Couple contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
