import React from "react";
import { Link } from "react-router-dom";

import"../../CSS/kapcsolat.css"

export default function Kapcsolat() {
  return (
    
    <div className="mf-3173"  id="mf-page" data-id="3173">

    <div className="mf-page section mf-section-img-bg-68m37f92" data-id="68m37f92">

    <div className="container mf-hero-container p-0">

      <div className="mf-column-wrap mf-col-100">
        <div className="mf-widget-container">
          <div className="mf-hero-widget">
            <div className="mf-widget-heading-title hero-title-23m42f16 w-1" data-id="42m34f89">
                  <h1 className="mf-heading-title">Kapcsolat</h1> 
            </div>
          </div>
        </div>   
      </div>
    </div>
          
    </div>  

      <div >
        <div >
          <div>     
            <div className="mf-widget-heading-title mf-contact-title-86m12f24 w-1" >
                  <center><h1 className="mf-heading-title">Lépjen kapcsolatba velem! </h1></center>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mf-page section mf-contact1-section2" data-id="">

        <div className="container mf-contact1-container p-0">
          <div className="mf-contact-info-box">
            <h2 className="contact-title">Küldjön üzenetet nekem!</h2>
            <p className="contact-description">Időpont egyeztetése előtt szeretne több információt?</p>
            <p className="contact-text">Hívjon fel vagy írjon nekem, és a lehető leghamarabb válaszolok a kérdéseire.</p>

            <div className="contact-info">
              <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
              <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
              <Link to="https://www.google.com/maps/place/Budapest,+Platina+u.+1,+1223/@47.4122232,19.0214719,17z/data=!3m1!4b1!4m6!3m5!1s0x4741e7a87f3240c7:0xcda504e38038b15e!8m2!3d47.4122196!4d19.0240468!16s%2Fg%2F11csj4sx99?entry=ttu" target="_blank">
              <h3><i className="fas fa-map-marker-alt"></i>1223 Budapest, Platina utca 1.</h3>
              </Link>
              <Link to="http://maps.google.com/?q=Budaörs+napsugar+setany+4" target="_blank">
              <h3><i className="fas fa-map-marker-alt"></i>2040 Budaörs, Napsugár sétány 4.</h3>
              </Link>
              <Link to="https://www.facebook.com/profile.php?id=100086794785366" target="_blank">
              <h3><i className="fab fa-facebook-f"></i>fb.com/megoldásfókusz</h3>  </Link> 
            </div>
          </div>
              
          <div className="mf-contact-form-box">
            <div className="mf-widget-heading-title mf-cont-title-21m74f59 w-1" data-id="21m74f59">
              <h3 className="mf-heading-title">Miben segíthetek?</h3>
            </div>
            <form action="mail.php" method="POST">
              <div className="mf-home-contact-input-box">
                    <input type="text" id="name" name="name" placeholder="Név" required="required"/>
              </div>
              <div className="mf-home-contact-input-box">
                    <input type="email" id="email" name="email" placeholder="e-mail" required="required"/>
              </div>
              <div className="mf-home-contact-input-box">
                    <input type="text" id="mobil" name="mobil" placeholder="Telefonszám" />
              </div>
              <div className="mf-home-contact-input-box">
                    <textarea name="message" rows="8" cols="80" placeholder="Írjon üzenetet!" required="required"></textarea>
              </div>
              <div className="mf-home-contact-check-box">
                <label for="vehicle"></label><br/>
                <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
            
                <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
              </div>
              <div className="mf-home-contact-input-box">
                    <input type="submit" id="send" className="send-btn" name="send" value="Küldés"/>
            </div></form>
          </div>
        </div>
      </div>

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
          <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
  
    </div>
  )
}
