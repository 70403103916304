import React from "react";
import { Link } from "react-router-dom";


import "../../CSS/iskolai_mediacio.css"
import "../../CSS/mediacio.css"

export default function Iskolai_mediacio() {
  return (
    <div className="mf-3721"  id="mf-page" data-id="3721">
      
      {/*hero section starts*/}
       <div className="mf-page section section-img-bg-29m06f28" data-id="29m06f28">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-42m71f95 w-1" data-id="42m71f95">
                                          <h1 className="mf-heading-title">Iskolai mediáció</h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>
      {/*hero section ends*/}
     
      {/*School mediation first section starts*/}
      <div className="mf-page section mf-smediation-section" data-id="">
            <div className="container mf-smediation-container p-0">
                  <div className="mf-smediation-text-block">
                        <div className="mf-widget-heading-title mf-smediation-title-14m06f17 w-1" data-id="14m06f17">
                              <h3 className="mf-heading-title">Az iskolai mediáció célja:</h3>
                        </div>
                        <div className="mf-smediation-content-text">
                              <ul className="mf-smediation-list">
                                    <li className="smediation-has-before">Az iskolai közösségben fellépő konfliktusok konstruktív és békés módon történő kezelése, az iskolaközösség minden tagjával együttműködésben.</li>
                                    <li className="smediation-has-before">A kommunikációs készségek fejlesztése.</li>
                                    <li className="smediation-has-before">Az empátia fejlődésének és egymás megértésének az elősegítése.</li>
                                    <li className="smediation-has-before">Minden résztvevő fél által elfogadható megoldások keresése és megtalálása.</li>
                                    <li className="smediation-has-before">Az iskolai incidensek (viták, verekedések, zaklatások) számának csökkentése.</li>   
                              </ul>
                        </div>
                  </div>
            </div>
      </div>
      {/*School mediation first section ends*/}

      {/*School mediation second section starts*/}
      <div className="mf-page section mf-smediation1-section2" data-id="blog">
            <div className="container mf-smediation1-container p-0">
                  <div className="mf-smediation-heading-box">
                        
                        <div className="mf-widget-heading-title mf-smediation-title-45m30f08 w-1" data-id="45m30f08">
                              <h1 className="mf-heading-title">Iskolai konfliktusok</h1>
                        </div>
                  </div>
            </div>
      </div>
      {/*School mediation second section starts*/}

      {/*School mediation 3 section starts*/}
      <div className="mf-page section mf-smediation2-section3" data-id="">
            <div className="container mf-smediation2-container p-0">
                  {/*card1*/}
                  <div className="smediation-card">
                        <h4 className="smediation-card-title">diák-diák konfliktus</h4>
                        <p className="smediation-card-description">Diákok közötti konfliktusok számos okból fakadhatnak. Íme néhány gyakori ok:</p>
                        <ul className="mf-smediation-list">
                              <li className="smediation-has-before">személyiségbeli különbségek</li>
                              <li className="smediation-has-before">félreértések, rossz kommunikációs technikák alkalmazása</li>
                              <li className="smediation-has-before">versengés</li>
                              <li className="smediation-has-before">baráti körök közötti feszültségek</li>
                              <li className="smediation-has-before">zaklatás, bántalmazás</li>
                              <li className="smediation-has-before">családi háttér, otthoni problémák</li>
                              <li className="smediation-has-before">iskolai szabályok megsértése</li>   
                        </ul>
                  </div>

                  <div className="smediation-card">
                        <h4 className="smediation-card-title">tanár-diák konfliktus</h4>
                        <p className="smediation-card-description">A tanár-diák között felmerülő konfliktusok gyakori okai lehetnek:</p>
                        <ul className="mf-smediation-list">
                              <li className="smediation-has-before">tanulási és viselkedési elvárások különbségei</li>
                              <li className="smediation-has-before">kommunikációs problémák</li>
                              <li className="smediation-has-before">fegyelmi problémák</li>
                              <li className="smediation-has-before">tanítási módszerek, stilusok</li>
                              <li className="smediation-has-before">szociális és kulturális különbségek</li>
                              <li className="smediation-has-before">túlzott elvárások</li>
                              <li className="smediation-has-before">diszkrimináció és előítéletek</li>
                              <li className="smediation-has-before">személyes problémák</li>  
                        </ul>
                  </div>

                  <div className="smediation-card">
                        <h4 className="smediation-card-title">szülő-tanár konfliktus</h4>
                        <p className="smediation-card-description">A tanár-szülő közötti konfliktusok gyakran a gyermek oktatásával, fejlődésével és jólétével kapcsolatos kérdésekből fakadnak. Okai lehetnek:</p>
                        <ul className="mf-smediation-list">
                              <li className="smediation-has-before">tanítási módszerekel való egyet nem értés</li>
                              <li className="smediation-has-before">eltérő elvárások a tanulmányi teljesítménnyel kapcsolatban</li>
                              <li className="smediation-has-before">hiányos vagy félreérthető kommunikáció</li>
                              <li className="smediation-has-before">gyermek különleges igényei</li>
                              <li className="smediation-has-before">iskolai szabályok és irányelvek</li>
                              <li className="smediation-has-before">diák viselkedési problémái</li>
                              <li className="smediation-has-before">szülők túlzott elvárásai</li>   
                        </ul>
                  </div>
            </div>
      </div>
      {/*School mediation 3 section ends*/}

      {/*School mediation 4 section starts*/}
      <div className="mf-page section mf-mediation-info-section" data-id="">
            <div className="container mf-mediation-info-container p-0">
                  <div className="mf-mediation-info-box">
                        <h2 className="mediation-info-title">Fedezze fel a békés megoldás útját!</h2>
                  </div>  
                  <div className="mf-mediation-info-btnBx"><Link to="/IskolaiKonfliktus">
                  <div className="mediation-info-btn btn" >Iskolai tréning</div></Link>
                  </div>
            </div>  
      </div>
      {/*School mediation 4 section ends*/}
      
      {/*contact section starts*/}
      <div className="mf-page section mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                        
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>           
                        </div>
                  </div>
            </div>

      </div>
      {/*contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>      
  )
}
