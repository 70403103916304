import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog07Hero from "../../../image/blog_07_hero_hazassagi_tanacsadas.png"

export default function Blog_07_hazassagi_tanacsadas() {
  return (
    <div className="mf-4403" id="mf-page" data-id="4403">
    <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog07Hero} alt="Hazassagi_es_parkapcsolati_tanacsadas"/>
                </div>
          

                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">Házassági és párkapcsolati tanácsadás</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2023. szeptember 20.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>

                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">
                            <p>
                                  A <Link to="/Parkapcsolat_tanacs">párkapcsolati tanácsadás</Link> segít megtanulni hatékonyabban kommunikálni, és egészségesebb módon megoldani a problémákat. Sok pár gondolja úgy, hogy csak akkor kell szakemberhez fordulniuk, ha a kapcsolatukban nehézséggel küzdenek, de ez nem így van. A párkapcsolati tanácsadás minden pár számára hasznos lehet, de különösen hasznos lehet azoknak, akik valamilyen változás előtt állnak vagy éppen valamilyen változáson mennek keresztül, például összeköltöznek vagy összeházasodnak, gyermekvállaláson gondolkoznak, esetleg válást fontolgatnak.
                            </p>

                            <p>
                                  Akárhogy is, az a döntés, hogy egy szakemberrel beszélnek a kapcsolatukról, azt mutatja, hogy törődnek egymással. Fontos azonban, hogy ezt a döntést egymás érdekében hozzák meg, nem pedig azért hogy a tanácsadás alatt mindenki a maga igazát vagy a partnere tévedését bizonygassa.
                            </p>

                            <h4>
                                  Néhány ok, amiért érdemes párkapcsolati tanácsadásra menni:
                            </h4>

                            <ul className="blog-globale-list">
                                  <li className="blog-globale-has-before">Úgy érzi, hogy Ön és a párja eltávolodtak egymástól.</li>
                                  <li className="blog-globale-has-before">Gyakran vannak vitáik, amelyeket nem tudnak békés úton megoldani.</li>
                                  <li className="blog-globale-has-before">Ön és a partnere folyamatosan ugyanazokról a dolgokról vitáznak.</li>
                                  <li className="blog-globale-has-before">Sem Ön, sem a partnere nem tud megegyezni fontos kérdésben.</li>
                                  <li className="blog-globale-has-before">Házasság előtt állnak, és előtte tanácsadáson szeretnének részt venni.</li>
                                  <li className="blog-globale-has-before">A házasságában nem érzi jól magát, és szeretne segíteni annak rendbetételében.</li> 
                            </ul>

                            <h4>
                                  A párkapcsolati tanácsadás előnyei
                            </h4>

                            <h6>
                                  <i>A tanácsadáson szabadon kifejezheti érzelmeit!</i>
                            </h6>

                            <p>
                                  A tanácsadás lehetőséget teremt arra, hogy Ön és a partnere is képesek legyenek őszintén kifejezni az érzéseiket egymás irányába és hogy, megszakítás nélkül meghallgassák egymást.  
                            </p>

                            <h6>
                                  <i>Oldja meg a problémáit, mielőtt tönkretenné az a kapcsolatát!</i>
                            </h6>

                            <p>
                                  Egyes párok azért kerülik a problémáik megoldását, mert nem tudják, hogyan kellene azt megoldaniuk. A párkapcsolati tanácsadás olyan megoldáshoz segítheti Önt és partnerét, amely mindkettőjük számára kielégítő lehet.
                            </p>

                            <h6>
                                  <i>Erősítse meg a köteléket!</i>
                            </h6>

                            <p>
                                  Ahogy egyre sikeresebb lesz az egymás közötti <a href="">kommunikáció</a>, a konfliktusmegoldás és a törekvés a másik jobb megértésére, valószínűleg egyre közelebb fogja érezni magát a partneréhez. A negatív érzelmek még a boldog párok közé is éket verhetnek, ha nem foglalkoznak velük megfelelően. A párkapcsolati tanácsadás segíthet abban, hogy Ön és a párja is  öntudatosabbá váljon, és ez által jobban kifejezhessék magukat egymás felé.
                            </p>

                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>



                      </div>

                      <div className="mf-blog-global-tags">
                            <p>Címkék:</p>
                            <a href="#">Tanácsadás</a>
                            
                      </div>
                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

</div>
)
}
