import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../CSS/szuloi_coaching.css"

import ParentsKonz from "../../image/mf_szuloi_coaching_1.png"

export default function Szulo_coaching() {

      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };  

  return (
    <div className="mf-3149"  id="mf-page" data-id="3149">
      
      {/*Home-hero first section starts*/}
      <div className="mf-page section section-img-bg-76m92f15" data-id="76m92f15">
        <div className="container mf-hero-container p-0">
              
          <div className="mf-column-wrap mf-col-100">
            <div className="mf-widget-container">
              <div className="mf-hero-widget">
                <div className="mf-widget-heading-title hero-title-39m24f58 w-1" data-id="39m24f58">
                      <h1 className="mf-heading-title">Szülő coaching</h1> 
                </div>
              </div>
            </div>   
          </div>
        </div>
      </div>
      {/*home-hero section ends*/}

      {/*Parent first section starts*/}
      <div className="mf-page section mf-parent-section" data-id="">
            <div className="container mf-parent-container p-0">
                  <div className="mf-parent-row">

                        <div className="mf-parent-info padd-20">
                              <div className="mf-widget-heading-title mf-parent-title-18m32f67 w-1" data-id="18m32f67">
                                    <h3 className="mf-heading-title">” Az erős gyerekeknek erős szülőkre van szükségük.”</h3>
                              </div>
                              
                              <div className="mf-widget-text-description mf-parent-text-76m38f23" data-id="76m38f23">
                                    <p className="mf-text-description">
                                          A gyermeknevelés a világ egyik legnehezebb munkája. A  családi együttélés olyan mértékben eszkalálódhat, hogy tarthatatlanná válik az otthoni helyzet. A szülőkkel folytatott beszéletéseim során sokszor azt hallom, hogy a gyerekek nem hallgatnak rájuk, provokálják őket, hazudnak nekik. A szülők ettől kiborulnak és mindezek olyan cselekedetekre sarkalják őket az elveszett tekintélyük visszaszerzése miatt (vagy arra, amit hagyományosan tekintélynek vélünk), amit később megbánnak, vagy szégyellnek. Kiabálnak, vagy kezet emelnek a gyermekre, és közben azt veszik észre, hogy úgy viselkednek, amit valójában nem tartanak helyesnek. A bűntetés, az erőszak, a megaláztatás árt a gyermeknek és a szülőben is rossz érzést és bűntudatot kelt.  Ezekről a témákról senki sem beszél szívesen.  
                                    </p>
                              </div>  
                        </div>

                        <div className="mf-parent-img padd-20">
                              <img src={ParentsKonz} alt=""/>
                        </div>
                  </div>
            </div>
      </div>
      {/*Parent first section ends*/}

      {/*Parent second section start*/}
      <div className="mf-page section mf-parent1-section1" data-id="">
            <div className="container mf-parent1-container p-0">
                  <div className="mf-parent-text-block">
                        <div className="mf-widget-heading-title mf-parent-title-25m16f32 w-1" data-id="25m16f32">
                              <h3 className="mf-heading-title">A szülői tanácsadás célja:</h3>
                        </div>
                        <div className="mf-parent-text">
                              <ul className="mf-parent-list">
                                    <li className="parent-has-before">Az eszkaláció megszüntetése.</li>
                                    <li className="parent-has-before">Javítani a szülői együttműködést.</li>
                                    <li className="parent-has-before">Erősíteni a szülői jelenlétet és tekintélyt.</li>
                                    <li className="parent-has-before">Határozott és gondoskodó hozzáállás kialakítása a gyermek irányába.</li>
                                    <li className="parent-has-before">Cselekvések kivitelezése konkrét lépéseken keresztül.</li>    
                              </ul>
                        </div>

                        <div className="mf-widget-text-description mf-parent-text-41m62f43" data-id="41m62f43">
                              <p className="mf-text-description">Szülői coaching esetén az együttműködés középpontjában a szülők állnak. 
                                    Közösen olyan erőszakmentes cselekvési alternatívákat dolgozunk ki, amelyet otthon megvalósíthat.
                              </p>
                        </div>
                  </div>
            </div>
      </div>
      {/*Parent second section ends*/}

      {/*Parent-3 section starts*/}
      <div className="mf-page section mf-parent2-section3" data-id="">
            <div className="container mf-parent2-container">

                  <div className="mf-parent1-row">

                        <div className="mf-parent-info1 padd-20">

                              <div className="mf-widget-heading-title mf-parent-title-22m09f01 w-1" data-id="22m09f01">
                                    <h3 className="mf-heading-title">GYIK a szülői tanácsadásról</h3>
                              </div>

                              <div className="accordion">
                              <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                                    <div className="label5" onClick={() => toggleAccordion(1)}>Mennyi ideig tart a szülői tanácsadás?</div>
                                    {openState[1] && (
                                          <div className="content5">
                                                <p>Néha csak néhány alkalomra van szükség, de a helyzettől függően a munka tovább is tarthat. Az első 1-2 alkalom elsősorban információgyűjtésről szól. Ezen ülések alatt megpróbáljuk közösen meghatározni a célokat, amiért érdemes dolgozni.</p>
                                          </div>
                                    )}
                                    </div>


                                    <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                                    <div className="label5" onClick={() => toggleAccordion(2)}>Milyen gyakoriak az ülések?</div>
                                    {openState[2] && (
                                          <div className="content5">
                                                <p>Az ülések gyakorisága általában 2 hetente egy alkalom, de krízis idején hamarabb is sor kerülhet találkozóra.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                                    <div className="label5" onClick={() => toggleAccordion(3)}>Mennyibe kerül a szülői tanácsadás?</div>
                                    {openState[3] && (
                                          <div className="content5">
                                                <p>A <b>60 perces</b> ülés <b>16.000 forint</b><br/>A <b>90 perces</b> ülés <b>22.000 forint.</b></p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                                    <div className="label5" onClick={() => toggleAccordion(4)}>Van még kérdése?</div>
                                    {openState[4] && (
                                          <div className="content5">
                                                <p>Látogassa meg a <Link to="/Gyik_tanacsadas">Gyakran Ismételt Kérdések</Link> oldalt, ahol további kérdésekre is választ kaphat! </p>
                                          </div>
                                    )}
                                    </div>

                              </div>

                        </div>

                        <div className="mf-parent-info2 padd-20">

                        </div>



                  </div>
            </div>

      </div>
      {/* Parent-3 section ends*/}

      {/*contact section starts */}
      <div className="mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                        
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>         
                        </div>
                  </div>
            </div>

      </div>
      {/*contact section ends */}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
