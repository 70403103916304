import React,  {Fragment, useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";


import LogoPic from "../image/mf-logo-new.png"

export default function Header() {

  // Állapot a lenyíló menü megnyitásához vagy bezárásához
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMouseEnter = () => {
    setIsServicesOpen(true);
  };

  const handleMouseLeave = () => {
    setIsServicesOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <div>
    <Fragment >
        <header className="header" data-header ref={menuRef}>
        <Link to="/" className="logo"><img src={LogoPic} alt="Logo"/></Link>

          <nav className={`navbar ${menuOpen ? "open" : ""}`}>
            <Link to="/" onClick={closeMenu}>Főoldal</Link>
            <Link to="/Rolam" onClick={closeMenu}>Rólam</Link>
            <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <Link to="/Szolgaltatasok" className="dropbtn">Szolgáltatások</Link>
              {isServicesOpen && (
                <div className="dropdown-content" onClick={closeMenu}>
                  <Link to="/Egyeni_konzultacio">Egyéni konzultáció</Link>
                  <Link to="/Parkapcsolat_tanacs">Párkapcsolati tanácsadás</Link>
                  <Link to="/Csaladkonzultacio">Családkonzultáció</Link>
                  <Link to="/Mozaikcsalad_konz">Mozaikcsalád konzultáció</Link>
                  <Link to="/Hazassag_elott">Házasság előtti tanácsadás</Link>
                  <Link to="/Szulo_coaching">Szülő coaching</Link>
                  <Link to="/Valasi_tanacsadas">Válási tanácsadás</Link>
                  <Link to="/Csoportok">Csoportok</Link>
                </div>
              )}
            </div>
            <Link to="/Online_konz" onClick={closeMenu}>Online</Link>
            <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <Link to="/Mediacio" className="dropbtn">Mediáció</Link>
              {isServicesOpen && (
                <div className="dropdown-content">
                  <Link to="/Valasi_Mediacio" onClick={closeMenu}>Válási mediáció</Link>
                  <Link to="/Csaladi_Mediacio" onClick={closeMenu}>Családi mediáció</Link>
                  <Link to="/Iskolai_Mediacio" onClick={closeMenu}>Iskolai mediáció</Link>
                </div>
              )}
            </div>
            
            <Link to="/Blog" onClick={closeMenu}>Blog</Link>
            <Link to="/Arak" onClick={closeMenu}>Árak</Link>
            <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <a className="dropbtn">GYIK</a>
              {isServicesOpen && (
                <div className="dropdown-content">
                  <Link to="/Gyik_tanacsadas" onClick={closeMenu}>GYIK tanácsadás</Link>
                  <Link to="/Gyik_mediacio" onClick={closeMenu}>GYIK mediáció</Link>
                </div>
              )}
            </div>
            <Link to="/Kapcsolat">Kapcsolat</Link>
          </nav>

          <div className="icons">
          <Link to="https://www.facebook.com/profile.php?id=100086794785366" target="_blank">
            <div id="facebook-btn" className="fab fa-facebook-f"></div>
          </Link>
            <div id="menu_btn" className="fas fa-bars" onClick={toggleMenu}></div>
          </div>
        </header>
    </Fragment>
    </div>
  );
}
