import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog17Hero from "../../../image/blog_17_hero_miert_a_valasi_mediacio.png"

export default function Blog_17_el_akarok_valni() {
  return (
    <div className="mf-4305" id="mf-page" data-id="mf-4305">
      <div className="mf-page section section-blog-globale">
        <div className="container mf-blog-globale-container p-0">
          <div className="mf-blog-globale-hero">
            <img src={Blog17Hero} alt="Hogyan_tanuld_meg_szeretni_onmagad"/>
          </div>
    

          <div className="mf-blog-globale-face">
            <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
              <h2 className="mf-blog-globale-heading-title">"El akarok válni" - Miért a válási mediáció a legjobb döntés?</h2>
            </div>

            <div className="mf-blog-globale-profile-container">
              <div className="mf-blog-globale-profile">
                <div className="mf-blog-globale-img-container"></div>   
    

                <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                  <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                  <p className="mf-blog-globale-text-description">2024. április 11.</p>
                </div>
              </div>
        
              <div>
              <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
              </div>
            </div>
          </div>



          <div className="mf-blog-globale-column">

                <div className="mf-blog-globale-text">
                      <p>
                            Íme, öt olyan érv, ami meggyőzheti Önt arról, hogy a válási mediáció a legjobb választás válás esetén.
                      </p>

                      <h6>
                            1. Költséghatékony
                      </h6>

                      <p>
                            Miért ne próbálna meg spórolni! Senki sem szeretne egy hosszan elhúzódó bírósági procedúrát, ami sok pénzbe kerül és akár hónapokig vagy évekig is eltarthat. Emellett még, sokkal nagyobb fokú kontrolt biztosít a végeredmény felett, mint a bírósági tárgyalás.
                      </p>

                      <h6>
                            2. Célravezető
                      </h6>

                      <p>
                            A kapcsolatfelvételt követően 1-2 hónap alatt le lehet zárni a mediációs folyamatot. A bontóper nem húzódik el, ha a mediációs folyamat során sikerül válási megállapodást készíteni, ami a keresetlevél mellékleteként benyújtásra kerül.
                      </p>

                      <h6>
                            3. A legjobb megoldás a gyerekek szempontjából
                      </h6>

                      <p>
                            A gyerekek mindenek felett álló érdeke az, hogy mindkét szülővel a válást követően is jó kapcsolatot ápoljon. A mediáció során (ügyvédek helyett) a két szülőnek kell kommunikálni egymással, ami lehetőséget teremt arra, hogy önmagukért kiállva a gyermekeikre összpontosítva hozzanak döntéseket.  A válási mediáció folyamata segíthet abban, hogy a gyerekek érdekében konszenzusra jussanak és a válás után is együttműködő kapcsolatot tartsanak fenn a közös szülői nevelés érdekében, megóvva a gyermekeiket egy vitás válás káros hatásaitól.
                      </p>

                      <h6>
                            4. Neutrális
                      </h6>

                      <p>
                            A mediátor semleges, nem képviseli egyik felet sem, nem tesz igazságot és nem keres felelősöket.
                      </p>

                      <h6>
                            5. Erőt ad 
                      </h6>

                      <p>
                            A felek a mediációs folyamatban magukért állnak ki, ami elősegíti az egymás közötti jobb kommunikációt, és segít ötleteket alkotni a megoldandó problémákkal kapcsolatban. A megoldás megtalálásának az aktív szereplője és nem a passzív (bíróság általi döntés) elszenvedője lesz.
                      </p>
                      <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>


                </div>
          </div>
          <div className="mf-blog-global-tags">
                <p>Címkék:</p>
                <a href="#">mediáció</a>
                
          </div>
        </div>
      </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>
  )
}
