import React from "react";
import { Link } from "react-router-dom";

import "../../../CSS/mf_blog_global.css"

import Blog10Hero from "../../../image/blog_10_hero_jo_kommunikacio.png"

export default function Blog_10_a_jo_kommunikacio() {
  return (
    <div className="mf-4406" id="mf-page" data-id="4406">
    <div className="mf-page section section-blog-globale">
          <div className="container mf-blog-globale-container p-0">
                <div className="mf-blog-globale-hero">
                      <img src={Blog10Hero} alt="A_jo_kommunikacio_5_egyszeru_dologgal_kezdodik"/>
                </div>
          

                <div className="mf-blog-globale-face">
                      <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                            <h2 className="mf-blog-globale-heading-title">A jó kommunikáció 5 egyszerű dologgal kezdődik</h2>
                      </div>

                      <div className="mf-blog-globale-profile-container">
                            <div className="mf-blog-globale-profile">
                                  <div className="mf-blog-globale-img-container"></div>   
                      

                                  <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                        <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                        <p className="mf-blog-globale-text-description">2024. január 23.</p>
                                  </div>
                            </div>
                      
                            <div>
                                  <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                            </div>
                      </div>
                </div>



                <div className="mf-blog-globale-column">

                      <div className="mf-blog-globale-text">
                            <p>
                                  A jó kommunikációs készség elengedhetetlen a mai világban, minden kapcsolat kulcsa, és 5 egyszerű dologgal kezdődik: 
                            </p>

                            <h4>
                                  1. Aktív hallgatás
                            </h4>

                            <p>
                                  Kapcsold ki vagy tedd le a kezedből az elektronikus eszközeidet, nézz a beszélőre és figyelj arra, amit mond.  
                            </p>

                            <h4>
                                  2. Megértő hozzáállás
                            </h4>

                            <p>
                                  Gyakorold az empátiát, próbáld meg beleélni magad a másik fél helyzetébe és érzelmeibe. Fontos megérteni és támogatni a másikat a saját nézőpontjából.
                            </p>

                            <h4>
                                  3. Kifejezőkészség
                            </h4>

                            <p>
                                  Használj tiszta és világos nyelvezetet. Próbáld pontosan kifejezni a gondolataidat, érzéseidet. Kerüld azokat kifejezéseket, amelyek nem relevánsak a másik személy számára.
                            </p>

                            <h4>
                                  4. Visszajelzés
                            </h4>

                            <p>
                                  A kommunikáció során fontos a visszajelzés. Erősítsd meg, hogy értetted a másikat, és kérd meg őt is, hogy erősítse meg, ha ő is megértett téged.
                            </p>

                            <p>
                                  <b>Fontos!</b> Csak akkor adj tanácsot, ha a másik kéri. Vannak, akik önállóan szeretnének megoldást találni.
                            </p>

                            <h4>
                                  5. Türelem
                            </h4>

                            <p>
                                  Légy türelmes a másikkal, különösen, ha érzékeny vagy nehéz témákról van szó. Adj időt a gondolkodásra és válaszadásra. Vedd figyelembe a másik fél aggályait.
                            </p>

                            <p>
                                  A jó kommunikáció másik fontos szempontja a testbeszéd. Érdemes figyelni a kommunikáció alatt az arckifejezésre, a gesztusokra, a testtartásra, a hanghordozásra, mivel ezek sokat elárulhatnak a másik érzéseiről és gondolatairól. A nonverbális jelzések éppen ugyanolyan fontosak, mint a verbális kifejezések. 
                            </p>

                            <p>
                                  <i>Ha jobb kommunikációra vágysz, próbáld ki ezt az 5 egyszerű dolgot!</i>
                            </p>

                            <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                      </div>

                      <div className="mf-blog-global-tags">
                            <p>Címkék:</p>
                            <a href="#">Kommunikáció</a>
                            
                      </div>
                </div>
          </div>
    </div>
    {/*Back to Top start*/}
    <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
</div>

  )
}
