import React from "react";
import { Link } from "react-router-dom";

import "../../CSS/valasi_mediacio.css"


import MediacioDivorce from "../../image/mf_valasi_med_1.png"

export default function Valasi_mediacio() {
  return (
    <div className="mf-3940"  id="mf-page" data-id="3940">
      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-23m51f87" data-id="23m51f87">

            
            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-about-widget">
                                    <div className="mediation-title-10m32f57 w-1" data-id="10m32f57">
                                          <h1 className="mf-heading-title">Válási mediáció</h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>
      {/*hero section ends*/}

      {/*divorce first section start*/}
      <div className="mf-page section mf-dmediation-section" data-id="">
            <div className="container mf-dmediation-container p-0">
                  <div className="mf-dmediation-row">

                        <div className="mf-dmediation-info padd-20">
                              <div className="mf-widget-text-description mf-dmediation-text-04m06f54" data-id="04m06f54">
                                    <p className="mf-text-description">
                                          Ha ezt az oldalt olvassa, akkor valószínűleg el akar válni. Bármilyen ijesztő és fájdalmas is, mostanra már eldöntötte, hogy ez a legjobb döntés Ön és gyermeke(i) számára.
                                    </p>
                              </div> 
                              <div className="mf-widget-text-description mf-dmediation-text-04m09f70" data-id="04m06f54">
                                    <p className="mf-text-description">
                                          A kérdés már csak az, hogy hogyan?
                                    </p>
                              </div>
                              <div className="mf-widget-text-description mf-dmediation-text-04m10f43" data-id="04m06f54">
                                    <p className="mf-text-description">
                                          A pereskedés drága, időigényes, érzelmileg kimerítő és kiszámíthatatlan. Amíg egy bíró nem hoz döntést az ügyben addig nem lehet biztos az eredményben sem. A válásnak nem kell hosszú, zűrzavaros eljárásnak lennie, egy válófélben lévő házaspár elkerülheti a hosszú hónapokon át tartó bírósági pereskedést, ha egy <b>mediátorral</b> dolgozik, aki segít nekik megegyezni a felmerülő kérdésekben.
                                    </p>
                              </div>   
                        </div>

                        <div className="mf-dmediation-img padd-20">
                              <img src={MediacioDivorce} alt=""/>
                        </div>
                  </div>
            </div>
      </div>
      {/*divorce first section ends*/}

      {/*divorce second section starts*/}
      <div className="mf-page section mf-dmediation1-section2" data-id="">
            <div className="container mf-dmediation1-container p-0">
                  <div className="mf-dmediation-text-block">
                        <div className="mf-widget-heading-title mf-dmediation-title-21m58f95 w-1" data-id="21m58f95">
                              <h3 className="mf-heading-title">A válási mediáció előnyei:</h3>
                        </div>
                        <div className="mf-dmediation-content-text">
                              <ul className="mf-dmediation-list">
                                    <li className="dmediation-has-before">ez a leghatékonyabb és legköltséghatékonyabb módja  a nem vitás válások rendezésének, amikor mindkét fél egyetért abban, hogy a házasságuk véget ért;</li>
                                    <li className="dmediation-has-before">lehetővé teszi azt, hogy Ön és partnere továbbra is kézben tartsa a saját válását és ne legyen kiszolgáltatva  egy hosszan elhúzódó jogi procedúrának;</li>
                                    <li className="dmediation-has-before">a közösen hozott döntések alapján létrejött megállapodás tartósabb és betarthatóbb lesz.</li>   
                              </ul>
                        </div>
                        <div className="mf-widget-text-description mf-dmediation-text-25m67f30" data-id="25m67f30">
                              <p className="mf-text-description">
                                    Röviden: a válási mediáció segít abban, hogy  megőrizze saját élete feletti kontrollját.
                              </p>
                        </div> 
                  </div>
            </div>
      </div>
      {/*divorce second section ends*/}

      {/*divorce 3 section start*/}
      <div className="mf-page section mf-dmediation2-section3" data-id="">
            <div className="container mf-dmediation2-container p-0">
                  <div className="mf-dmediation2-heading-box">
                        
                        <div className="mf-widget-heading-title mf-dmediation-title-43m69f57 w-1" data-id="43m69f57">
                              <h1 className="mf-heading-title">Miért különösen hasznos a válási mediáció ha gyermek is érintett?</h1>
                        </div>
                        <div className="mf-widget-text-description mf-dmediation-text-60m50f25" data-id="60m50f25">
                              <p className="mf-text-description">
                                    Ha a válófélben lévő párnak kiskorú gyereke van, akkor a válás után, életük hátralévő részében továbbra is szülőtársak maradnak, akik a gyermekeik miatt kapcsolatban fognak állni egymással. Szükségük lesz arra, hogy kialakítsanak egy, korábbiaktól eltérő viszonyulási módot, amire a válási mediáció lehetőséget adhat. 
                              </p>
                        </div> 
                  </div>  
            </div>
      </div>
      {/*divorce 3 section ends*/}

      {/*divorce 4 section start*/}
      <div className="mf-page section mf-dmediation3-section4" data-id="">
            <div className="container mf-dmediation3-container p-0">
                  <div className="mf-dmediation2-text-block">
                        <div className="mf-widget-heading-title mf-dmediation-title-37m86f59 w-1" data-id="37m86f59">
                              <h3 className="mf-heading-title">Előnyei:</h3>
                        </div>
                        <div className="mf-dmediation2-content-text">
                              <ul className="mf-dmediation-list2">
                                    <li className="dmediation2-has-before">a gyerekeknek jót tesz, ha azt látják, hogy a szülők együtt tudnak dolgozni,</li>
                                    <li className="dmediation2-has-before">minden résztvevő fél számára optimális eredmény elérésére összpontosít,</li>
                                    <li className="dmediation2-has-before">jó példát mutathat arra vonatkozóan, hogyan oldhatóak meg a jövőbeni felmerülő szülői problémák,</li>
                                    <li className="dmediation2-has-before">segít a gyermekek igényeire és szükségleteire összpontosítani,</li>
                                    <li className="dmediation2-has-before">elősegíti, hogy nyitott maradjon a két szülő közötti kommunikáció,</li>
                                    <li className="dmediation2-has-before">sokkal gyorsabb, kevésbé stresszes és többnyire olcsóbb, mint a jogi procedúra,</li>
                                    <li className="dmediation2-has-before">lehetővé teszi a saját, közösen hozott döntések születését, amelyek növelik a megállapodás betarthatóságát.</li>
                              </ul>
                        </div>
                        
                  </div>
            </div>
      </div>
      {/*divorce 4 section end*/}

      {/*divorce 5 section starts*/}
      <div className="mf-page section mf-dmediation4-section5" data-id="">
            <div className="container mf-dmediation4-container p-0">
                  <div className="mf-dmediation4-heading-box">
                        
                        <div className="mf-widget-heading-title mf-dmediation-title-65m87f35 w-1" data-id="65m87f35">
                              <h2 className="mf-heading-title">Egy bevált eljárást követve elérheti a megállapodást</h2>
                        </div>
                        <div className="mf-widget-text-description mf-dmediation-text-43m98f10" data-id="43m98f10">
                              <p className="mf-text-description">
                                    A <b>Válási Mediációs Megállapodás</b> egy olyan dokumentum, amely tartalmazni fogja azokat az elemeket, amelyek a Polgári Törvénykönyv szerint a házasság közös megegyezéssel történő  felbontásához szükségesek (szülői felügyeleti jog, kapcsolattartás, tartásdíj, lakáshasználat).
                              </p>
                        </div> 
                        <div className="mf-widget-text-description mf-dmediation-text-43m98f10" data-id="43m98f10">
                              <p className="mf-text-description">
                                    A <b>Mediációs Megállapodás</b> benyújtása a keresetlevél mellékleteként,  lehetővé teszi, hogy a bontóper ne húzódjon hónapokon vagy éveken keresztül.
                              </p>
                        </div> 
                  </div>  
            </div>
      </div>
      {/*divorce 5 section ends*/}

      {/*divorce 6 section starts*/}
      <div className="mf-page section mf-dmediation5-section6" data-id="">
            <div className="container mf-dmediation5-container p-0">
                  <div className="mf-dmediation5-text-block">
                        <div className="mf-widget-heading-title mf-dmediation-title-29m75f46 w-1" data-id="21m58f95">
                              <h3 className="mf-heading-title">A mediációs szolgáltatás szakaszai</h3>
                        </div>
                        <div className="mf-dmediation5-content-text">
                              <ul className="mf-dmediation5-list3">
                                    <li className="dmediation5-has-before"><b>Információ-kapcsolatfelvétel:</b> Az <i>első 20 perces konzultáció ingyenes</i> - Ön dönti el, hogy telefonon beszélünk vagy online találkozunk. Ha inkább személyesen szeretne találkozni, akkor méltányos díj ellenében kérhet személyes találkozót is. A beszélgetés természetesen bizalmas lesz. Ennek a konzultációnak a célja, hogy általános tájékoztatást nyújtson a mediációról és hogy segítsen meghatározni a lehetőségeket és a lehetséges következő lépéseket. Ez megtehető együtt vagy a másik féltől függetlenül.</li>
                                    <li className="dmediation5-has-before"><b>Első szakasz - előkészítés:</b> 60 perces egyéni megbeszélés, amely segíti a közös mediációs ülésre való felkészülést.</li>
                                    <li className="dmediation5-has-before"><b>Második szakasz - közös mediációs ülés:</b> A két órás ülés alatt a résztvevő felek megosztják egymással az információikat, és azon fognak a mediátorral együtt konstruktív módon dolgozni, hogy az összes vitás kérdésre megoldást találjanak.</li>
                                    
                              </ul>
                        </div>
                        
                  </div>
            </div>
      </div>
      {/*divorce 6 section ends*/}

      {/*divorce 7section starts*/}
      <div className="mf-page section mf-mediation-info-section" data-id="">
            <div className="container mf-mediation-info-container p-0">
                  <div className="mf-mediation-info-box">
                        <h2 className="mediation-info-title">További információért töltse le a válási mediációs útmutatót</h2>
                  </div>  
                  <div className="mf-mediation-info-btnBx">
                  <Link to="https://gemeterzsuzsa.hu/static/media/utmutato_a_valasi_mediaciohoz.pdf"><div className="mf-mediation-info-btn btn" >Válási mediációs útmutató</div></Link>
                  </div>
            </div>
            
      </div>
      {/*divorce 7 section ends*/}

      {/*contact section starts */}
      <div className="mf-page section mf-mediation-contact-section-27m10f36" data-id="27m10f36">

            <div className="container mf-mediation-contact-container p-0">
                  <div className="mf-mediation-contact-content">
                        <div className="mf-mediation-info-content">
                              <div className="mf-widget-heading-title mediation-info-title-26e5f30c" data-id="26e5f30c">
                                    <h1 className="mf-heading-title">Készen áll konfliktusai megoldására?</h1>
                              </div>
                              <div className="mf-widget-text-description mediation-info-text-22a7b451" data-id="22a7b451">
                                    <p className="mf-text-description">Tegye meg az első lépést!</p>
                              </div>
                              <div className="mf-widget-text-description mediation-info-text-32m10f63" data-id="32m10f63">
                                    <p className="mf-text-description">Kötelezettség nélkül, 20 perces ingyenes konzultációs lehetőséget biztosítok Önnek, hogy beszéljünk a helyzetéről.</p>
                              </div>
                              <div>
                              <Link to="/Kapcsolat" className="mf-mediation-contact-btn btn">Kérjen időpontot ingyenes konzultációra</Link>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
      {/*contact section ends*/}

      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

      </div>

  )
}
