import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../CSS/gyik.css"

export default function Gyik_tanacsadas() {
  
      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };

  
      return (

      <div className="mf-3553"  id="mf-page" data-id="3553">
        {/*hero section starts*/}
        <div className="mf-page section faq-section-img-bg-3m4f7587" data-id="3m4f7587">
  
              
              <div className="container mf-faq-container p-0">
                    
                    <div className="mf-faq-column-wrap mf-col-100">
                          <div className="mf-faq-widget-container">
                                <div className="mf-widget">
                                      <div className="mf-widget-heading-title faq-title-7e1f8217 w-1" data-id="7e1f8217">
                                            <h1 className="mf-heading-title">Gyakran ismételt kérdések<span>konzultáció / tanácsadás</span></h1> 
                                      </div>
                                      
                                </div>
                          </div>   
                    </div>
              </div>
        </div>
        {/*hero section ends*/}
  
        {/*faq first section starts*/}
        <div className="mf-page section mf-faqs-section" data-id="">
              <div className="container mf-faqs-container p-0">
                    <div className="mf-faqs-box">
                          <div className="mf-widget-text-description faqs-text-69b32e91 w-1" data-id="69b32e91">
                                <p className="mf-text-description">Összegyűjtöttem a konzultációval / tanácsadással kapcsolatos leggyakrabban feltett kérdéseket. 
                                      Ha kérdésére nem kapott választ, kérem <Link to="/Kapcsolat">forduljon hozzám</Link> bizalommal, minden kérdésére szívesen válaszolok.</p>
                          </div>
                    </div>
              </div>
        </div>
        {/*faq first section ends*/}
  
  
        {/*FAQ toggle section starts*/}
        <div className="mf-page section faq-toggle-section-62m5f296" data-id="62m5f296">
  
              <div className="container mf-faq-toggle-container p-0">
  
                    <div className="accordion">
                          <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(1)}>Valójában konzultációra/tanácsadásra van szükségem? Nem olyan, mintha egy baráttal beszélgetnék?</div>
                                {openState[1] && (
                        <div className="content">
                                      <p>Úgy gondolom, hogy életünk során folyamatosan változunk, változnak a körülményeink. Az ezek során átélt érzelmek akár a  karrierünket, a kapcsolatainkat és az általános jólétünket is befolyásolják.  Ilyenkor mindannyiunknak hasznára válik a támogatás az empátia és a meghallgatás.</p>
                                      <p>A velem való beszélgetés lehetőséget teremt arra, hogy a nehéz helyzeteket olyan valakivel dolgozza fel, aki rendelkezik ehhez, a megfelelő képzettséggel és tapasztalattal. Célom, egy együttműködésen alapuló olyan biztonságos és bizalmas környezet biztosítása, amely segíti az Ön növekedését és fejlődését.</p>
                                      <p>Ezen kívül, ami megkülönbözteti a konzultációt a baráttal/családtaggal való beszélgetéstől, az az, hogy üléseink teljesen bizalmasak. Nem kell attól tartania, hogy személyes adatai az Ön beleegyezése vagy tudta nélkül megosztásra kerülnek.</p>
                                      <p>Az együttműködés minden tartalma titoktartási kötelezettség alá esik.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(2)}>Akkor is részt vehetek konzultáción/tanácsadáson ha a párom/családom(egyelőre) nem akar részt venni rajta?</div>
                                {openState[2] && (
                        <div className="content">
                                      <p>Igen, ez lehetséges. Teljesen érthető, ha párjának kezdetben nehézségei vannak egy idegen harmadik személlyel szemben vagy esetleg (még) nem érzékeli azokat a nehézségeket, amiket Ön lát. Ebben a helyzetben is érdemes önállóan is konzultációra jönni, hiszen a jelenlegi helyzettől szenved és szeretne  változtatni rajta. A konzultáció lehetőséget ad saját gondolatai, érzései tisztázásához. </p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(3)}>Mennyi idővel korábban kell jelentkezni a konzultációra?</div>
                                {openState[3] && (
                        <div className="content">
                                      <p>Csak hívjon, és személyesen megbeszéljük, hogy melyik konzultáció/tanácsadás megfelelő az Ön számára és azonnal időpontot egyeztetünk.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(4)}>Mennyi ideig tart egy ülés?</div>
                                {openState[4] && (
                        <div className="content">
                                      <p>Ez a konzultáció típusától függően változik:</p>
                                      <ul className="accordion-list">
                                            <li className="accordion-has-before">Az egyéni konzultáció és coaching ülések 60 percesek.</li>
                                            <li className="accordion-has-before">A pár- vagy családkonzultáció 90 percesek.</li>  
                                      </ul>
                                      <p>Részletes információ az ülésekről és az árakról az Árak menüpont alatt található.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[5] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(5)}>Hogyan működik a folyamat?</div>
                                {openState[5] && (
                        <div className="content">
                                      <p>Kezdésként időpontot egyeztetünk egy  találkozóhoz, hogy személyesen megismerjük egymást. Ezután szabadon eldöntheti, hogy a munkamódszerem és a stílusom megfelel-e az Ön számára és szeretne-e velem együtt dolgozni.</p>
                                      <p>Kezdetben a találkozókra kb. 2 hetente kerül sor. Erre azért van szükség, hogy legyen ideje kipróbálni a megbeszélteket a mindennapokban. Amikor a kívánt változások megtörténtek, a további együttműködés arról szól, hogy a változásokat beépítse az életébe. Aztán a találkozókra olyan gyakran kerül sor ahogy azt Ön megfelelőnek tartja (kb. 4-8 hetente).</p>
                                      <p>Akut krízishelyzetekben, azaz hirtelen, traumatikus eseményeknél a fentiektől gyakrabban kerül sor a találkozókra.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[6] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(6)}>Hány ülésre van szükség?</div>
                                {openState[6] && (
                        <div className="content">
                                      <p>Hogy meddig tart az együttműködés azt nem lehet előre megmondani. Ez az Ön személyes helyzetétől és céljaitól függ. A konzultáció/coaching nem az a projekt, ahol meg lehet tervezni az eredményt. Ez a fejlődésről szól, ami kiszámíthatatlan. Így Ön határozza meg meg az igényeit és bármikor befejezheti a foglalkozásokat. </p>
                                      <p>A tapasztalat azt mutatja, hogy általában 5-8 alkalom után képes lesz más perspektívából nézni a helyzetre, még akkor is ha nem oldódott meg minden nehézség.</p>
                                      <p>Pár- és családkonzultációban a kapcsolatok és a kérdések gyakran nagyon összetettek és időbe telik, hogy megértsük az összefüggéseket és a dinamikát mielőtt megváltoztatnánk azokat.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[7] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(7)}>Hány éves kortól jöhetnek gyerekek?</div>
                                {openState[7] && (
                        <div className="content">
                                      <p>Gyerekek csak akkor jöhetnek, ha az előzetesen meg lett beszélve.  Családkonzultáció folyamatában általában  a gyerekek is  részt vesznek életkortól függetlenül. Azonban ezen alkalmakkor is előfordulhatnak olyan foglalkozások, amelyekre gyerekek nélkül kerül sor, például amikor párkapcsolati kérdések kerülnek megvitatására.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[8] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(8)}>Hogyan segít a konzultáció/tanácsadás?</div>
                                {openState[8] && (
                        <div className="content">
                                      <p>A konzultáció/coaching sokféleképpen segíthet!</p>
                                      <ul className="accordion-list">
                                            <li className="accordion-has-before">A személyes célok és értékek azonosításában.</li>
                                            <li className="accordion-has-before">Önbecsülés, önértékelés növelésében.</li>
                                            <li className="accordion-has-before">Új és egészséges megküzdési stratégiák kidolgozásában.</li>
                                            <li className="accordion-has-before">A stressz és a szorongás hatékonyabb kezelésében.</li>
                                            <li className="accordion-has-before">A kommunikációs készségek fejlesztésében.</li>
                                            <li className="accordion-has-before">Kapcsolati nehézségek (család, barátok, kollégák) megoldásában.</li>
                                      </ul>
                                      <p>A konzultáció/coaching nem akadályozza meg az életében felbukkanó nehézségeket, de megtanítja  átlendülni rajtuk.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[9] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(9)}>Mikor van értelme a párkapcsolati tanácsadásnak?</div>
                                {openState[9] && (
                        <div className="content">
                                      <p>Amikor párként olyan akadályokkal néz szembe, amelyek az Ön szempontjából leküzdhetetlennek tűnnek vagy boldogtalannak érzi magát a kapcsolatában. Gyakran a negatív érzések felülkerekednek a sok pozitív érzés felett, ilyenkor segíthetek hogy visszataláljanak egy boldog együttléthez.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[10] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(10)}>Hogyan működik az online konzultáció/tanácsadás?</div>
                                {openState[10] && (
                        <div className="content">
                                      <p>Olyan embereknek szól, akik Budapesten kívül élnek, akár Magyarország többi részén, akár külföldön. A szokásos konzultációhoz hasonlóan először időpontot egyeztetünk telefonon vagy e-mailen és ezt követően csak internet kapcsolatra, webkamerára és üzenetküldő rendszerre (MS-Team Zoom, Google Meet) lesz szüksége.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[11] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(11)}>A konzultáció/tanácsadás helyettesítheti-e a pszichoterápiát?</div>
                                {openState[11] && (
                        <div className="content">
                                      <p>Nem. Ideiglenes hídként szolgálhat, ha megfelelő pszichoterapeutát keres vagy még várólistán van.</p>
                                      <p>Munkám középpontjában az Ön interperszonális kapcsolatai állnak, ezért a megoldásközpontú módszerrel nem lehetséges a mentális betegségek diagnosztizálása és kezelése sem.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[12] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(12)}>Mi történik, ha nem tudok részt venni egy találkozón?</div>
                                {openState[12] && (
                        <div className="content">
                                      <p>Mindenkinek közbejöhet valami. Amennyiben a megbeszélt időpontban nem tud részt venni a foglalkozáson, kérem, legalább 24 órával korábban mondja le azt telefonon vagy e-mailben. Ellenkező esetben lemondási díj kerül felszámításra, amely a teljes díj 100%-a.</p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[13] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(13)}>Felvehetem-e a munkamenetet okostelefonnal/tablettel vagy a videoüléseket rögzíthetem-e?</div>
                                {openState[13] && (
                        <div className="content">
                                      <p>Nem. Ezt törvény tiltja! Digitálisan sem rögzíthető semmi. A felvételek  minden résztvevő személyiségi jogát érintik, ezért kérem tartózkodjon ettől, és a foglalkozások alatt kapcsolja ki a készülékét. Utána írásos jegyzetet és fényképet készíthet. </p>
                                </div>
                                )}
                          </div>
  
                          <div className={`contentBx ${openState[14] ? 'active' : ''}`}>
                  <div className="label" onClick={() => toggleAccordion(14)}>Milyen fizetési lehetőségeim vannak?</div>
                                {openState[14] && (
                        <div className="content">
                                      <p>Fizethet készpénzzel illetve banki átutalással. Minden szükséges részletet az Árak menüpont alatt megtalál.</p>
                                </div>
                                )}
                          </div>
                    </div>
  
              </div>
        </div>
        {/*FAQ toggle section ends*/}
  
  
        {/*Home-contact section starts */}
        <div className="mf-page section faq-contact-section" data-id="3872">
  
              <div className="mf-container mf-faq-contact-container">
                    <div className="mf-faq-contact-content-container center">
                          <div className="mf-faq-contact-content">
                                <div className="mf-widget-heading-title faq-contact-title-26e5f30c" data-id="26e5f30c">
                                      <h1 className="mf-heading-title">Kérdése van?</h1>
                                </div>
                                <div className="mf-widget-text-description faq-contact-text-22a7b451" data-id="22a7b451">
                                      <p className="mf-text-description">Küldjön üzenetet!</p>
                                </div>
                                <div>
                                <Link to="/Kapcsolat" className="mf-faq-contact-btn btn">Lépjen kapcsolatba velem</Link>
                                </div>
                          </div>
                    </div>
              </div>
        </div>
        {/*home-contact section ends*/}
  
  
        {/*Back to Top start*/}
        <a href="#" className="back-to-top">
              <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
        </a>
        {/*Back to Top end*/}
  
  
  </div>
  )
}
