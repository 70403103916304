import React from "react";
import"../../CSS/mf_about.css"

import AboutPic from "../../image/gemeter_zsuzsa_about.png"

export default function Rolam() {
  return (
    <div>
    



<div className="mf-3843"  id="mf-page" data-id="3843">
{/*Hero section starts*/}
<div className="mf-page section section-img-bg-2m2f2458" data-id="2m2f2458">

      
      <div className="container mf-hero-container p-0">
            
            <div className="mf-column-wrap mf-col-100">
                  <div className="mf-widget-container">
                        <div className="mf-about-widget">
                              <div className="about-title-50b2c440 w-1" data-id="50b2c440">
                                    <h1 className="mf-heading-title">Rólam</h1> 
                              </div>
                        </div>
                  </div>
            </div>
      </div> 

      <div className="container mf-container-1 p-0">
            
            <div className="mf-about-quote-container">
                  <div className="about-quote-box">
                        <blockquote>
                              <h6>"Az élet értelme, hogy megtaláld a képességeidet.
                              <br/> Az élet célja, hogy ezt mások szolgálatába állítsd."
                              </h6>
                        </blockquote>
                  <p>
                        /Paolo Picasso/
                  </p>
                  </div>
            </div>
      </div>
      
</div>
{/*Hero First section ends*/}



{/*About-section-profile starts*/}
<div className="mf-page section mf-section-about" data-id="">

      <div className="container mf-about-container">{/*rozsaszin*/}

            <div className="mf-about-profile-continer">{/*feher*/}

                  

                  <div className="mf-about-column col-left">
                        <div className="mf-about-title-3b1f2157" data-id="3b1f2157">
                              <h2 className="mf-heading-title">Gemeter Zsuzsa vagyok,</h2>
                        </div>

                        <div className="mf-about-text-8e4f6371" data-id="8e4f6371">
                              <p className="mf-text-description">óvodapedagógusi végzettségem megszerzését követően 
                                    a Pécsi Tudományegyetemen szociálpolitikai tanulmányokat folytattam. Már a képzésem 
                                    alatt és az azt követő  20 évben is szociális területen, segítő szakemberként dolgoztam.  
                                    Családsegítés, gyermekvédelem és az átmeneti ellátások területén szereztem átfogó szakmai ismereteket. 
                                    Ezek mellett tapasztalatot szereztem főiskolai hallgatókkal való együttműködésben, 
                                    amikor tereptanárként segítettem gyakorlati felkészülésüket.
                              </p> 
                        </div>
                        <div className="mf-about-text-8e4f6371" data-id="8e4f6371">
                              <p className="mf-text-description">
                                    Nyolc évvel ezelőtt úgy éreztem, hogy tudásomat magasabb szintre kell emelnem és ezért a 
                                    WJLF –  családkonzulens/családterapeuta  szakirányú képzésén vettem részt. 
                                    A családkonzulensi két éves képzésem  mellett  kiegészítő 
                                    szakmai továbbképzésekkel is gazdagítottam ismereteimet, hogy a lehető legjobban támogathassam 
                                    és kísérjem azokat az embereket, akik elköteleződnek és részt vesznek a konzultáció vagy coaching folyamatában. 
                              </p>
                        </div>
                        
                        <div className="mf-about-text-8e4f6371" data-id="8e4f6371">
                              <p className="mf-text-description">
                                    Elkötelezett szakembernek tartom magam, aki rajong a munkájáért. 
                                    Az élet és azok az emberek akikkel munkám során találkoztam folyamatosan 
                                    gazdagítottak engem. Kiváló tanáraim és mentoraim voltak, akik támogattak és 
                                    bátorítottak a szakmai fejlődésem útján. Mély tiszteletet érzek minden olyan ember iránt, aki fejlődni szeretne.
                              </p>
                        </div>
                  </div>

                  <div className="mf-about-column col-right">
                        <div className="mf-about-profile">
                              <img src={AboutPic}/>
                        </div>
                  </div>
                  

            </div>
      </div>
</div>
{/*About-section-profile ends*/}


{/*About-section-glass starts*/}
<div className="mf-page section about-section-img-bg" data-id="">

      <div className="container mf-about-blue-container p-0">
            <div className="mf-about-card-glass">
                  <div className="mf-about-glass-text">
                        <h2>
                             Amit nyújtani tudok 
                        </h2>

                        <h3>Az Ön igényeinek megfelelő terápia.</h3>

                        <p>
                              Nyílt, őszinte kommunikáción keresztül fogok 
                              Önnel dolgozni, hogy megtaláljam a módját annak, 
                              hogy a legjobbat hozhassa ki az életéből. 
                              Olyan támogató tér létrehozása a célom, 
                              ahol magabiztosnak érezheti magát, hogy kipróbáljon 
                              új stratégiákat érzelmei szabályozására és kommunikációs 
                              készségeinek fejlesztésére.
                        </p>

                        <p>
                              Az Önnel való kapcsolatomat a semlegesség, pártatlanság, 
                              őszinte érdeklődés, támogatás, de a következetesség és a kitartás 
                              fogja jellemezni. Mindenki a saját történetével érkezik a konzultációra, 
                              és mivel saját élete szakértőjeként tisztelem, ezért nem nehezedik nyomás Önre, 
                              hogy megfeleljen bizonyos elképzeléseknek, elvárásoknak.
                        </p>
                  </div>
            </div>

      </div>
</div>
{/*About-section-glass ends*/}




{/*About-section-kepzes starts*/}
<div className="mf-page section about-section-color-bg" data-id="">
      <div className="container mf-about-white-container p-0">
            <div className="mf-about-education-container">
                  <div className="mf-about-education-text">
                        <h3>Tanulmányok & Továbbképzések</h3>
                  </div>
            </div>
      </div>

      <div className="container mf-about-white-container-1 p-0">
            <div className="mf-about-timeline-container">
                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">2023</h5>
                              <p className="mf-timeline-card-text"><b>Mediátor</b><br/>Partners Hungary Egyesület</p>
                        </div>
                  </div>

                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">2021</h5>
                              <p className="mf-timeline-card-text"><b>Mozaikcsaládok a segítő munkában</b><br/>Hogyan dolgozzunk mozaikcsaládokkal</p>
                        </div>
                  </div>

                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">2014 - 2016</h5>
                              <p className="mf-timeline-card-text"><b>Családkonzulens</b><br/>Wesley János Lelkészképző Főiskola</p>
                        </div>
                  </div>

                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">2016</h5>
                              <p className="mf-timeline-card-text"><b>Trauma tréning</b><br/><br/></p>
                        </div>
                  </div>

                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">2000 - 2004</h5>
                              <p className="mf-timeline-card-text"><b>Okleveles Szociálpolitikus</b><br/>Pécsi Tudományegyetem</p>
                        </div>
                  </div>

                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">2014</h5>
                              <p className="mf-timeline-card-text"><b>Gyászoló családok</b><br/>A terápia lehetőségei a gyászolók segítésében</p>
                        </div>
                  </div>

                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">1995 - 1998</h5>
                              <p className="mf-timeline-card-text"><b>Óvodapedagógus</b><br/>Budapesti Tanítóképző Főiskola</p>
                        </div>
                  </div>

                  <div className="mf-timeline-card-container">
                        <div className="mf-timeline-card">
                              <h5 className="mf-timeline-card-title">2014-</h5>
                              <p className="mf-timeline-card-text"><b>Magyar Családterápiás Egyesület</b><br/>tagja</p>
                        </div>
                  </div>
            </div>

      </div>


</div>
{/*About-section-kepzes ends*/}




{/*Back to Top start*/}
<a href="#" className="back-to-top">
      <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
</a>
{/*Back to Top end*/}


</div>
    </div>
  )
}
