import React, { useState } from "react";
import { Link } from "react-router-dom";

import "../../CSS/csaladkonzultacio.css"

import CsaladKonz from "../../image/csaladkonzultacio-1.png"

export default function Csaladkonzultacio() {

      const [openState, setOpenState] = useState({});

      const toggleAccordion = (index) => {
      setOpenState(prevState => ({
            ...Object.keys(prevState).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [index]: !prevState[index]
      }));
      };

  return (
    <div className="mf-3674"  id="mf-page" data-id="3674">

      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-20m78f53" data-id="20m78f53">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-hero-widget">
                                    <div className="mf-widget-heading-title hero-title-75m74f96 w-1" data-id="75m74f96">
                                          <h1 className="mf-heading-title">Családkonzultáció<span>személyesen és online</span></h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 

            <div className="mf-adult1-container p-0">
                  
                  <div className="mf-adult-quote-container">
                        <div className="adult-quote-box">
                              <blockquote>
                                    <h6></h6>
                              </blockquote>
                        <p></p>
                        </div>
                  </div>
            </div>


      </div>
      {/*hero section ends*/}

      {/*Family first section starts*/}
      <div className="mf-page section mf-family-section" data-id="">
            <div className="container mf-family-container p-0">

                  <div className="mf-family-text-block">
                        <div className="mf-widget-text-description mf-family-text-19m42f84" data-id="19m42f84">
                              <p className="mf-text-description">
                                    A családkonzultáció egy kezelési módszer, amely megpróbálja megoldani azokat a konfliktusokat, 
                                    nehézségeket amelyeken egy család keresztül megy. Arra szolgál, hogy a családtagok kifejezzék érzéseiket 
                                    a problémájukkal kapcsolatban és megpróbáljanak egyezségre jutni megértve egymást.  
                                    A családkonzultáció különlegessége abban áll, hogy minden családtag szempontjait és igényeit figyelembe veszi.  
                                    Fő célja, hogy a családban megnyilvánuló destruktív viselkedést, kommunikációt építő jellegűvé változtassa.
                              </p>
                        </div>
                  </div>
            </div>
      </div>
      {/*Family first section ends*/}

      {/*Family second section starts*/}
      <div className="mf-page section mf-family1-section2" data-id="">
            <div className="container mf-family1-container p-0">
                  <div className="mf-adult-text-block">
                        <div className="mf-widget-heading-title mf-adult-title-53m87f66 w-1" data-id="53m87f66">
                              <h3 className="mf-heading-title">Mikor javasolt a családkonzultáció?</h3>
                        </div>
                        <div className="mf-family-content-text">
                              <ul className="mf-family-list">
                                    <li className="family-has-before">Amikor nehézségek merülnek fel vagy hiányosságok mutatkoznak a családi kommunikációban.</li>
                                    <li className="family-has-before">Ha nehézségek merülnek fel a családi kapcsolatokban – szülők és gyerekek vagy testvérek között – az élet bármely szakaszában: gyermekkorban, serdülőkorban, amikor a gyermekek elhagyják az otthonukat vagy amikor a szülők idősek lesznek.</li>
                                    <li className="family-has-before">Ha trauma vagy komoly változás történt ami az egész családot érinti, például új házba költözés, új országba költözés, családtag váratlan elvesztése.</li>
                                    <li className="family-has-before">Amikor a szülők aktívan közösen rész akarnak venni a gyermek- vagy serdülőkorban felmerülő nehézségek leküzdésében: problémás viselkedés, szokások, otthoni szabályok, féltékenység vagy rivalizálás a testvérek között, szorongás, félelmek, lázadás, feszült családi légkör stb.</li>
                                    <li className="family-has-before">Amikor a szülők nem tudnak „csapatként” működni, és ez negatívan befolyásolja a családi kapcsolatok légkörét.</li>
                                    <li className="family-has-before">Amikor gyász, betegség, fogyatékosság, szenvedélybetegség (alkohol, kábítószer stb.) által meggyengül a családi rendszer.</li>
                                    <li className="family-has-before">A válás vagy válás után, hogy a gyerek/gyermekek számára a legjobb megoldás szülessen.</li>
                                    <li className="family-has-before">Mozaikcsalád együttélése során felmerülő nehézségek esetén .</li>
                              </ul>
                        </div>
                  </div>
            </div>
      </div>
      {/*Family second section ends*/}

      {/*Family-3 section starts*/}
      <div className="mf-page section mf-family2-section3" data-id="">
            <div className="container mf-family2-container p-0">
                  <div className="mf-family-row">

                        <div className="mf-family-info padd-20">
                              <div className="mf-widget-heading-title mf-family-title-46m59f13 w-1" data-id="46m59f13">
                                    <h3 className="mf-heading-title">Hogyan segít a családkonzultáció?</h3>
                              </div>
                              
                              <div className="mf-widget-text-description mf-family-text-36m52f69" data-id="36m52f69">
                                    <p className="mf-text-description">
                                          A családkonzultáció azon az előfeltevésen alapul, hogy a család erős kötelék, értékek, mitoszok, közös történetek, elvárások rendszere, amely minden egyes tagjára hatással van.  A családi rendszereknek meg kell őrizni stabilitásukat, de ugyanakkor képeseknek kell lenniük arra, hogy idővel alkalmazkodjanak a különféle változásokhoz.
                                    </p>
                              </div>

                              <div className="mf-widget-text-description mf-family-text-36m52f70" data-id="36m52f70">
                                    <p className="mf-text-description">
                                          A családkonzultáció elősegíti a családon belüli kommunikációt és lehetőséget ad minden egyes családtagnak arra, hogy kifejezhessék nehézségeiket, megfogalmazhassák észrevételeiket, elmondhassák, hogy mi megy jól a családban és mi az, ami nehéz vagy fájdalmas. A konzultációs tér  lehetővé teszi a szólásszabadságot, a családtagok kipróbálhatnak  új kommunikációs eszközöket, amelyek  lehetővé teszik a családban az érzelmi kapcsolatok erősödését, a krízishelyzetekkel való megküzdést és a közös jövőkép kialakítását.
                                    </p>
                              </div>
                        </div>

                        <div className="mf-family-img padd-20">
                              <img src={CsaladKonz} alt=""/>
                        </div>


                  </div>

            </div>

      </div>
      {/*Family-3 section ends*/}

      {/*Family-4 section starts*/}
      <div className="mf-page section mf-family3-section4" data-id="">
            <div className="container mf-family3-container">

                  <div className="mf-family1-row">

                        <div className="mf-family-info1 padd-20">

                              <div className="mf-widget-heading-title mf-family-title-27m59f24 w-1" data-id="27m59f24">
                                    <h3 className="mf-heading-title">GYIK a családkonzultációról</h3>
                              </div>

                              <div className="accordion">
                              <div className={`contentBx ${openState[1] ? 'active' : ''}`}>
                                    <div className="label2" onClick={() => toggleAccordion(1)}>Mennyi ideig tart a konzultáció?</div>
                                    {openState[1] && (
                                          <div className="content2">
                                                <p>Néha csak néhány alkalomra van szükség ahhoz, hogy a család visszanyerje az egyensúlyát. A helyzettől függően a munka tovább is tarthat. Az első 1-2 alkalom elsősorban információgyűjtésről szól. Ezen ülések alatt megpróbáljuk közösen meghatározni a célokat, amiért érdemes dolgozni.</p>
                                          </div>
                                    )}
                                    </div>


                                    <div className={`contentBx ${openState[2] ? 'active' : ''}`}>
                                    <div className="label2" onClick={() => toggleAccordion(2)}>Milyen gyakoriak az ülések?</div>
                                    {openState[2] && (
                                          <div className="content2">
                                                <p>Az ülések gyakorisága általában 2 hetente egy alkalom, de krízis idején hamarabb is sor kerülhet találkozóra.</p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[3] ? 'active' : ''}`}>
                                    <div className="label2" onClick={() => toggleAccordion(3)}>Mennyibe kerül a családkonzultáció?</div>
                                    {openState[3] && (
                                          <div className="content2">
                                                <p>A <b>90 perces</b> ülés <b>22.000 forint.</b><br/>A kettős vezetésű <b>90 perces</b> ülés <b>32.000 forint.</b> </p>
                                          </div>
                                    )}
                                    </div>

                                    <div className={`contentBx ${openState[4] ? 'active' : ''}`}>
                                    <div className="label2" onClick={() => toggleAccordion(4)}>Van még kérdése?</div>
                                    {openState[4] && (
                                          <div className="content2">
                                                <p>Látogassa meg a <Link to="/Gyik_tanacsadas">Gyakran Ismételt Kérdések</Link> oldalt, ahol további kérdésekre is választ kaphat! </p>
                                          </div>
                                    )}
                                    </div>
                              </div>
                        </div>
                        <div className="mf-adult-info4 padd-20">
                        </div>
                  </div>
            </div>

      </div>
      {/*Family-4 section ends*/}

      {/*Family-5 contact section starts*/}
      <div className="mf-contact-section" data-id="">

            <div className="container mf-contact-container p-0">

                  <div className="mf-widget-heading-title mf-contact-title-21m23f47 w-1" data-id="21m23f47">
                        <h4 className="mf-heading-title">Lépjen kapcsolatba velem!</h4>
                  </div>

                  <div className="mf-contact-row">

                        <div className="mf-contact-content padd-10">
                              <h3 className="contact-title">Kapcsolat</h3>

                              <div className="contact-info">
                                    <h3><i className="fas fa-envelope"></i>info@gemeterzsuzsa.hu</h3>
                                    <h3><i className="fas fa-phone"></i>+36-70-418-1114</h3>
                                    <h3><i className="fas fa-map-marker-alt"></i>Budapest, Budaörs</h3>   
                              </div>

                        </div>
                        
                        <div className="mf-contact-form padd-10">
                        <form action="mail.php" method="POST">
                              <input type="text" id="name" name="name" placeholder="Név" className="box" required="required"/>
                              <input type="email" id="email" name="email" placeholder="e-mail" className="box" required="required"/>
                              <input type="text" id="mobil" name="mobil" placeholder="Mobilszám" className="box"/>
                              <textarea name="message" id="" cols="55" rows="10" className="box massage" placeholder="Írjon üzenetet!" required="required"></textarea>
                              <div className="mf-home-contact-check-box">
                              <label for="vehicle"></label><br/>
                              <input type="checkbox" name="vehicle" id="vehicle" value="true" required="required"/>
                              <p> Elolvastam és elfogadom az <Link to="https://gemeterzsuzsa.hu/static/media/adatvedelmi_tajekoztato.pdf">Adatvédelmi tájékoztatót.</Link></p>
                              </div>
                              <input type="submit" id="send" className="contact-btn" name="send" value="Küldés"/>
                        </form>              
                        </div>
                  </div>
            </div>

      </div>
      {/*Family-5 contact section ends*/}

      {/*Back to Top start*/}
       <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}

</div>
  )
}
