import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog03Hero from "../../../image/blog_03_hero_uj_ev.jpg"

export default function Blog_03_uj_ev() {
  return (
    <div className="mf-4398" id="mf-page" data-id="4398">
            <div className="mf-page section section-blog-globale">
                  <div className="container mf-blog-globale-container p-0">
                        <div className="mf-blog-globale-hero">
                              <img src={Blog03Hero} alt="Uj_ev_uj_lehetosegek"/>
                        </div>
                  
      
                        <div className="mf-blog-globale-face">
                              <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
                                    <h2 className="mf-blog-globale-heading-title">Új év - új lehetőségek a változásra</h2>
                              </div>
      
                              <div className="mf-blog-globale-profile-container">
                                    <div className="mf-blog-globale-profile">
                                          <div className="mf-blog-globale-img-container"></div>   
                              
      
                                          <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                                                <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                                                <p className="mf-blog-globale-text-description">2023. január 2.</p>
                                          </div>
                                    </div>
                              
                                    <div>
                                          <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
                                    </div>
                              </div>
                        </div>
      
      
      
                        <div className="mf-blog-globale-column">
      
                              <div className="mf-blog-globale-text">
                                    <p>
                                          Amikor új évbe lépünk, reménykedünk a változás, változtatás 
                                          lehetőségében, akár a karrierünkről, kapcsolatainkról vagy 
                                          az egészségünkről van szó. Ha figyelembe vesszük a 
                                          terveinket és a megvalósításukhoz szükséges lépéseket 
                                          – akár stressz vagy szorongás késztet is bennünket arra – 
                                          először is ki kell tűznünk az elérni kívánt célokat.
                                    </p>
                                    <p>
                                          Talán hasznos lesz számunkra, ha észben tartjuk ezt a néhány kérdést, az új év kezdetén:
                                    </p>
                                    <h4>
                                          Mit várok ettől az évtől?
                                    </h4>
                                    <p>
                                          Ha ezzel a kérdéssel kezdünk, és az év folyamán többször is felidézzük, akkor ez segíthet abban, 
                                          hogy a jövőbeni céljainkra összpontosítsunk, amelyek 
                                          izgatnak bennünket. Ha a szándékainkra összpontosítunk, 
                                          és tudunk a hozzájuk kapcsolódó pozitív érzésekhez 
                                          kapcsolódni, akkor tisztábban fogunk látni és nem 
                                          tévesztjük szem elől, hogy mit is akarunk.
                                    </p>
                                    <h4>
                                          Milyen lépések segítenek elérni céljaimat?
                                    </h4>
                                    <p>
                                          Igen nagyon hasznos, ha tisztában vagyunk azzal kapcsolatban, 
                                          hogy mit akarunk. Viszont a következő fontos lépés, hogy 
                                          hogyan juthatunk el odáig. Nem árt végiggondolni a 
                                          lehetőségeket. Ötleteljünk!  Nem csak egy jó út létezik, 
                                          különböző utakon ugyanahhoz az eredményhez érhetünk el, 
                                          és ez rugalmasságot biztosít a számunkra. Ha az egyik 
                                          úton akadályba botlunk, megnézhetjük azokat az 
                                          alternatívákat, amelyeket már feltérképeztünk, 
                                          hogy van-e közöttük olyan lehetőség, amely végül 
                                          ugyanoda vezet bennünket.
                                    </p>
                                    <h4>
                                          Vannak-e olyan akadályok, amelyek megnehezíthetik e lépések megtételét?
                                    </h4>
                                    <p>
                                          Itt vesszük figyelembe a lehetséges akadályokat. 
                                          Fontos emlékeznünk arra, hogy egyikünk sem tudja, 
                                          hogy valójában mi fog történni a célhoz vezető úton. 
                                          Már az segítség lehet, ha végig gondoljuk, hogy mit tennénk, 
                                          abban az esetben, amennyiben nehézségekbe ütköznénk, 
                                          számításba vehetünk néhány akadályt, amelyek szembe jöhetnek 
                                          velünk. Így egy kicsit felkészültebbnek érezhetjük magunkat 
                                          a nehéz időkben.
                                    </p>
                                    <h4>
                                          Hogyan gyakorolhatom, hogy türelmes legyek önmagammal, 
                                          és hogy kedves legyek önmagamhoz?
                                    </h4>
                                    <p>
                                          Ez egy nagy kérdés! Eltelhet ez az év is úgy, hogy a végén azt érezzük túl 
                                          gyorsan szaladt el és nem volt időnk megtenni mindazt, amit szerettünk volna, 
                                          csalódottnak érezhetjük magunkat. Az igazság az, hogy a változáshoz idő kell, 
                                          és amit akarunk, arra nem mindig elegendő 365 nap, néha kevesebb, néha több 
                                          időre van szükségünk, és ez rendben van! Ha rájössz arra, hogyan tudsz önmagaddal 
                                          elfogadóbb, türelmesebb lenni, még akkor is, amikor frusztráltnak érzed magad, 
                                          akkor sokkal jobban fogod élvezni a célhoz vezető utad. Az elfogadás, arra fog ösztönözni, 
                                          hogy továbbra is higgy a képességeidben.
                                    </p>
                                    <p>
                                          Fedezd fel magadban azt a forrást, amiből a nehéz napokon meríteni tudsz és fejleszd önismereted! 
                                          Mindezek segítenek abban, hogy ellenállóbbá válj és tovább lépj afelé, amit szeretnél.
                                    </p>
                                    <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>
                              </div>
      
      
                              <div className="mf-blog-global-tags">
                                    <p>Címkék:</p>
                                    <a href="#">Önismeret</a>
                                    
                              </div>
                        </div>
      
                  </div>                  
      
            </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
      </div>

  )
}
