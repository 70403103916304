import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/mf_blog_global.css"

import Blog15Hero from "../../../image/blog_15_hero_ot_lepes_a_hatekony.png"

export default function Blog_15_ot_lepes_a_hekony_parkapcsolati_kommunikaciohoz() {
  return (
    <div className="mf-4301" id="mf-page" data-id="mf-4301">
      <div className="mf-page section section-blog-globale">
        <div className="container mf-blog-globale-container p-0">
          <div className="mf-blog-globale-hero">
                <img src={Blog15Hero} alt=""/>
          </div>
    
          <div className="mf-blog-globale-face">
            <div className="blog-globale-heading-title mf-blog-globale-title-2f3b5530">
              <h2 className="mf-blog-globale-heading-title">5 lépés a hatékony párkapcsolati kommunikációhoz</h2>
            </div>

            <div className="mf-blog-globale-profile-container">
              <div className="mf-blog-globale-profile">
                <div className="mf-blog-globale-img-container"></div>   
    

                <div className="mf-blog-globale-text-description mf-blog-globale-text-2f5f3421">
                  <p className="mf-blog-globale-text-description">Gemeter Zsuzsa</p>
                  <p className="mf-blog-globale-text-description">2024. március 14.</p>
                </div>
              </div>
        
              <div>
              <Link to="/Blog" className="mf-blog-globale-btn">Blog</Link>
              </div>
            </div>
          </div>

          <div className="mf-blog-globale-column">

                <div className="mf-blog-globale-text">
                      <p>
                            Mindennapjaink nagy részét kommunikációval töltjük, ennek csaknem a felét hallgatással. Ha ilyen sokszor kell a figyelmünket adni, akkor jogosan vetődik fel gyakran az a kérdés, hogy miért érzi úgy a partnerünk, hogy nem figyelünk rá? 
                      </p>

                      <p>
                            Lehet, hogy azért mert sokszor, elkalandozik a figyelmünk: <i>Mi legyen ma a vacsora? Pizzát rendeljünk, vagy étterembe menjünk? El ne felejtsem beírni a találkozót! Fel kell hívni holnap a fogorvosom!..  Elnézést, mit mondtál?</i>
                      </p>

                      <p>
                            Lehet, hogy amikor a másik beszél, akkor is arra figyelünk, hogy mi mit szeretnénk mondani. <i>Már vagy 15 perce beszél, mikor hagyja már abba, hogy elmondhassam, mit gondolok erről.</i>
                      </p>

                      <p>
                            Az is lehet, hogy azért nem figyelünk, mert lebecsüljük a másik mondanivalóját: <i>Marhaság az egész.</i>
                      </p>

                      <p>
                            Bármi legyen is az ok, az szinte bizonyos, hogy nem hallgatunk jól, mert nem sajátítottuk el a hatékony hallgatás művészetét. Lehet, hogy halljuk, amit a másik mond, de a hallgatás, odafigyelést, gondolkodást és gyakorlást igényel. A következő lépések gyakorlása közelebb vihet ehhez:
                      </p>

                      <h6>
                            1. Légy a jelenben!
                      </h6>

                      <p>
                            Megmutatod a párodnak, hogy az idő, amit beszélgetéssel töltöttök, az fontos a számodra. Amit tehetsz, hogy lenémítod a telefonodat, kikapcsolod a TV-t, elég közel és szembe ülsz vele és figyelsz rá.
                      </p>

                      <h6>
                            2. Légy kíváncsi arra, amit a másik mond! 
                      </h6>

                      <p>
                            Ha arra a lehetőségre vársz a beszélgetés során, hogy végre elmondhasd, amit te akarsz, akkor nem igazán tudsz hallgatni. Tedd félre a saját gondolataid későbbre! Hallgass meg minden szót, amit a másik mond, hiszen ez által megismerheted, hogy a számodra legfontosabb ember, hogyan gondolkodik és érez. Egy bólintás és egy „hmmm” megnyugtató lehet a másik félnek és visszajelzést ad, hogy figyelsz rá.
                      </p>

                      <h6>
                            3. Figyelj arra is, ami nem hangzik el! 
                      </h6>

                      <p>
                            Általában egyetértés van azt illetően, hogy a kommunikáció csak kis részét kb. 7% -át teszik ki a szavak, és a maradék 93% -át pedig a hanglejtés, a hangsúly, a hanghordozás és a testbeszéd adja. Érdemes tehát odafigyelned arra is, hogy mit jelez a párod teste és a hangszíne.
                      </p>

                      <h6>
                            4. Ismételd meg a hallottakat, és tegyél fel kérdéseket! 
                      </h6>

                      <p>
                            Lehet, hogy pontosan érted, amit a párod kommunikált feléd, de azért okos döntés lehet, ha megbizonyosodsz minderről. Hogy hogyan? Több, a témára vonatkozó pontosító kérdéseket tehetsz fel vagy megismételheted a hallottakat, hogy jól értetted-e. Akármelyik megoldást is választod, ne feledd, hogy mindez egy lehetőség arra, hogy az esetleges félreértéseket tisztázzátok, nem pedig egy vélemény megfogalmazása, ha nem értesz egyet a pároddal.
                      </p>

                      <h6>
                            5. Légy nyitott!
                      </h6>

                      <p>
                            Miközben hallgatod a párod, próbáld meg beleélni magad az ő helyzetébe, próbáld meg elképzelni azt, amiről beszél. A másik félnek, lehetnek mások az érzései, gondolatai, érzelmei, mint neked. Az ő nézőpontjának az elismerése segíthet a kapcsolatotok megértésében.
                      </p>

                      <p>
                            Ha hajlandó vagy erőfeszítést tenni a másikért, akkor azt a párod értékelni fogja és viszonozni, ami által a kapcsolatotok mélyebbé és bensőségesebbé válhat.
                      </p>
                      <p>
                                  <b>További információért vagy időpont egyeztetésért hívja a + 36 70 418 1114-es telefonszámot, vagy <Link to="/Kapcsolat">írjon nekem.</Link></b>
                            </p>

                </div>
          </div>
          <div className="mf-blog-global-tags">
            <p>Címkék:</p>
            <a href="#">kapcsolat</a>
                
          </div>
        </div>
      </div>
      {/*Back to Top start*/}
      <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
    </div>

  )
}
