import React, {useState} from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';

import "../../CSS/blog.css"

import GemZsu from "../../image/gemeter_zsuzsa.png"
import Blog25Blog from "../../image/blog_25_5_tipp_a_boldogabb_erosebb_parkapcsolatert.png"
import Blog24Blog from "../../image/blog_24_hogyan hat a szulove_valas_a_parkapcsolatra.png"
import Blog23Blog from "../../image/blog_23_miert_fontos_az egeszseges_onbecsules.png"
import Blog22Blog from "../../image/blog_22_parkapcsolat_epitokovei.png"
import Blog21Blog from "../../image/blog_21_hero_hogyan_mukodik_a _valasi mediacio.png"
import Blog20Blog from "../../image/blog_20_hero_valas__hogyan_lehet.png"
import Blog19Blog from "../../image/blog_19_hero_valas_gyakori_reakciok.png"
import Blog18Blog from "../../image/blog_18_hero_hogyan_tanuld_meg.png"
import Blog17Blog from "../../image/blog_17_hero_miert_a_valasi_mediacio.png"
import Blog16Blog from "../../image/blog_16_hero_a_szuloi_ongondoskodas.png"
import Blog15Blog from "../../image/blog_15_hero_ot_lepes_a_hatekony.png"
import Blog14Blog from "../../image/blog_14_hero_mediacio.png"
import Blog13Blog from "../../image/blog_13_hero_onbizalom.png"
import Blog12Blog from "../../image/blog_12_hero_elismerni-a-masik-erzeseit.png"
import Blog11Blog from "../../image/blog_11_hero_szeretetnyelv.png"
import Blog10Blog from "../../image/blog_10_hero_jo_kommunikacio.png"
import Blog09Blog from "../../image/blog_09_hero_sikeres_hazassag.jpg"
import Blog08Blog from "../../image/blog_08_hero_egyeni_tanacsadas.png"
import Blog07Blog from "../../image/blog_07_hero_hazassagi_tanacsadas.png"
import Blog06Blog from "../../image/blog_06_hero_stresszkezeles.jpg"
import Blog05Blog from "../../image/blog_05_hero_mire_szamithat.jpg"
import Blog04Blog from "../../image/blog_04_hero_tipp.jpg"
import Blog03Blog from "../../image/blog_03_hero_uj_ev.jpg"
import Blog02Blog from "../../image/blog_02_hero_hogyan_szabjunk.jpg"
import Blog01Blog from "../../image/blog_01_hero_7_kulcs.png"


export default function Blog() {

      $(document).ready(function () {
            $(".filter-item").click(function () {
                  const value = $(this).attr("data-filter")
                  if (value == "all") {
                        $(".post-box").show("1000");
                  }
                  else{
                        $(".post-box")
                        .not("." + value)
                        .hide("1000");
                        $(".post-box")
                        .filter("." + value)
                        .show("1000")
                  }
            });
      
     
      $(".filter-item").click(function(){
            $(this).addClass("active-filter").siblings().removeClass("active-filter");
      });
      
      });

  return (
    <div className="mf-3918"  id="mf-page" data-id="3918">
      {/*hero section starts*/}
      <div className="mf-page section section-img-bg-25m43f62" data-id="25m43f62">

            <div className="container mf-hero-container p-0">
                  
                  <div className="mf-column-wrap mf-col-100">
                        <div className="mf-widget-container">
                              <div className="mf-widget">
                                    <div className="title-73m19f57 w-1" data-id="73m19f57">
                                          <h1 className="mf-heading-title">Blog</h1> 
                                    </div>
                              </div>
                        </div>
                  </div>
            </div> 
      </div>
      {/*hero section ends*/}

      {/*Blog starts*/}
      <div className="mf-page section mf-blogs-section" data-id="">
                  {/*Post filter*/}
                  <div className="post-filter container">
                        <span className="filter-item active-filter" data-filter="all">Összes</span>
                        <span className="filter-item" data-filter='couple'>Kapcsolatok</span>
                        <span className="filter-item" data-filter='self'>Önismeret</span>
                        <span className="filter-item" data-filter='commun'>Kommunikáció</span>
                        <span className="filter-item" data-filter='counse'>Tanácsadás</span>
                        <span className="filter-item" data-filter='divorce'>Válás</span>
                        <span className="filter-item" data-filter='mediat'>Mediáció</span>
                  </div>
                  {/*Posts*/}
                  <div className="post container">

                  {/*Post box 25*/}
                  <div class="post-box mediat">
                              <img src={Blog25Blog} alt="" class="post-img"/>
                              <h2 class="category">kapcsolatok</h2>
                              <Link to="/Blog25" class="post-title">Őt tipp a boldogabb és erősebb párkapcsolatért
                              </Link>
                              <span class="post-date">2024.09.15.</span>
                              <p class="post-description">Időnként a legboldogabb párkapcsolatokban is jelentkeznek nehézségek, azonban megfelelő eszközökkel és stratégiával le lehet küzdeni a felmerülő problémákat. Párokkal végzett munkám tapasztalatai</p>
                              {/*profile*/}
                              <div class="profile">
                                    <img src={GemZsu} alt="" class="profile-img"/>
                                    <span class="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                  {/*Post box 24*/}
                  <div class="post-box mediat">
                              <img src={Blog24Blog} alt="" class="post-img"/>
                              <h2 class="category">kapcsolatok</h2>
                              <Link to="/Blog24" class="post-title">Hogyan hat a szülővé válás a párkapcsolatra?
                              </Link>
                              <span class="post-date">2024.08.13.</span>
                              <p class="post-description">Munkám során gyakran tapasztalom, hogy a párok nincsenek felkészülve arra, hogy az első gyermek születése hogyan fog hatni az életükre és a kapcsolatukra. A hozzám forduló gyermeket nevelő párok</p>
                              {/*profile*/}
                              <div class="profile">
                                    <img src={GemZsu} alt="" class="profile-img"/>
                                    <span class="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                  {/*Post box 23*/}
                  <div class="post-box mediat">
                              <img src={Blog23Blog} alt="" class="post-img"/>
                              <h2 class="category">önismeret</h2>
                              <Link to="/Blog23" class="post-title">Miért fontos az egészséges önbecsülés?
                              </Link>
                              <span class="post-date">2024.07.16.</span>
                              <p class="post-description">Az egészséges önbecsülés motiválhat bennünket céljaink elérésére, mert képesek vagyunk eligazodni az életben, annak tudatában, hogy képesek vagyunk elérni bármit, amire csak gondolunk.</p>
                              {/*profile*/}
                              <div class="profile">
                                    <img src={GemZsu} alt="" class="profile-img"/>
                                    <span class="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                  {/*Post box 22*/}
                  <div class="post-box mediat">
                              <img src={Blog22Blog} alt="" class="post-img"/>
                              <h2 class="category">kapcsolatok</h2>
                              <Link to="/Blog22" class="post-title">A párkapcsolat építőkövei
                              </Link>
                              <span class="post-date">2024.06.14.</span>
                              <p class="post-description">Párkapcsolatunk hihetetlen mélységet és értéket adhat az életünknek, de a pozitívumok mellett természetesen kihívásokkal és felelősséggel is jár. Egy egészséges párkapcsolat felépítéséhez és  </p>
                              {/*profile*/}
                              <div class="profile">
                                    <img src={GemZsu} alt="" class="profile-img"/>
                                    <span class="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                  {/*Post box 21*/}
                        <div className="post-box mediat">
                              <img src={Blog21Blog} alt="" className="post-img"/>
                              <h2 className="category">mediáció</h2>
                              <Link to="/Blog21" className="post-title">Hogyan működik a válási mediáció?
                              </Link>
                              <span className="post-date">2024.05.29.</span>
                              <p className="post-description">A válási mediáció egy olyan konfliktuskezelési mód, amelyet az emberek a peres eljárás helyett választanak amelyben bírósághoz fordul, ahol a bíró meghallgatja mindkét ügyvéd érvelését, és döntéseket hoz a szülői felügyeletről, a kapcsolattartásról, </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 20*/}
                        <div className="post-box divorce">
                              <img src={Blog20Blog} alt="" className="post-img"/>
                              <h2 className="category">válás</h2>
                              <Link to="/Blog20" className="post-title">Válás II. rész - Hogyan lehet felépülni a válásból
                              </Link>
                              <span className="post-date">2024.05.09.</span>
                              <p className="post-description">Míg a szakítás nehéz és fájdalmas, addig vannak egészséges módszerek a felépülésre, a megbirkózásra és a továbblépésre. Bár mindenki másképp gyászol és kezeli a szakítás okozta fájdalmat,</p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 19*/}
                        <div className="post-box divorce">
                              <img src={Blog19Blog} alt="" className="post-img"/>
                              <h2 className="category">válás</h2>
                              <Link to="/Blog19" className="post-title">Válás I. rész - Gyakori reakciók
                              </Link>
                              <span className="post-date">2024.04.25.</span>
                              <p className="post-description">Amikor valaki válással vagy szakítással küzd, sokszor úgy érzi, hogy egyedül maradt, és a helyzetre adott reakcióit pedig nem mindig tekinti normálisnak.
                                    Első fontos lépés
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 18*/}
                        <div className="post-box self">
                              <img src={Blog18Blog} alt="" className="post-img"/>
                              <h2 className="category">önismeret</h2>
                              <Link to="/Blog18" className="post-title">Hogyan tanuld meg szeretni önmagad
                              </Link>
                              <span className="post-date">2024.04.11.</span>
                              <p className="post-description">Azt gondoljuk, hogy biztosan nem nehéz szeretni önmagunkat a hibáinkkal együtt, ám sokszor mégis, igencsak nehéznek bizonyul saját magunk feltétel nélküli elfogadása. Néhányan negatív önértékeléssel, alacsony önbecsüléssel vagy méltatlanság érzésével küzdenek. </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 17*/}
                        <div className="post-box mediat">
                              <img src={Blog17Blog} alt="" className="post-img"/>
                              <h2 className="category">mediáció</h2>
                              <Link to="/Blog17" className="post-title">"El akarok válni" - Miért a válási mediáció a legjobb döntés
                              </Link>
                              <span className="post-date">2024.04.11.</span>
                              <p className="post-description">Öt olyan érv, ami meggyőzheti Önt arról, hogy miért a válási mediáció a legjobb választás válás esetén.</p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>


                        {/*Post box 16*/}
                        <div className="post-box parents">
                              <img src={Blog16Blog} alt="" className="post-img"/>
                              <h2 className="category">szülőség</h2>
                              <Link to="/Blog16" className="post-title">A szülői öngondoskodás jelentősége
                              </Link>
                              <span className="post-date">2024.03.28.</span>
                              <p className="post-description">A szülői coaching során sokszor hangzik el a szülők szájából, az a mondat, hogy „Akkor jó egy szülő, ha az gyermeke minden vágyát, igényét és szükségletét kielégíti, bármi áron.” </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>


                        {/*Post box 15*/}
                        <div className="post-box couple">
                              <img src={Blog15Blog} alt="" className="post-img"/>
                              <h2 className="category">kapcsolat</h2>
                              <Link to="/Blog15" className="post-title">5 lépés a hatékony párkapcsolati kommunikációhoz
                              </Link>
                              <span className="post-date">2024.03.14.</span>
                              <p className="post-description">Mindennapjaink nagy részét kommunikációval töltjük, 
                                    ennek csaknem a felét hallgatással. Ha ilyen sokszor kell a figyelmünket adni, 
                                    akkor jogosan vetődik fel gyakran az a kérdés</p>                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>


                        {/*Post box 14*/}
                        <div className="post-box mediat">
                              <img src={Blog14Blog} alt="" className="post-img"/>
                              <h2 className="category">mediáció</h2>
                              <Link to="/Blog14" className="post-title">A mediáció folyamata
                              </Link>
                              <span className="post-date">2024.02.29.</span>
                              <p className="post-description">A mediáció egy önkéntes és bizalmas folyamat, amely lehetővé teszi azt, a felek egy semleges harmadik fél segítségével pres eljárás nélkül megállapodásra jussanak vitás kérdésekben. </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>


                        {/*Post box 13*/}
                        <div className="post-box self">
                              <img src={Blog13Blog} alt="" className="post-img"/>
                              <h2 className="category">önismeret</h2>
                              <Link to="/Blog13" className="post-title">Az önbizalom saját magad megismerésével kezdődik!
                              </Link>
                              <span className="post-date">2024.02.14.</span>
                              <p className="post-description">Az önmagadba vetett hit elengedhetetlen, 
                                    ha sikeres akarsz lenni az életben, legyen szó munkáról kapcsolatokról vagy bármi másról. Az önbizalomról sokkal könnyebb beszélni,                             </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 12*/}
                        <div className="post-box couple">
                              <img src={Blog12Blog} alt="" className="post-img"/>
                              <h2 className="category">kapcsolatok</h2>
                              <Link to="/Blog12" className="post-title">Elismerni a másik érzéseit 
                                    
                              </Link>
                              <span className="post-date">2024.02.08.</span>
                              <p className="post-description">Amikor a párkapcsolatban az egyik fél megosztja, hogyan érez 
                                    valamivel kapcsolatban, az az ő érzése, a saját valósága. Fontos tudni, hogy az emberek valósága szubjektív, vagyis
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 11*/}
                        <div className="post-box couple">
                              <img src={Blog11Blog} alt="" className="post-img"/>
                              <h2 className="category">kapcsolatok</h2>
                              <Link to="/Blog11" className="post-title">Miért fontos ismerni párod szeretetnyelvét 
                                    
                              </Link>
                              <span className="post-date">2024.02.01.</span>
                              <p className="post-description">Amikor szerelemről, szeretetről beszélünk, 
                                    nem hagyhatjuk figyelmen kívül azt a tényt, hogy az emberek különbözőképpen fejezik ki a szeretetüket a másik iránt [...]
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 10*/}
                        <div className="post-box commun">
                              <img src={Blog10Blog} alt="" className="post-img"/>
                              <h2 className="category">kommunikáció</h2>
                              <Link to="/Blog10" className="post-title">A jó kommunikáció 5 egyszerű dologgal kezdődik 
                                    
                              </Link>
                              <span className="post-date">2024.01.23.</span>
                              <p className="post-description">A jó kommunikációs készség elengedhetetlen a 
                                    mai világban, minden kapcsolat kulcsa és 5 egyszerű dologgal kezdődik.
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 9*/}
                        <div className="post-box couple">
                              <img src={Blog09Blog} alt="" className="post-img"/>
                              <h2 className="category">kapcsolatok</h2>
                              <Link to="/Blog09" className="post-title">Mi kell a sikeres házassághoz
                                    
                              </Link>
                              <span className="post-date">2023.11.08.</span>
                              <p className="post-description">Az érzelmek változnak az idő múlásával, és a szerelem erőssége is 
                                    természetes módon változik a házasságban eltelt évek alatt. A házaspárok többsége [...]
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 8*/}
                        <div className="post-box counse">
                              <img src={Blog08Blog} alt="" className="post-img"/>
                              <h2 className="category">tanácsadás</h2>
                              <Link to="/Blog08" className="post-title">Egyéni tanácsadás 
                                    
                              </Link>
                              <span className="post-date">2023.10.24.</span>
                              <p className="post-description">sok olyan ember, aki elégedett az életével, 
                                    rendszeresen jár tanácsadásra, mert szüksége van egy olyan biztonságos helyre, ahol ítélkezésmentes környezetben meg tudja osztani az érzéseit[...]
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 7*/}
                        <div className="post-box counse">
                              <img src={Blog07Blog} alt="" className="post-img"/>
                              <h2 className="category">tanácsadás</h2>
                              <Link to="/Blog07" className="post-title">Házassági és párkapcsolati tanácsadás 
                                    
                              </Link>
                              <span className="post-date">2023.09.20.</span>
                              <p className="post-description">Sok pár gondolja úgy, hogy csak akkor kell szakemberhez fordulniuk, 
                                    ha a kapcsolatukban nehézséggel küzdenek, de ez nem így van. A párkapcsolati tanácsadás minden pár számára [...]
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 6*/}
                        <div className="post-box commun">
                              <img src={Blog06Blog} alt="" className="post-img"/>
                              <h2 className="category">kommunikáció</h2>
                              <Link to="/Blog06" className="post-title">Hogyan segítheted szeretteid stresszkezelését 
                                    
                              </Link>
                              <span className="post-date">2023.02.21.</span>
                              <p className="post-description">Volt már olyan érzésed, hogy felrobbansz, 
                                    ha még egy kis nyomás nehezedik rád? Amikor túl sok stressz ér bennünket, akkor legtöbben úgy érezzük magunkat[...]
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 5*/}
                        <div className="post-box counse">
                              <img src={Blog05Blog} alt="" className="post-img"/>
                              <h2 className="category">tanácsadás</h2>
                              <Link to="/Blog05" className="post-title">Mire számíthat a párkonzultáción
                                    
                              </Link>
                              <span className="post-date">2023.01.18.</span>
                              <p className="post-description">A válások elsődleges okai közé a kommunikáció és az intimitás hiánya, illetve a folyamatos veszekedések tartoznak. Amikor párterápiára jelentkezik, 
                                    valószínűleg már küzd egy ideje a párjával és aggódik vagy fél, hogy mi lesz a kapcsolatukkal.
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 4*/}
                        <div className="post-box self">
                              <img src={Blog04Blog} alt="" className="post-img"/>
                              <h2 className="category">önismeret</h2>
                              <Link to="/Blog04" className="post-title">Tippek az öngondoskodás megvalósításához 
                                    
                              </Link>
                              <span className="post-date">2023.01.13.</span>
                              <p className="post-description">Az öngondoskodás, azaz a magunkkal való törődés, odafigyelés a saját szükségleteinkre, mozgalmas és kihívásokkal teli életünkben nem mindig élvez prioritást. Pedig a lelki 
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 3*/}
                        <div className="post-box self">
                              <img src={Blog03Blog} alt="" className="post-img"/>
                              <h2 className="category">Önismeret</h2>
                              <Link to="/Blog03" className="post-title">Új év- új lehetőségek a változásra
                                    
                              </Link>
                              <span className="post-date">2023.01.02.</span>
                              <p className="post-description">Amikor új évbe lépünk, reménykedünk a változás, 
                                    változtatás lehetőségében, akár a karrierünkről, kapcsolatainkról vagy az egészségünkről van szó.[...]
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 2*/}
                        <div className="post-box couple">
                              <img src={Blog02Blog} alt="" className="post-img"/>
                              <h2 className="category">kapcsolatok</h2>
                              <Link to="/Blog02" className="post-title">Hogyan szabjunk határokat az ünnepek alatt 
                                    
                              </Link>
                              <span className="post-date">2022.12.21.</span>
                              <p className="post-description">Ahhoz, hogy a párkapcsolatok túléljék az ünnepeket fontos, 
                                    hogy egy pár, párként, határokat tudjon szabni. Érdemes tehát leülni és megbeszélni, hogy hol és kinek
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>

                        {/*Post box 1*/}
                        <div className="post-box couple">
                              <img src={Blog01Blog} alt="" className="post-img"/>
                              <h2 className="category">kapcsolatok</h2>
                              <Link to="/Blog01" className="post-title">Hét plusz egy kulcs a 
                                    sikeres kapcsolatokhoz
                              </Link>
                              <span className="post-date">2022.11.21.</span>
                              <p className="post-description">A kommunikáció mindig fontos eleme az egészséges, 
                                    jól működő kapcsolatoknak, de vannak még további fontos tényezők is[...]
                              </p>
                              {/*profile*/}
                              <div className="profile">
                                    <img src={GemZsu} alt="" className="profile-img"/>
                                    <span className="profile-name">Gemeter Zsuzsa</span>
                              </div>

                        </div>
                  </div>      
      </div>
      {/*Blog ends*/}

       {/*Back to Top start*/}
       <a href="#" className="back-to-top">
            <span className="material-icons"><i className="fa-solid fa-angles-up"></i></span>
      </a>
      {/*Back to Top end*/}
</div>

  )
}
